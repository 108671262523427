import PouchDB from 'pouchdb-browser';
import { appInsights } from '../../init';
import type { ActionContext, Module, Payload } from 'vuex';
import type { RootState } from '..';
import { abp } from '../../lib/abp';
import ajax from '../../lib/ajax';
import { base64Decode } from '../../lib/base64';
import insertIntoSorted from '../../lib/insert-sorted';
import util from '../../lib/util';
import type { IndexConfiguration } from './index-configuration';

interface MostRecentRevision {
  type: 'metadata';
  articleModificationTime: string;
  draftArticleModificationTime: string;
  attachmentModificationTime: string;
  indexModificationTime: string;
  noticesModificationTime: string;
}
export interface ArticleSummary {
  type: 'article';
  published: boolean;
  documentName: string;
  revisionCount: number;
  modificationTime: string;
  deleted?: boolean;
  keywords: string[];
  documentBody: string | null;
  hierarchyParent: string[] | null;
  hierarchyWeight: string | null;
  isPublic: boolean;
  modificationUserName: string;
  images: string[];
  protocolSection: 'ALS' | 'BLS';
  isCurrent: boolean;
  replaces: string | null;
  replacedBy: string | null;
}
export interface ImageAttachment {
  type: 'attachment';
  id: string;
  fileType: string;
  contents?: string | null;
  creationTime: string;
  usageCount: number;
  fileSize: number;
  isDeleted?: boolean;
}
export interface ArticleListState {
  currentTime: string;
  currentDate: string;

  documents: ArticleSummary[];
  documentIndices: { [documentName: string]: number };
  draftDocuments: ArticleSummary[];
  draftDocumentIndices: { [documentName: string]: number };

  attachments: ImageAttachment[];
  attachmentIndices: { [attachmentName: string]: number };

  notices: Notice[];
  noticeIndices: {
    [noticeId: string]: number;
  };
  noticesByDailyBucket: {
    [dateStamp: string]: {
      [id: string]: true;
    };
  };

  imageCounts: { [attachmentName: string]: number };

  isSyncing: boolean;
  completedInitialLoad: boolean;
  currentlySelectedDocument: string;
  articleModificationTime: string;
  draftArticleModificationTime: string;
  attachmentModificationTime: string;
  indexModificationTime: string;
  noticesModificationTime: string;
  attachmentMap: {
    [url: string]: {
      url: string;
      users: {
        [user: string]: boolean;
      };
    };
  };
  pendingAttachmentMap: {
    [url: string]: boolean;
  };
  indexConfiguration: IndexConfiguration;
}
export interface Notice {
  type: 'notice';
  title: string;
  color: string;
  body: string | null;
  isDeleted: boolean;
  fromDate: string | null;
  throughDate: string | null;
  modificationTime: string;
  modificationUserName: string;
  id: string;
}

interface Index {
  type: 'designdoc';
  version: number;
  views: {
    [name: string]: {
      map: string;
    };
  };
}

export type DatabaseStorable =
  | ArticleSummary
  | ImageAttachment
  | IndexConfiguration
  | MostRecentRevision
  | Index
  | Notice;

export let datastore!: PouchDB.Database<DatabaseStorable>;
let concurrencyCheck: unknown;
let unmountDatastore = () => {};
function initDatastore() {
  datastore = new PouchDB('articles');
}

function localTimeToLocalDate(dateStr?: Date | string) {
  if (typeof dateStr === 'undefined') {
    dateStr = new Date();
  }

  if (dateStr == '') {
    return '';
  }
  const date = typeof dateStr === 'string' ? new Date(dateStr) : dateStr;
  if (Number.isNaN(date.valueOf())) {
    return '';
  }

  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

class ArticleListStore implements Module<ArticleListState, any> {
  namespaced = true;
  state: ArticleListState = {
    currentTime: new Date().toISOString(),
    currentDate: localTimeToLocalDate(),

    documents: [],
    draftDocuments: [],
    draftDocumentIndices: {},
    documentIndices: {},
    attachments: [],
    attachmentIndices: {},
    notices: [],
    noticeIndices: {},
    noticesByDailyBucket: {},

    imageCounts: {},
    isSyncing: false,
    completedInitialLoad: false,
    currentlySelectedDocument: 'Home',

    articleModificationTime: '1900-01-01T00:00:00Z',
    draftArticleModificationTime: '1900-01-01T00:00:00Z',
    attachmentModificationTime: '1900-01-01T00:00:00Z',
    indexModificationTime: '1900-01-01T00:00:00Z',
    noticesModificationTime: '1900-01-01T00:00:00Z',

    attachmentMap: {},
    pendingAttachmentMap: {},

    indexConfiguration: {
      type: 'index',
      id: 0,
      revisionCount: 0,
      revisionDate: '1900-01-01T00:00:00Z',
      alsSections: [],
      blsSections: [],
    },
  };

  actions = {
    async deleteAttachment(
      context: ActionContext<ArticleListState, any>,
      arg: ActionPayload<string>
    ) {
      const id = arg.payload.split(':')[1];
      try {
        const url = `/api/services/app/Attachment/Delete?Id=${id}`;
        await ajax.delete(url, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });
      } catch {}
      context.dispatch({
        type: 'startLoadDocuments',
      });
    },

    async deleteDocument(
      context: ActionContext<ArticleListState, any>,
      arg: ActionPayload<string | { id: string; replacedBy: string | null }>
    ) {
      if (arg.payload) {
        const id =
          typeof arg.payload === 'string' ? arg.payload : arg.payload.id;
        const replacedBy =
          typeof arg.payload === 'string' ? null : arg.payload.replacedBy;
        const doc = context.state.draftDocumentIndices[id];
        if (typeof doc === 'undefined') {
          return;
        }
        const docBody: ArticleSummary = context.state.draftDocuments[doc];
        docBody.documentBody = null;
        docBody.hierarchyParent = null;
        docBody.hierarchyWeight = null;
        docBody.isPublic = false;
        docBody.deleted = true;
        docBody.published = replacedBy == null;
        docBody.images = [];
        docBody.replacedBy = replacedBy;
        docBody.replaces = null;

        try {
          const url = '/api/services/app/Document/Update';
          await ajax.put(
            url,
            {
              ...docBody,
              replacedBy,
            },
            {
              headers: {
                'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
              },
            }
          );
        } catch {}

        context.dispatch(
          {
            type: 'articleList/startLoadDocuments',
          },
          { root: true }
        );
      }
    },
    async deleteNotice(
      context: ActionContext<ArticleListState, any>,
      arg: ActionPayload<string>
    ) {
      const id = arg.payload;
      try {
        const url = `/api/services/app/Notice/Delete?Id=${id}`;
        await ajax.delete(url, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });
      } catch {}
      context.dispatch({
        type: 'startLoadDocuments',
      });
    },

    async pickDocument(
      context: ActionContext<ArticleListState, RootState>,
      {
        payload,
      }: ActionPayload<
        string | { id: string; draft?: boolean; warnOnContextSwitch?: boolean }
      >
    ): Promise<boolean> {
      const id = typeof payload === 'string' ? payload : payload.id;
      const draft = typeof payload === 'string' ? false : payload.draft;
      // const warnOnContextSwitch =
      //   typeof payload === 'string' ? false : payload.warnOnContextSwitch;
      // const documentWasSelected = !!context.rootState.article.currentRemote;
      const protocolGroup = context.rootState.article.currentRemote?.isPublic
        ? context.rootState.article.currentRemote.protocolSection
        : '';

      context.commit({
        type: 'selectDocument',
        payload: id,
      });
      if (
        context.rootState.article.currentEditing &&
        context.rootState.article.currentEditing.documentName == id
      ) {
        // we already have the correct document selected.
        return true;
      }
      if (id) {
        const doc = draft
          ? context.state.draftDocumentIndices[id]
          : context.state.documentIndices[id];
        const docBody: ArticleSummary =
          typeof doc === 'undefined'
            ? {
                type: 'article',
                documentName: id,
                revisionCount: 0,
                modificationTime: '1900-01-01T00:00:00',
                keywords: [],
                documentBody: '',
                hierarchyParent: null,
                hierarchyWeight: null,
                isPublic: false,
                modificationUserName: '',
                images: [],
                protocolSection: 'ALS',
                published: false,
                isCurrent: true,
                replaces: null,
                replacedBy: null,
              }
            : draft
            ? context.state.draftDocuments[doc]
            : context.state.documents[doc];
        if (
          typeof doc !== 'undefined' &&
          protocolGroup &&
          docBody.isPublic &&
          docBody.protocolSection !== protocolGroup
        ) {
          if (
            !window.confirm(
              `Navigate from ${protocolGroup} to ${docBody.protocolSection}?`
            )
          ) {
            return false;
          }
        }

        context.commit(
          {
            type: 'article/selectDocument',
            payload: {
              doc: docBody,
              exists: typeof doc !== 'undefined',
              select: draft ? 'draft' : 'published',
            },
          },
          { root: true }
        );
        return true;
      }
      return true;
    },

    pickNotice(
      context: ActionContext<ArticleListState, any>,
      arg: ActionPayload<{ id: string; title: string | undefined }>
    ) {
      if (arg.payload) {
        const doc =
          arg.payload.id == 'new'
            ? undefined
            : context.state.noticeIndices[arg.payload.id];
        const throughDate = new Date(
          Date.now() + 7 * 24 * 60 * 60 * 1000
        ).toISOString();
        const docBody: Notice =
          typeof doc === 'undefined'
            ? {
                type: 'notice',
                modificationTime: '',
                modificationUserName: '',
                fromDate: null,
                throughDate,
                color: 'green',
                body: '',
                title: arg.payload.title || '',
                id: '',
                isDeleted: false,
              }
            : context.state.notices[doc];
        context.commit(
          {
            type: 'notice/selectNotice',
            payload: { doc: docBody, exists: typeof doc !== 'undefined' },
          },
          { root: true }
        );
      }
    },

    async loadIndex(
      context: ActionContext<ArticleListState, any>,
      args: ActionPayload<{}>
    ) {
      const localConcurrencyCheck = args.payload;
      if (localConcurrencyCheck != concurrencyCheck) {
        return;
      }
      let { indexModificationTime } = context.state;
      let indexUrl = '/api/services/app/IndexConfigurationService/GetAll';
      if (indexModificationTime) {
        indexUrl += '?MinRevisionDate=' + indexModificationTime;
      }
      try {
        if (!datastore) {
          return;
        }
        const indexResponse = await ajax.get(indexUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });

        const indices: IndexConfiguration[] = indexResponse.data.result.items.map(
          (a: IndexConfiguration) => {
            return {
              ...a,
              type: 'index',
            };
          }
        );
        if (!datastore) {
          return;
        }
        await Promise.all(
          indices.map(async doc => {
            if (!datastore) {
              return;
            }
            let _rev: string | undefined;
            try {
              const pouchDoc = await datastore.get(`index:_`);
              _rev = pouchDoc._rev;
            } catch {}
            await datastore.put({
              ...doc,
              type: 'index',
              _id: `index:_`,
              _rev,
            });
          })
        );
        if (!datastore) {
          return;
        }
        let update = false;
        for (const index of indices) {
          if (
            !indexModificationTime ||
            index.revisionDate > indexModificationTime
          ) {
            indexModificationTime = index.revisionDate;
            update = true;
          }
        }
        if (update) {
          context.commit({
            type: 'setMinRevisionDate',
            payload: { indexModificationTime },
          });
          let _rev: string | undefined;
          let articleModificationTime,
            attachmentModificationTime,
            noticesModificationTime,
            draftArticleModificationTime;
          while (true) {
            try {
              const lastRevisionDoc: any = await datastore.get(
                'local/last_revision_date'
              );
              _rev = lastRevisionDoc._rev;
              articleModificationTime = lastRevisionDoc.articleModificationTime;
              attachmentModificationTime =
                lastRevisionDoc.attachmentModificationTime;
              noticesModificationTime = lastRevisionDoc.noticesModificationTime;
              draftArticleModificationTime =
                lastRevisionDoc.draftArticleModificationTime;
            } catch {}
            if (!datastore) {
              return;
            }
            try {
              await datastore.put({
                type: 'metadata',
                _id: 'local/last_revision_date',
                _rev,
                articleModificationTime,
                attachmentModificationTime,
                indexModificationTime,
                noticesModificationTime,
                draftArticleModificationTime,
              });
              break;
            } catch {}
          }
        }
      } catch {
        // assume this is a spurious change.
      }
      // now check again in 30 seconds;
      window.setTimeout(() => {
        context.dispatch({
          type: 'loadIndex',
          payload: localConcurrencyCheck,
        });
      }, 30_000);
    },

    async loadAttachments(
      context: ActionContext<ArticleListState, any>,
      args: ActionPayload<{}>
    ) {
      const localConcurrencyCheck = args.payload;
      if (localConcurrencyCheck != concurrencyCheck) {
        return;
      }
      let { attachmentModificationTime } = context.state;
      let attachmentsUrl = '/api/services/app/Attachment/GetAll';
      if (attachmentModificationTime) {
        attachmentsUrl += '?MinRevisionDate=' + attachmentModificationTime;
      }
      try {
        if (!datastore) {
          return;
        }
        const attachmentsResponse = await ajax.get(attachmentsUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });

        const attachments: ImageAttachment[] = attachmentsResponse.data.result.items.map(
          (a: ImageAttachment) => {
            let fileSize = a.contents ? ((a.contents.length / 4) | 0) * 3 : 0;
            const lastThree =
              a.contents && a.contents.length > 4
                ? a.contents.substring(a.contents.length - 2)
                : '';
            if (lastThree.endsWith('==')) {
              fileSize -= 2;
            } else if (lastThree.endsWith('=')) {
              fileSize -= 1;
            }
            return {
              ...a,
              type: 'attachment',
              fileSize,
            };
          }
        );

        if (!datastore) {
          return;
        }
        await Promise.all(
          attachments.map(async doc => {
            if (!datastore) {
              return;
            }
            let found = false;
            let rev;
            try {
              rev = (await datastore.get(`attachment:${doc.id}`))._rev;
              found = true;
            } catch {}
            if (!datastore) {
              return;
            }
            if (!found) {
              await datastore.put({
                ...doc,
                type: 'attachment',
                id: `attachment:${doc.id}`,
                _id: `attachment:${doc.id}`,
              });
            } else if (doc.isDeleted) {
              await datastore.put({
                ...doc,
                _rev: rev,
                type: 'attachment',
                id: `attachment:${doc.id}`,
                _id: `attachment:${doc.id}`,
              });
            }
          })
        );
        if (!datastore) {
          return;
        }
        let update = false;
        for (const attachment of attachments) {
          if (
            !attachmentModificationTime ||
            attachment.creationTime > attachmentModificationTime
          ) {
            attachmentModificationTime = attachment.creationTime;
            update = true;
          }
        }
        if (update) {
          context.commit({
            type: 'setMinRevisionDate',
            payload: { attachmentModificationTime },
          });
          let _rev: string | undefined;
          let articleModificationTime,
            indexModificationTime,
            noticesModificationTime,
            draftArticleModificationTime;
          while (true) {
            try {
              const lastRevisionDoc: any = await datastore.get(
                'local/last_revision_date'
              );
              _rev = lastRevisionDoc._rev;
              articleModificationTime = lastRevisionDoc.articleModificationTime;
              indexModificationTime = lastRevisionDoc.indexModificationTime;
              noticesModificationTime = lastRevisionDoc.noticesModificationTime;
              draftArticleModificationTime =
                lastRevisionDoc.draftArticleModificationTime;
            } catch {}
            if (!datastore) {
              return;
            }
            try {
              await datastore.put({
                type: 'metadata',
                _id: 'local/last_revision_date',
                _rev,
                articleModificationTime,
                attachmentModificationTime,
                indexModificationTime,
                noticesModificationTime,
                draftArticleModificationTime,
              });
              break;
            } catch {}
          }
        }
      } catch {
        // assume this is a spurious change.
      }
      // now check again in 30 seconds;
      window.setTimeout(() => {
        context.dispatch({
          type: 'loadAttachments',
          payload: localConcurrencyCheck,
        });
      }, 30_000);
    },

    async loadNotices(
      context: ActionContext<ArticleListState, any>,
      args: ActionPayload<{}>
    ) {
      const localConcurrencyCheck = args.payload;
      if (localConcurrencyCheck != concurrencyCheck) {
        return;
      }
      let { noticesModificationTime } = context.state;
      let noticesUrl = '/api/services/app/Notice/GetAll';
      if (noticesModificationTime) {
        noticesUrl += '?MinRevisionDate=' + noticesModificationTime;
      }
      try {
        if (!datastore) {
          return;
        }
        const noticesResponse = await ajax.get(noticesUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });

        const notices: Notice[] = noticesResponse.data.result.items.map(
          (a: Notice) => {
            return {
              ...a,
              type: 'notice',
            };
          }
        );
        if (!datastore) {
          return;
        }
        await Promise.all(
          notices.map(async doc => {
            if (!datastore) {
              return;
            }
            let _rev: string | undefined;
            try {
              const pouchDoc = await datastore.get(`notice:${doc.id}`);
              _rev = pouchDoc._rev;

              if (
                'modificationTime' in pouchDoc &&
                doc.modificationTime <= pouchDoc.modificationTime
              ) {
                // don't bother. we already have this document.
                return;
              }
            } catch {}
            if (!datastore) {
              return;
            }
            await datastore.put({
              ...doc,
              type: 'notice',
              _id: `notice:${doc.id}`,
              _rev,
            });
            return;
          })
        );
        if (!datastore) {
          return;
        }
        let update = false;
        for (const doc of notices) {
          if (
            !noticesModificationTime ||
            doc.modificationTime > noticesModificationTime
          ) {
            noticesModificationTime = doc.modificationTime;
            update = true;
          }
        }
        context.commit({
          type: 'setMinRevisionDate',
          payload: {
            noticesModificationTime,
          },
        });

        if (update) {
          let _rev: string | undefined;
          let attachmentModificationTime,
            indexModificationTime,
            articleModificationTime,
            draftArticleModificationTime;
          while (true) {
            try {
              const lastRevisionDoc: any = await datastore.get(
                'local/last_revision_date'
              );
              _rev = lastRevisionDoc._rev;
              attachmentModificationTime =
                lastRevisionDoc.attachmentModificationTime;
              indexModificationTime = lastRevisionDoc.indexModificationTime;
              articleModificationTime = lastRevisionDoc.articleModificationTime;
              draftArticleModificationTime =
                lastRevisionDoc.draftArticleModificationTime;
            } catch {}
            if (!datastore) {
              return;
            }
            try {
              await datastore.put({
                type: 'metadata',
                _id: 'local/last_revision_date',
                _rev,
                articleModificationTime,
                attachmentModificationTime,
                indexModificationTime,
                noticesModificationTime,
                draftArticleModificationTime,
              });
              break;
            } catch {}
          }
        }
      } catch {
        // assume this is a spurious change.
        context.commit({
          type: 'setMinRevisionDate',
          payload: {},
        });
      }
      // now check again in 30 seconds;
      window.setTimeout(() => {
        context.dispatch({
          type: 'loadNotices',
          payload: localConcurrencyCheck,
        });
      }, 30_000);
    },

    async loadDocuments(
      context: ActionContext<ArticleListState, any>,
      args: ActionPayload<{}>
    ) {
      const localConcurrencyCheck = args.payload;
      if (localConcurrencyCheck != concurrencyCheck) {
        return;
      }
      let {
        articleModificationTime,
        draftArticleModificationTime,
      } = context.state;
      const isLoggedIn = !!abp.session.userId;
      let articlesUrl = '/api/services/app/Document/GetAll?IncludeBody=true';
      if (isLoggedIn) {
        if (draftArticleModificationTime) {
          articlesUrl += '&MinRevisionDate=' + draftArticleModificationTime;
        }
        articlesUrl += '&IncludeDrafts=true';
      } else if (articleModificationTime) {
        articlesUrl += '&MinRevisionDate=' + articleModificationTime;
      }
      try {
        if (!datastore) {
          return;
        }
        const docsResponse = await ajax.get(articlesUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });

        const docs: ArticleSummary[] = docsResponse.data.result.items.map(
          (a: ArticleSummary) => {
            return {
              ...a,
              type: 'article',
            };
          }
        );
        if (!datastore) {
          return;
        }
        await Promise.all(
          docs
            .filter(doc => doc.published)
            .map(async doc => {
              if (!datastore) {
                return;
              }
              let _publishedRev: string | undefined;
              try {
                const pouchDoc = await datastore.get(
                  `article:${doc.documentName}`
                );
                _publishedRev = pouchDoc._rev;

                if (
                  'revisionCount' in pouchDoc &&
                  doc.revisionCount <= pouchDoc.revisionCount
                ) {
                  // don't bother. we already have this document.
                  return;
                }
              } catch {}
              if (!datastore) {
                return;
              }
              await datastore.put({
                ...doc,
                type: 'article',
                _id: `article:${doc.documentName}`,
                _rev: _publishedRev,
              });
              window.setTimeout(() => {
                context.dispatch(
                  { type: 'search/primeSearch' },
                  { root: true }
                );
              }, 20_000);
              return;
            })
        );
        await Promise.all(
          docs
            .filter(doc => doc.isCurrent)
            .map(async doc => {
              if (!datastore) {
                return;
              }
              let _publishedRev: string | undefined;
              try {
                const pouchDoc = await datastore.get(
                  `draft:${doc.documentName}`
                );
                _publishedRev = pouchDoc._rev;

                if (
                  'revisionCount' in pouchDoc &&
                  doc.revisionCount <= pouchDoc.revisionCount
                ) {
                  // don't bother. we already have this document.
                  return;
                }
              } catch {}
              if (!datastore) {
                return;
              }
              await datastore.put({
                ...doc,
                type: 'article',
                _id: `draft:${doc.documentName}`,
                _rev: _publishedRev,
              });
              return;
            })
        );
        context.commit('completeInitialLoad');
        if (!datastore) {
          return;
        }
        let update = false;
        for (const doc of docs) {
          if (
            doc.published &&
            (!articleModificationTime ||
              doc.modificationTime > articleModificationTime)
          ) {
            articleModificationTime = doc.modificationTime;
            update = true;
          }
          if (
            doc.isCurrent &&
            (!draftArticleModificationTime ||
              doc.modificationTime > draftArticleModificationTime)
          ) {
            draftArticleModificationTime = doc.modificationTime;
            update = true;
          }
        }
        if (update) {
          context.commit({
            type: 'setMinRevisionDate',
            payload: { articleModificationTime, draftArticleModificationTime },
          });

          let _rev: string | undefined;
          let attachmentModificationTime,
            indexModificationTime,
            noticesModificationTime;
          while (true) {
            try {
              const lastRevisionDoc: any = await datastore.get(
                'local/last_revision_date'
              );
              _rev = lastRevisionDoc._rev;
              attachmentModificationTime =
                lastRevisionDoc.attachmentModificationTime;
              indexModificationTime = lastRevisionDoc.indexModificationTime;
              noticesModificationTime = lastRevisionDoc.noticesModificationTime;
            } catch {}
            if (!datastore) {
              return;
            }
            try {
              await datastore.put({
                type: 'metadata',
                _id: 'local/last_revision_date',
                _rev,
                draftArticleModificationTime,
                articleModificationTime,
                attachmentModificationTime,
                indexModificationTime,
                noticesModificationTime,
              });
              break;
            } catch {}
          }
        }
      } catch {
        // assume this is a spurious change.
      }
      // now check again in 30 seconds;

      window.setTimeout(() => {
        context.dispatch({
          type: 'loadDocuments',
          payload: localConcurrencyCheck,
        });
      }, 30_000);
    },

    startLoadDocuments(context: ActionContext<ArticleListState, any>) {
      const localConcurrencyCheck = (concurrencyCheck = {});
      context.dispatch({
        type: 'loadDocuments',
        payload: localConcurrencyCheck,
      });
      context.dispatch({
        type: 'loadAttachments',
        payload: localConcurrencyCheck,
      });
      context.dispatch({
        type: 'loadIndex',
        payload: localConcurrencyCheck,
      });
      context.dispatch({
        type: 'loadNotices',
        payload: localConcurrencyCheck,
      });
    },

    async initDatastore(context: ActionContext<ArticleListState, any>) {
      if (context.state.isSyncing) {
        return;
      }
      context.commit('beginSyncing');
      initDatastore();

      const changeStream = datastore
        .changes({ live: true, since: 'now', include_docs: true })
        .on('change', changes => {
          if (changes.id.startsWith('local/')) {
            return;
          }
          if (changes.doc?.type == 'article') {
            context.commit({
              type: 'loadDocuments',
              payload: [changes.doc],
            });
            context.commit(
              {
                type: 'article/updateFromRemote',
                payload: [changes.doc],
              },
              { root: true }
            );
            context.dispatch(
              {
                type: 'articleRevision/updateFromRemote',
                payload: changes.doc,
              },
              { root: true }
            );
          } else if (changes.doc?.type == 'attachment') {
            context.commit({
              type: 'loadDocuments',
              payload: [changes.doc],
            });
          } else if (changes.doc?.type == 'index') {
            context.commit(
              {
                type: 'indexConfiguration/updateFromRemote',
                payload: changes.doc,
              },
              { root: true }
            );
            context.commit({
              type: 'loadDocuments',
              payload: [changes.doc],
            });
          } else if (changes.doc?.type == 'notice') {
            context.commit(
              {
                type: 'notice/updateFromRemote',
                payload: [changes.doc],
              },
              { root: true }
            );
            context.commit({
              type: 'loadDocuments',
              payload: [changes.doc],
            });
          }
        });
      const docs = await datastore.allDocs({ include_docs: true });
      const allDocs = docs.rows
        .filter(d => !d.id.startsWith('local/'))
        .map(f => f.doc!);
      context.commit({
        type: 'loadDocuments',
        payload: allDocs,
      });
      context.commit(
        {
          type: 'article/updateFromRemote',
          payload: allDocs.filter(f => f.type == 'article'),
        },
        { root: true }
      );
      const indices = allDocs.filter(f => f.type == 'index');
      const index = indices.length > 0 ? indices[0] : undefined;
      if (index) {
        context.commit(
          {
            type: 'indexConfiguration/updateFromRemote',
            payload: index,
          },
          { root: true }
        );
      }
      if (allDocs.filter(f => f.type == 'article').length > 0) {
        context.commit('completeInitialLoad');
      }
      try {
        const revisions = await datastore.get('local/last_revision_date');
        if (revisions.type == 'metadata') {
          const {
            attachmentModificationTime,
            articleModificationTime,
            indexModificationTime,
            draftArticleModificationTime,
          } = revisions;
          revisions.attachmentModificationTime;
          context.commit({
            type: 'setMinRevisionDate',
            payload: {
              attachmentModificationTime:
                attachmentModificationTime || '1900-01-01T00:00:00',
              articleModificationTime:
                articleModificationTime || '1900-01-01T00:00:00',
              draftArticleModificationTime:
                draftArticleModificationTime || '1900-01-01T00:00:00',
              indexModificationTime:
                indexModificationTime || '1900-01-01T00:00:00',
            },
          });
        }
      } catch {}

      context.dispatch({
        type: 'startLoadDocuments',
      });

      unmountDatastore = () => {
        changeStream.cancel();
        if (datastore) {
          datastore.close();
        }
        concurrencyCheck = undefined;
        datastore = undefined!;
      };
    },

    async closeDatastore(context: ActionContext<ArticleListState, any>) {
      unmountDatastore();
      context.commit('endSyncing');
    },

    async blobifyAttachments(
      context: ActionContext<ArticleListState, any>,
      args: ActionPayload<{ pending: string[]; user: string }>
    ) {
      const retrieveImage = async (src: string): Promise<void> => {
        const doc = await datastore.get(src);
        if (doc.type != 'attachment') {
          return;
        }
        const rawBuffer = base64Decode(doc.contents || '');
        const fileBlob = new Blob([rawBuffer]);
        const blobURL = URL.createObjectURL(fileBlob);
        context.commit({
          type: 'finishLoadingBlob',
          payload: {
            src: src,
            dest: blobURL,
          },
        });
      };
      const newAttachments = args.payload.pending.filter(
        src => !(src in context.state.pendingAttachmentMap)
      );
      context.commit({
        type: 'startLoadingBlob',
        payload: args.payload,
      });
      newAttachments.map(retrieveImage);
    },

    beginEditingIndexConfiguration(
      context: ActionContext<ArticleListState, any>
    ) {
      context.commit(
        {
          type: 'indexConfiguration/startEditing',
          payload: context.state.indexConfiguration,
        },
        { root: true }
      );
    },
  };

  mutations = {
    finishLoadingBlob(
      state: ArticleListState,
      args: ActionPayload<{ src: string; dest: string }>
    ) {
      if (!(args.payload.src in state.attachmentMap)) {
        delete state.pendingAttachmentMap[args.payload.src];
        URL.revokeObjectURL(args.payload.dest);
      }
      if (state.attachmentMap[args.payload.src].url != '') {
        URL.revokeObjectURL(args.payload.dest);
      }
      const attachmentMap = {
        ...state.attachmentMap,
        [args.payload.src]: {
          url: args.payload.dest,
          users: state.attachmentMap[args.payload.src].users,
        },
      };
      state.attachmentMap = attachmentMap;
    },
    startLoadingBlob(
      state: ArticleListState,
      args: ActionPayload<{ pending: string[]; user: string }>
    ) {
      for (const file of args.payload.pending) {
        state.pendingAttachmentMap[file] = true;
        state.attachmentMap[file] = state.attachmentMap[file] || {
          url: '',
          users: {},
        };
        state.attachmentMap[file].users[args.payload.user] = true;
      }
      for (const file in state.attachmentMap) {
        if (!(file in state.pendingAttachmentMap)) {
          if (args.payload.user in state.attachmentMap[file].users) {
            delete state.attachmentMap[file].users[args.payload.user];
          }
          if (Object.keys(state.attachmentMap[file].users).length) {
            continue;
          }
          if (state.attachmentMap[file].url) {
            URL.revokeObjectURL(state.attachmentMap[file].url);
          }
          delete state.attachmentMap[file];
        }
      }
    },
    setMinRevisionDate(
      state: ArticleListState,
      args: ActionPayload<{
        articleModificationTime?: string;
        draftArticleModificationTime?: string;
        attachmentModificationTime?: string;
        indexModificationTime?: string;
        noticesModificationTime?: string;
      }>
    ) {
      state.draftArticleModificationTime =
        args.payload.draftArticleModificationTime ||
        state.draftArticleModificationTime;
      state.articleModificationTime =
        args.payload.articleModificationTime || state.articleModificationTime;
      state.attachmentModificationTime =
        args.payload.attachmentModificationTime ||
        state.attachmentModificationTime;
      state.indexModificationTime =
        args.payload.indexModificationTime || state.indexModificationTime;
      state.noticesModificationTime =
        args.payload.noticesModificationTime || state.noticesModificationTime;
      state.currentTime = new Date().toISOString();
      state.currentDate = localTimeToLocalDate();
    },
    selectDocument(state: ArticleListState, args: ActionPayload<string>) {
      appInsights.startTrackEvent(`page loading:${args.payload}`);
      state.currentlySelectedDocument = args.payload;
    },
    completeInitialLoad(state: ArticleListState) {
      state.completedInitialLoad = true;
    },
    beginSyncing(state: ArticleListState) {
      state.isSyncing = true;
    },
    endSyncing(state: ArticleListState) {
      state.isSyncing = false;
    },
    loadDocuments(
      state: ArticleListState,
      args: ActionPayload<
        ((ArticleSummary | ImageAttachment | IndexConfiguration | Notice) &
          PouchDB.Core.AllDocsMeta)[]
      >
    ) {
      const docs = [...state.documents];
      const draftDocs = [...state.draftDocuments];
      const attachments = [...state.attachments];
      const notices = [...state.notices];

      const imageDeltas: { [attachmentName: string]: number } = {};

      for (const doc of args.payload) {
        if (doc.type == 'article') {
          if (doc.published) {
            if (doc.documentName in state.documentIndices) {
              const docWas =
                state.documents[state.documentIndices[doc.documentName]];
              for (const attachment of docWas.images || []) {
                imageDeltas[attachment] = (imageDeltas[attachment] || 0) - 1;
              }
            }
            for (const image of doc.images || []) {
              imageDeltas[image] = (imageDeltas[image] || 0) + 1;
            }
            insertIntoSorted(docs, doc, 'documentName');
          }
          if (doc.isCurrent) {
            insertIntoSorted(draftDocs, doc, 'documentName');
          }
        } else if (doc.type == 'attachment') {
          doc.usageCount = state.imageCounts[doc.id] || 0;
          insertIntoSorted(attachments, doc, 'id');
        } else if (doc.type == 'index') {
          state.indexConfiguration = doc;
        } else if (doc.type == 'notice') {
          insertIntoSorted(notices, doc, 'id');
          if (doc.id in state.noticeIndices) {
            const noticeWas = state.notices[state.noticeIndices[doc.id]];
            let fromTime = noticeWas.fromDate;
            if (!fromTime) {
              // From date and modification time aren't quite the same thing.
              // fromDate is a date only. Time Zone is disregarded. Modification time is a time
              // stamp so you have to convert to local time, then drop the time portion.
              const newDate = new Date(noticeWas.modificationTime);
              fromTime = newDate.toISOString();
            }
            const throughTime = noticeWas.throughDate!;
            const fromDate = fromTime.split('T')[0];
            const throughDate = throughTime.split('T')[0];
            let indexDate = fromDate;
            while (indexDate <= throughDate) {
              if (indexDate in state.noticesByDailyBucket) {
                if (doc.id in state.noticesByDailyBucket[indexDate]) {
                  delete state.noticesByDailyBucket[indexDate][doc.id];
                  if (
                    Object.keys(state.noticesByDailyBucket[indexDate]).length ==
                    0
                  ) {
                    delete state.noticesByDailyBucket[indexDate];
                  }
                }
              }
              indexDate = new Date(
                Date.parse(indexDate + 'T00:00:00Z') + 24 * 60 * 60 * 1000
              )
                .toISOString()
                .split('T')[0];
            }
          }
          if (!doc.isDeleted) {
            let fromTime = doc.fromDate;
            if (!fromTime) {
              // From date and modification time aren't quite the same thing.
              // fromDate is a date only. Time Zone is disregarded. Modification time is a time
              // stamp so you have to convert to local time, then drop the time portion.
              const newDate = new Date(doc.modificationTime);
              // not sure why this was not this way in the first place. per spec
              // toISODate is always in UTC.
              fromTime = newDate.toISOString();
            }
            const throughTime = doc.throughDate!;
            const newDate = new Date();
            const nowTime = newDate.toISOString();
            if (nowTime > fromTime) {
              fromTime = nowTime;
            }
            const fromDate = fromTime.split('T')[0];
            const throughDate = throughTime.split('T')[0];
            let indexDate = fromDate;
            while (indexDate <= throughDate) {
              if (!(indexDate in state.noticesByDailyBucket)) {
                state.noticesByDailyBucket[indexDate] = {};
              }
              state.noticesByDailyBucket[indexDate][doc.id] = true;
              indexDate = new Date(
                Date.parse(indexDate + 'T00:00:00Z') + 24 * 60 * 60 * 1000
              )
                .toISOString()
                .split('T')[0];
            }
          }
        }
      }
      const documentIndices: { [documentName: string]: number } = {};
      let ix = 0;
      for (const doc of docs) {
        documentIndices[doc.documentName] = ix++;
      }
      ix = 0;
      const draftDocumentIndices: { [documentName: string]: number } = {};
      for (const doc of draftDocs) {
        draftDocumentIndices[doc.documentName] = ix++;
      }
      ix = 0;

      const attachmentIndices: { [attachmentId: string]: number } = {};
      for (const doc of attachments) {
        // save some memory.
        delete doc.contents;
        attachmentIndices[doc.id] = ix++;
      }
      ix = 0;
      const noticeIndices: { [noticeId: string]: number } = {};
      for (const doc of notices) {
        noticeIndices[doc.id] = ix++;
      }

      const imageCounts = {
        ...state.imageCounts,
      };
      const changedImages: string[] = [];
      let changed = false;
      for (const image in imageDeltas) {
        if (imageDeltas[image] == 0) {
          continue;
        }
        changed = true;
        changedImages.push(image);
        imageCounts[image] = (imageCounts[image] || 0) + imageDeltas[image];
        if (imageCounts[image] == 0) {
          delete imageCounts[image];
        }
      }
      if (changed) {
        state.imageCounts = imageCounts;
        for (const image of changedImages) {
          if (image in attachmentIndices) {
            attachments[attachmentIndices[image]].usageCount =
              imageCounts[image];
          }
        }
      }

      state.documents = docs;
      state.documentIndices = documentIndices;
      state.attachments = attachments;
      state.attachmentIndices = attachmentIndices;
      state.notices = notices;
      state.noticeIndices = noticeIndices;
      state.draftDocuments = draftDocs;
      state.draftDocumentIndices = draftDocumentIndices;
    },
  };
}

interface ActionPayload<T> extends Payload {
  payload: T;
}

const articleList = new ArticleListStore();
export default articleList;
