
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';
import VMenuCaret from '../components/vMenuCaret.vue';

import {
  VTextField,
  VBtn,
  VIcon,
  VFlex,
  VSpacer,
  VChip,
  VMenu,
  VList,
  VListItemTitle,
  VListItemAction,
  VListItemContent,
  VCheckbox,
  VListItem,
  VListItemGroup,
} from 'vuetify/lib';
import MiniIndex from './smallIndex.vue';
import SearchResults from './searchResults.vue';
import type { ArticleSummary } from '../store/modules/article-list';
import type { Route } from 'vue-router';

@Component({
  components: {
    VTextField,
    VBtn,
    VIcon,
    VFlex,
    VSpacer,
    VChip,
    MiniIndex,
    SearchResults,
    VMenu,
    VList,
    VListItemTitle,
    VListItemAction,
    VListItemContent,
    VCheckbox,
    VListItem,
    VListItemGroup,
    VMenuCaret,
  },
})
export default class SearchBar extends AbpBase {
  searchSection: 'ALS' | 'BLS' | null = null;
  search = '';
  count = 10;
  searching = false;
  toc = false;
  sectionsOpen = false;

  @Prop(Boolean)
  mobile!: boolean;

  @Prop(String)
  section!: 'ALS' | 'BLS';

  @Watch('search')
  @Watch('searchSection')
  @Watch('count')
  onSearchParamsChange(): void {
    const rawTerm = this.$store.state.search.rawSearch;
    const section = this.$store.state.search.section;
    let doSearch = false;

    if (this.search != rawTerm || section != this.searchSection) {
      this.count = 10;
      doSearch = true;
    }
    if (this.count != this.$store.state.search.count) {
      doSearch = true;
    }
    if (!doSearch) {
      return;
    }

    void this.$store.dispatch({
      type: 'search/setSearch',
      payload: {
        term: this.search,
        section: this.searchSection,
        count: this.count,
      },
    });
    const query = {
      ...this.route.query,
    };

    delete query['toc'];
    delete query['q'];
    delete query['qs'];

    const routeIsSearching = 'q' in this.route.query;
    const route = {
      name: this.route.name!,
      params: this.route.params,
      query,
    };
    if (this.searching) {
      query['q'] = this.search;
      query['qs'] = this.searchSection || 'ALL';
    }
    if (routeIsSearching) {
      void this.$router.replace(route);
    } else if (this.searching) {
      void this.$router.push(route);
    }
  }

  clearSearch(): void {
    const routeIsSearching = 'q' in this.route.query;
    if (routeIsSearching) {
      void this.$router.back();
      this.searching = false;
      return;
    }

    this.searching = false;
    this.toc = 'toc' in this.route.query && this.route.query['toc'] == 'true';
  }

  toggleToc(): void {
    // okay so first of all, if we were not TOC and we now ARE TOC, we need to
    // add this to the nav stack
    if (!this.toc) {
      const query = {
        ...this.route.query,
      };
      delete query['q'];
      delete query['qs'];
      query['toc'] = 'true';
      const route = {
        name: this.route.name!,
        params: this.route.params,
        query,
      };
      void this.$router.push(route);
      return;
      // this will cause the ROUTE to be updated, and then the TOC value will be set to match the route
    }

    // if we were TOC and now we are not, we need to remove it from the nav stack
    void this.$router.back();
  }

  @Watch('toc')
  onToc(toc: boolean): void {
    if (toc) {
      this.searching = false;
    }
  }

  @Watch('searching')
  onSearch(searching: boolean): void {
    if (searching) {
      this.toc = false;
    }
    if (searching && this.mobile) {
      window.setTimeout(() => {
        const node = this.$refs['searchbox'] as HTMLElement;
        if (this.searching && this.mobile) {
          node.focus();
        }
      }, 400);
    }
  }

  @Watch('mobile')
  onMobile(mobile: boolean): void {
    if (!mobile) {
      this.searching = false;
      this.toc = false;

      const query = {
        ...this.route.query,
      };
      if ('toc' in query) {
        delete query['toc'];
        const route = {
          name: this.route.name!,
          params: this.route.params,
          query,
        };
        void this.$router.replace(route);
      }
    }
  }

  get currentItem(): ArticleSummary | null {
    if (this.$route.name === 'article') {
      return this.$store.state.article.currentRemote;
    }
    return null;
  }

  get currentItemSection(): string {
    if (!this.currentItem) {
      return '';
    }
    return this.currentItem.isPublic ? this.currentItem.protocolSection : '';
  }

  get currentSearchSection(): string {
    return this.searchSection || 'All';
  }

  get currentItemSectionColor(): string {
    switch (this.currentItemSection) {
      case 'ALS':
        return 'red';
      case 'BLS':
        return 'blue';
    }
    return '';
  }

  get currentSearchSectionColor(): string {
    switch (this.currentSearchSection) {
      case 'ALS':
        return 'red';
      case 'BLS':
        return 'blue';
    }
    return 'gray';
  }

  // get currentIcon(): string | null {
  //   if (!this.currentItem) {
  //     return 'mdi-home';
  //   }
  //   if (
  //     !this.currentItem.hierarchyParent ||
  //     this.currentItem.hierarchyParent.length == 0
  //   ) {
  //     return '';
  //   }
  //   let relevantSection: IndexSection | undefined;
  //   const sections =
  //     this.section == 'BLS'
  //       ? this.$store.state.articleList.indexConfiguration.blsSections
  //       : this.$store.state.articleList.indexConfiguration.alsSections;
  //   let searchpath = sections;
  //   for (let i = 0; i < this.currentItem.hierarchyParent.length; i++) {
  //     const hierarchyPath = this.currentItem.hierarchyParent[i];
  //     const relevantSections = searchpath.filter(s => s.name == hierarchyPath);
  //     if (relevantSections.length > 0) {
  //       relevantSection = relevantSections[0];
  //       searchpath = relevantSection.children;
  //     } else {
  //       return '';
  //     }
  //   }
  //   if (!relevantSection) {
  //     return '';
  //   }
  //   return relevantSection.icon;
  // }

  get route(): Route {
    return this.$route;
  }

  @Watch('route')
  onRouteChanged(): void {
    this.toc = this.route.query['toc'] == 'true';
    if (this.toc) {
      this.searching = false;
      return;
    }
    const query = this.route.query['q'];
    const section = this.route.query['qs'];
    if (query || section) {
      this.searching = true;
      this.search = query as string;
      if (section) {
        const searchSection = section as 'ALS' | 'BLS' | 'ALL';
        this.searchSection = searchSection == 'ALL' ? null : searchSection;
      } else {
        this.searchSection = null;
      }
    } else {
      this.searching = false;
    }
  }

  @Emit('togglelayout')
  toggleLayout(): void {
    console.log('togglinglayout');
  }
}
