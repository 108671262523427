
import { Component, Watch } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase';
@Component({})
export default class NotFoundView extends AbpBase {
  get id(): string {
    return this.$route.params['id'];
  }

  created() {
    this.$store.dispatch('articleList/initDatastore');
  }
  get documentsAreReady(): boolean {
    const state = this.$store.state.articleList;
    return state.completedInitialLoad;
  }

  mounted() {
    if (this.exists) {
      this.onExists(true);
    }
  }

  get exists() {
    const state = this.$store.state.articleList;
    const exists = state.documentIndices[this.id] !== undefined;
    return exists;
  }

  @Watch('exists')
  onExists(exists: boolean) {
    if (exists) {
      this.$router.replace({ name: 'article', params: { id: this.id } });
    }
  }
}
