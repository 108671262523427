var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "search-bar-host", class: { mobile: _vm.mobile } },
    [
      _c("transition", { attrs: { name: "fly-up" } }, [
        _vm.mobile && _vm.toc
          ? _c(
              "div",
              { staticClass: "cover elevation-3" },
              [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "close",
                        attrs: {
                          "aria-label": _vm.L("Close"),
                          large: "",
                          icon: "",
                          color: "black",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleToc()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-close")])],
                      1
                    ),
                  ],
                  1
                ),
                _c("mini-index", {
                  on: {
                    togglelayout: function ($event) {
                      return _vm.toggleLayout()
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.mobile
        ? _c(
            "div",
            {
              staticClass: "bottom-bar elevation-3",
              attrs: { role: "toolbar" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flexroot" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "expansion",
                      attrs: {
                        text: "",
                        color: "primary",
                        "aria-label": _vm.L("TableOfContents"),
                        "aria-haspopup": "true",
                        "aria-expanded": _vm.toc ? "true" : "false",
                      },
                      on: { click: _vm.toggleToc },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-menu")]),
                      _vm._v(" " + _vm._s(_vm.L("Menu")) + " "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        "aria-label": _vm.L("Search"),
                        icon: "",
                        "aria-haspopup": "true",
                        "aria-expanded": _vm.searching ? "true" : "false",
                      },
                      on: {
                        click: function ($event) {
                          _vm.searching = true
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-magnify")])],
                    1
                  ),
                  _vm.currentItemSection
                    ? _c(
                        "div",
                        { staticClass: "category" },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                dark: "",
                                color: _vm.currentItemSectionColor,
                              },
                            },
                            [_vm._v(_vm._s(_vm.currentItemSection))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("transition", { attrs: { name: "fly-up", role: "dialog" } }, [
        _vm.mobile && _vm.searching
          ? _c(
              "div",
              {
                staticClass: "cover elevation-3",
                attrs: { role: "search", "aria-label": _vm.L("AllProtocols") },
              },
              [
                _c(
                  "v-text-field",
                  {
                    ref: "searchbox",
                    staticClass: "searchbox",
                    attrs: {
                      rounded: "",
                      outlined: "",
                      light: "",
                      dense: "",
                      "hide-details": "",
                      "aria-label": _vm.L("Search"),
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "v-input__icon v-input__icon--prepend-inner",
                        attrs: { slot: "prepend-inner" },
                        slot: "prepend-inner",
                      },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-y": "",
                              transition: "slide-y-transition",
                              "content-class": "caret-menu-content",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "noink caret",
                                              attrs: {
                                                ripple: false,
                                                icon: "",
                                                "aria-label":
                                                  _vm.L("Select Section"),
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-magnify")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2864535145
                            ),
                            model: {
                              value: _vm.sectionsOpen,
                              callback: function ($$v) {
                                _vm.sectionsOpen = $$v
                              },
                              expression: "sectionsOpen",
                            },
                          },
                          [
                            _c(
                              "v-menu-caret",
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "dropdown" },
                                  [
                                    _c(
                                      "v-list-item-group",
                                      { attrs: { multiple: "" } },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              active:
                                                _vm.searchSection == "ALS" ||
                                                _vm.searchSection == null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.searchSection =
                                                  _vm.searchSection == "BLS"
                                                    ? null
                                                    : _vm.searchSection == "ALS"
                                                    ? "BLS"
                                                    : "BLS"
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(_vm.L("ALS"))),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    "input-value":
                                                      _vm.searchSection ==
                                                        "ALS" ||
                                                      _vm.searchSection == null,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              active:
                                                _vm.searchSection == "BLS" ||
                                                _vm.searchSection == null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.searchSection =
                                                  _vm.searchSection == "ALS"
                                                    ? null
                                                    : _vm.searchSection == "BLS"
                                                    ? "ALS"
                                                    : "ALS"
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(_vm.L("BLS"))),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    "input-value":
                                                      _vm.searchSection ==
                                                        "BLS" ||
                                                      _vm.searchSection == null,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "v-input__icon v-input__icon--append",
                        attrs: { slot: "append" },
                        slot: "append",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "searchhint" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  dark: "",
                                  color: _vm.currentSearchSectionColor,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.sectionsOpen = !_vm.sectionsOpen
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.currentSearchSection) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "noink",
                            attrs: {
                              ripple: false,
                              icon: "",
                              "aria-label": _vm.L("Clear"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clearSearch()
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    _vm.search
                      ? _c("search-results", {
                          on: {
                            selected: function ($event) {
                              _vm.searching = false
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fly-in" } }, [
        !_vm.mobile
          ? _c(
              "div",
              {
                staticClass: "sidebar elevation-3",
                attrs: { role: "search", "aria-label": _vm.L("AllProtocols") },
              },
              [
                _c(
                  "v-text-field",
                  {
                    staticClass: "search",
                    attrs: {
                      rounded: "",
                      outlined: "",
                      light: "",
                      dense: "",
                      "hide-details": "",
                      "aria-label": _vm.L("Search"),
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "v-input__icon v-input__icon--prepend-inner",
                        attrs: { slot: "prepend-inner" },
                        slot: "prepend-inner",
                      },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              "offset-y": "",
                              transition: "slide-y-transition",
                              "content-class": "caret-menu-content",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "noink caret",
                                              attrs: {
                                                ripple: false,
                                                icon: "",
                                                "aria-label":
                                                  _vm.L("Select Section"),
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-magnify")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2864535145
                            ),
                            model: {
                              value: _vm.sectionsOpen,
                              callback: function ($$v) {
                                _vm.sectionsOpen = $$v
                              },
                              expression: "sectionsOpen",
                            },
                          },
                          [
                            _c(
                              "v-menu-caret",
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "dropdown" },
                                  [
                                    _c(
                                      "v-list-item-group",
                                      { attrs: { multiple: "" } },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              active:
                                                _vm.searchSection == "ALS" ||
                                                _vm.searchSection == null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.searchSection =
                                                  _vm.searchSection == "BLS"
                                                    ? null
                                                    : _vm.searchSection == "ALS"
                                                    ? "BLS"
                                                    : "BLS"
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(_vm.L("ALS"))),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    "input-value":
                                                      _vm.searchSection ==
                                                        "ALS" ||
                                                      _vm.searchSection == null,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: {
                                              active:
                                                _vm.searchSection == "BLS" ||
                                                _vm.searchSection == null,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.searchSection =
                                                  _vm.searchSection == "ALS"
                                                    ? null
                                                    : _vm.searchSection == "BLS"
                                                    ? "ALS"
                                                    : "ALS"
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(_vm._s(_vm.L("BLS"))),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    "input-value":
                                                      _vm.searchSection ==
                                                        "BLS" ||
                                                      _vm.searchSection == null,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "v-input__icon v-input__icon--append",
                        attrs: { slot: "append" },
                        slot: "append",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "searchhint" },
                          [
                            _c(
                              "v-chip",
                              {
                                attrs: {
                                  dark: "",
                                  color: _vm.currentSearchSectionColor,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.sectionsOpen = !_vm.sectionsOpen
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.currentSearchSection))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "noink",
                            attrs: {
                              ripple: false,
                              icon: "",
                              "aria-label": _vm.L("Clear"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.search = ""
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "scroller" },
                  [
                    _c("mini-index", {
                      attrs: { section: _vm.section },
                      on: {
                        togglelayout: function ($event) {
                          return _vm.toggleLayout()
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "transition",
                  { attrs: { name: "fade" } },
                  [
                    _vm.search
                      ? _c("search-results", {
                          on: {
                            underflow: function ($event) {
                              _vm.count += 10
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }