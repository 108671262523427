export interface EditorSelection {
  fromLine: number;
  fromCol: number;
  toLine: number;
  toCol: number;
}

export function getSelectionContents(
  editorSelection: EditorSelection,
  content: string
): { prefix: string; suffix: string; selection: string } {
  const lines = content.split('\n');
  const linesPrefix = lines.slice(0, editorSelection.fromLine - 1).join('\n');
  const linesSuffix = lines.slice(editorSelection.toLine).join('\n');
  const spanPrefix = lines[editorSelection.fromLine - 1].slice(
    0,
    editorSelection.fromCol - 1
  );
  const spanSuffix = lines[editorSelection.toLine - 1].slice(
    editorSelection.toCol - 1
  );
  let selection: string;
  if (editorSelection.fromLine == editorSelection.toLine) {
    selection = lines[editorSelection.fromLine - 1].slice(
      editorSelection.fromCol - 1,
      editorSelection.toCol - 1
    );
  } else {
    const selectionFirstLine = lines[editorSelection.fromLine - 1].slice(
      editorSelection.fromCol - 1
    );
    const selectionLastLine = lines[editorSelection.toLine - 1].slice(
      0,
      editorSelection.toCol - 1
    );
    const additionalSelectionLines = lines.slice(
      editorSelection.fromLine,
      editorSelection.toLine - 1
    );
    selection =
      selectionFirstLine +
      '\n' +
      (additionalSelectionLines.length
        ? additionalSelectionLines.join('\n') + '\n'
        : '') +
      selectionLastLine;
  }
  const prefix =
    editorSelection.fromLine == 1
      ? spanPrefix
      : linesPrefix + '\n' + spanPrefix;
  const suffix =
    editorSelection.toLine == lines.length
      ? spanSuffix
      : spanSuffix + '\n' + linesSuffix;
  return { selection, prefix, suffix };
}

export function modifySelection(
  start: string,
  end: string,
  defaultContent: string,
  selection: EditorSelection,
  content: string,
  endMatcher?: string | RegExp
): { output: string; selection: EditorSelection } {
  if (typeof endMatcher === 'undefined') {
    endMatcher = end;
  }
  const { prefix, suffix, selection: selected } = getSelectionContents(
    selection,
    content
  );
  let selectedSpan = selected;

  const suffixMatches = (suffix: string): false | number => {
    if (typeof endMatcher === 'string') {
      return suffix.startsWith(endMatcher) && endMatcher.length;
    }
    const result = endMatcher!.exec(suffix);
    if (!result) {
      return false;
    }
    return result.index == 0 && result[0].length;
  };

  const suffixLength = suffixMatches(suffix);
  let remove = prefix.endsWith(start) && typeof suffixLength != 'boolean';

  // let spanIsEmpty = !selectedSpan;
  selectedSpan = selectedSpan || defaultContent;
  const output = remove
    ? prefix.substring(0, prefix.length - start.length) +
      selectedSpan +
      suffix.substring(suffixLength || 0)
    : prefix + start + selectedSpan + end + suffix;
  selection = {
    ...selection,
    fromCol: selection.fromCol + (remove ? -1 * start.length : start.length),
    toCol:
      selection.fromLine == selection.toLine
        ? selection.toCol + (remove ? -1 * start.length : start.length)
        : selection.toCol,
  };
  return { output, selection };
}

export function modifyLine(
  linePrefix: string | ((ix: number) => string),
  defaultContent: string,
  selection: EditorSelection,
  content: string,
  one?: boolean
): { output: string; selection: EditorSelection } {
  const lines = content.split('\n');
  if (one) {
    selection = {
      ...selection,
      toLine: selection.fromLine,
      toCol:
        selection.toLine > selection.fromLine
          ? lines[selection.fromLine - 1].length
          : selection.toCol,
    };
  }
  if (typeof linePrefix == 'string') {
    const linePrefixRoot = linePrefix;
    linePrefix = (_ix: number) => linePrefixRoot;
  }
  const linePrefixFn = linePrefix;
  const linesPrefix = lines.slice(0, selection.fromLine - 1).join('\n');
  const linesSuffix = lines.slice(selection.toLine).join('\n');
  const relevantLines = lines.slice(selection.fromLine - 1, selection.toLine);
  const remove = relevantLines.every((l, ix) => l.startsWith(linePrefixFn(ix)));
  const isBlank = relevantLines.join('') == '';
  const adjustedLines = relevantLines.map((l, ix) =>
    remove ? l.substring(linePrefixFn(ix).length) : linePrefixFn(ix) + l
  );
  if (isBlank) {
    adjustedLines[0] += defaultContent;
  }
  const output =
    linesPrefix +
    (selection.fromLine == 1 ? '' : '\n') +
    adjustedLines.join('\n') +
    (selection.toLine == lines.length ? '' : '\n') +
    linesSuffix;
  const toCol = isBlank ? defaultContent.length + 1 : selection.toCol;
  const fromCol = isBlank ? 1 : selection.fromCol;
  const toLine = isBlank ? selection.fromLine : selection.toLine;
  const lastLine = toLine - selection.fromLine;
  const firstLineLength = linePrefixFn(0).length;
  const lastLineLength = linePrefixFn(lastLine).length;
  selection = {
    ...selection,
    toLine: isBlank ? selection.fromLine : selection.toLine,
    toCol: remove ? toCol - firstLineLength : toCol + firstLineLength,
    fromCol: remove ? fromCol - lastLineLength : fromCol + lastLineLength,
  };
  return { output, selection };
}
