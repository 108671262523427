import { defaults, Options } from './defaults';
import { checkSanitizeDeprecation, Ctor } from './helpers';
import InlineLexer from './InlineLexer';
import Lexer from './Lexer';
import Parser from './Parser';
import Renderer, { BlockRenderer } from './Renderer';
import Slugger from './Slugger';
import TextRenderer from './TextRenderer';

export interface MarkedCallback {
  (error: any): string;
  (error: null, output: string): string;
}

export interface Marked {}

/**
 * Marked
 */
function marked(
  src: string,
  optOrCallback?: Partial<Options> | MarkedCallback,
  callback?: MarkedCallback
) {
  // throw error in case of non string input
  if (typeof src === 'undefined' || src === null) {
    throw new Error('marked(): input parameter is undefined or null');
  }
  if (typeof src !== 'string') {
    throw new Error(
      'marked(): input parameter is of type ' +
        Object.prototype.toString.call(src) +
        ', string expected'
    );
  }

  let opt: Partial<Options>;

  if (!callback && typeof optOrCallback === 'function') {
    callback = optOrCallback;
    opt = {};
  } else if (typeof optOrCallback === 'object') {
    opt = optOrCallback;
  } else {
    opt = {};
  }

  if (callback) {
    const options = {
      ...localDefaults,
      ...opt,
    };

    checkSanitizeDeprecation(options);
    const highlight = opt.highlight;
    let results;

    try {
      results = Lexer.lex(src, options);
    } catch (e) {
      return callback(e);
    }
    const { tokens, links } = results;

    let pending = tokens.length;

    const done = (err?: any) => {
      if (err) {
        opt.highlight = highlight;
        return callback!(err);
      }

      let out: string;

      try {
        out = Parser.parse(tokens, links, options);
      } catch (e) {
        err = e;
      }

      options.highlight = highlight;

      return err ? callback!(err) : callback!(null, out!);
    };

    if (!highlight || highlight.length < 3) {
      return done();
    }

    delete opt.highlight;

    if (!pending) return done();

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];

      if (token.type !== 'code') {
        return --pending || done();
      }
      highlight(token.text, token.lang, (err, code) => {
        if (err) {
          return done(err);
        }
        if (code == null || code === token.text) {
          return --pending || done();
        }
        token.text = code;
        token.escaped = true;
        return --pending || done();
      });
    }

    return;
  }

  const options = {
    ...localDefaults,
    ...opt,
  };
  checkSanitizeDeprecation(options);
  const { tokens, links } = Lexer.lex(src, options);
  const results = Parser.parse(tokens, links, options);
  return results;
}

export function markedFull<Out>(
  src: string,
  renderer: Ctor<BlockRenderer<Out>>,
  options: Partial<Options<Out>> = {}
) {
  const opts: Options<Out> = {
    ...localDefaults,
    ...options,
  };
  const { tokens, links } = Lexer.lex(src, opts);
  const parser = new Parser(renderer, opts);
  const results = parser.parse(tokens, links);
  return { results, tokens };
}

/**
 * Options
 */

let localDefaults = defaults;

export function setOptions(opt: Partial<Options>) {
  localDefaults = {
    ...localDefaults,
    ...opt,
  };
}

export interface SimpleVNodeList extends Array<SimpleVNode> {}
export type SimpleVNode = string | SimpleVElement;
export type SimpleVElement = {
  tag: string;
  slot?: string;
  attributes: {
    [attribute: string]: string;
  };
  props?: {
    [key: string]: unknown;
  };
  children: SimpleVNodeList;
};

export function extractChildren(element: HTMLElement): SimpleVNodeList {
  const result = [];
  for (const child of Array.prototype.slice.apply(element.childNodes) as Array<
    ChildNode
  >) {
    if (child.nodeType == Node.TEXT_NODE) {
      result.push((child as Text).textContent!);
    } else if (child.nodeType == Node.ELEMENT_NODE) {
      const tag = (child as HTMLElement).tagName;
      const attributes: {
        [attribute: string]: string;
      } = {};
      for (const attribute of Array.prototype.slice.apply(
        (child as HTMLElement).attributes
      ) as Array<Attr>) {
        attributes[attribute.name] = attribute.value;
      }
      result.push({
        tag,
        attributes,
        children: extractChildren(child as HTMLElement),
      });
    }
  }
  return result;
}

export function parseHTML(html: string): SimpleVNodeList {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  return extractChildren(doc.body);
}

export {
  Parser,
  defaults,
  Renderer,
  TextRenderer,
  Lexer,
  InlineLexer,
  Slugger,
  marked,
};
