var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "v-menu-caret" }, [
    _c(
      "div",
      {
        staticClass: "v-menu-caret__caret",
        class: { right: _vm.right, left: !_vm.right },
      },
      [_vm._m(0)]
    ),
    _c("div", { staticClass: "v-menu-caret__content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "v-menu-caret__caretbox" }, [
      _c("div", { staticClass: "v-menu-caret__caretunderlay" }),
      _c("div", { staticClass: "v-menu-caret__caretborder" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }