/* eslint-disable @typescript-eslint/consistent-type-imports */
import { APP_CONSTS } from '../init';
import { abp, Abp } from './abp';
class Util {
  abp: Abp = abp;
  loadScript(url: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.body.appendChild(script);
  }

  title(title: string) {
    const appname = this.abp.localization.localize(
      'AppName',
      APP_CONSTS.localization.defaultLocalizationSourceName
    );
    const page = this.abp.localization.localize(
      title,
      APP_CONSTS.localization.defaultLocalizationSourceName
    );
    window.document.title = appname + ' – ' + page;
  }

  showThisRoute(itAccess: any, currentAccess: any) {
    if (typeof itAccess === 'object' && Array.isArray(itAccess)) {
      return itAccess.includes(currentAccess);
    } else {
      return itAccess === currentAccess;
    }
  }

  getRouterObjByName(routers: Array<any>, name?: string): any {
    if (!name || !routers || !routers.length) {
      return null;
    }
    // debugger;
    let routerObj = null;
    for (const item of routers) {
      if (item.name === name) {
        return item;
      }
      routerObj = this.getRouterObjByName(item.children, name);
      if (routerObj) {
        return routerObj;
      }
    }
    return null;
  }

  toDefaultPage(
    routers: Array<any>,
    name: string | undefined,
    route: any,
    next: any
  ) {
    const len = routers.length;
    let i = 0;
    let notHandle = true;
    while (i < len) {
      if (
        routers[i].name === name &&
        routers[i].children &&
        routers[i].redirect === undefined
      ) {
        route.replace({
          name: routers[i].children[0].name,
        });
        notHandle = false;
        next();
        break;
      }
      i++;
    }
    if (notHandle) {
      next();
    }
  }
}
const util = new Util();
export default util;

const urlValidator = /^(?:(?:https?):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u{00a1}-\u{ffff}0-9]+-?)*[a-z\u{00a1}-\u{ffff}0-9]+)(?:\.(?:[a-z\u{00a1}-\u{ffff}0-9]+-?)*[a-z\u{00a1}-\u{ffff}0-9]+)*(?:\.(?:[a-z\u{00a1}-\u{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/iu;
export function testURL(url: string): boolean {
  return urlValidator.test(url);
}
