// import Renderer, { BlockRenderer } from './Renderer';
import { defaults, Options } from './defaults';
import { escape, findClosingBracket } from './helpers';
import { Fragment } from './Lexer';
import { inline, InlineGrammar } from './rules';
import { ITextRenderer } from './TextRenderer';

/**
 * Inline Lexer & Compiler
 */
export default class InlineLexer<Out> {
  options: Options<Out>;
  links: { [key: string]: { href: string; title?: string | undefined } };
  rules: InlineGrammar;
  renderer: ITextRenderer<Out>;
  inLink: boolean = false;
  inRawBlock: boolean = false;

  constructor(
    links: { [key: string]: { href: string; title?: string } },
    options: Partial<Options<Out>>,
    renderer: ITextRenderer<Out>
  ) {
    this.options = {
      ...defaults,
      ...options,
    };
    this.links = links;
    this.rules = inline.normal;

    this.renderer = renderer;
    this.renderer.setOptions(this.options);

    if (!this.links) {
      throw new Error('Tokens array requires a `links` property.');
    }

    if (this.options.type == 'pedantic') {
      this.rules = inline.pedantic;
    } else if (this.options.type == 'breaks') {
      this.rules = inline.breaks;
    } else if (this.options.type == 'gfm') {
      this.rules = inline.gfm;
    }
  }

  reset(links: { [key: string]: { href: string; title?: string } }) {
    this.inLink = false;
    this.inRawBlock = false;
    this.links = links;
  }

  /**
   * Static Lexing/Compiling Method
   */
  static output<Out>(
    src: string,
    fragments: Fragment[],
    links: { [key: string]: { href: string; title?: string } },
    options: Partial<Options<Out>>,
    renderer: ITextRenderer<Out>
  ) {
    const inline = new InlineLexer(links, options, renderer);
    return inline.output(src, fragments);
  }

  sliceFragments(
    fragments: Fragment[],
    offset: number,
    length: number,
    role?: Fragment['role'],
    styles?: string[]
  ) {
    if (length < 0) {
      offset -= length;
      length = length * -1;
    }

    for (let i = 0; i < fragments.length; i++) {
      let fragment = fragments[i];
      if (
        typeof fragment.contentOffset === 'undefined' ||
        typeof fragment.contentLength === 'undefined'
      ) {
        continue;
      }
      if (fragment.contentOffset + fragment.contentLength <= offset) {
        continue;
      }
      if (fragment.contentOffset >= offset + length) {
        break;
      }
      let lengthpreceding = offset - fragment.contentOffset;
      if (lengthpreceding < 0) {
        lengthpreceding = 0;
      }
      let lengthsucceeding =
        fragment.contentLength + fragment.contentOffset - (offset + length);
      if (lengthsucceeding < 0) {
        lengthsucceeding = 0;
      }
      if (lengthpreceding > 0) {
        fragments.splice(i, 0, {
          ...fragment,
          contentLength: lengthpreceding,
          end: {
            column: (fragment.start.column as number) + lengthpreceding,
            row: fragment.start.row,
          },
        });
        i++;
      }
      const trueLength =
        fragment.contentLength - lengthpreceding - lengthsucceeding;
      fragments.splice(i, 1, {
        ...fragment,
        role: role === undefined ? fragment.role : role,
        contentOffset: fragment.contentOffset + lengthpreceding,
        contentLength: trueLength,
        inlineStyles:
          fragment.inlineStyles && styles
            ? [...fragment.inlineStyles, ...styles]
            : styles
            ? styles
            : fragment.inlineStyles,
        start: {
          column: (fragment.start.column as number) + lengthpreceding,
          row: fragment.start.row,
        },
        end: {
          column: (fragment.end.column as number) - lengthsucceeding,
          row: fragment.start.row,
        },
      });
      if (lengthsucceeding > 0) {
        fragments.splice(i + 1, 0, {
          ...fragment,
          contentLength: lengthsucceeding,
          contentOffset: fragment.contentOffset + lengthpreceding + trueLength,
          start: {
            column: (fragment.end.column as number) - lengthsucceeding,
            row: fragment.start.row,
          },
        });
        i++;
      }
    }

    // if (process.env.NODE_ENV !== 'production') {
    //   let lastContentIndex = 0;
    //   let lastColumn : number|undefined;
    //   let lastRow : number|undefined;
    //   for (const fragment of fragments) {
    //     if (fragment.start.row != fragment.end.row) {
    //       if (typeof fragment.contentLength != 'undefined' && fragment.contentLength != 1) {
    //         console.error('fragment was bad', fragment);
    //       }
    //     } else {
    //       if (typeof fragment.contentLength != 'undefined' && fragment.contentLength != (fragment.end.column as number) - (fragment.start.column as number)) {
    //         console.error('fragment was bad', fragment);
    //       }
    //     }
    //     if (typeof lastColumn !== 'undefined' && typeof lastRow !== 'undefined') {
    //       if (fragment.start.row == fragment.start.row + 1 && fragment.start.column == 0) {
    //       } else if (fragment.start.row == lastRow && fragment.start.column == lastColumn) {
    //       } else {
    //         console.error('fragment was non contiguous', fragment);
    //       }
    //     }
    //     if (typeof fragment.contentOffset != 'undefined') {
    //       if (fragment.contentOffset != lastContentIndex) {
    //         console.error('fragment was non contiguous');
    //       }
    //       lastContentIndex = fragment.contentOffset + fragment.contentLength!;
    //     }
    //     lastColumn = fragment.end.column as any;
    //     lastRow = fragment.end.row;
    //   }
    // }
  }
  /**
   * Lexing/Compiling
   */
  output(src: string, fragments: Fragment[], outerPosition = 0) {
    let out = this.renderer.start(),
      text: Out,
      href: string,
      title: string,
      cap: RegExpExecArray | null,
      prevCapZero: string;
    const initialLength = src.length;
    const currentPosition = () => {
      return initialLength - src.length + outerPosition;
    };

    while (src) {
      // escape
      if ((cap = this.rules.escape.exec(src))) {
        src = src.substring(cap[0].length);
        this.sliceFragments(fragments, currentPosition() - 2, 2, 'escape');
        out = this.renderer.cat(out, this.renderer.ofText(cap[1]));
        continue;
      }

      // tag
      if ((cap = this.rules.tag.exec(src))) {
        if (!this.inLink && /^<a /i.test(cap[0])) {
          this.inLink = true;
        } else if (this.inLink && /^<\/a>/i.test(cap[0])) {
          this.inLink = false;
        }
        if (!this.inRawBlock && /^<(pre|code|kbd|script)(\s|>)/i.test(cap[0])) {
          this.inRawBlock = true;
        } else if (
          this.inRawBlock &&
          /^<\/(pre|code|kbd|script)(\s|>)/i.test(cap[0])
        ) {
          this.inRawBlock = false;
        }

        src = src.substring(cap[0].length);
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          'passthrough'
        );
        out = this.renderer.cat(
          out,
          this.renderer.html(
            this.options.sanitizer ? this.options.sanitizer(cap[0]) : cap[0],
            false
          )
        );
        continue;
      }

      // link
      if (
        (cap = this.rules.link.exec(src)) ||
        (cap = this.rules.imglink.exec(src))
      ) {
        const lastParenIndex = findClosingBracket(cap[2], ['(', ')']);
        if (lastParenIndex > -1) {
          const start = cap[0].indexOf('!') === 0 ? 5 : 4;
          const linkLen = start + cap[1].length + lastParenIndex;
          cap[2] = cap[2].substring(0, lastParenIndex);
          cap[0] = cap[0].substring(0, linkLen).trim();
          cap[3] = '';
        }
        src = src.substring(cap[0].length);

        this.inLink = true;
        href = cap[2];
        if (this.options.type == 'pedantic') {
          const link = /^([^'"]*[^\s])\s+(['"])(.*)\2/.exec(href);

          if (link) {
            href = link[1];
            title = link[3];
          } else {
            title = '';
          }
        } else {
          title = cap[3] ? cap[3].slice(1, -1) : '';
        }

        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          'markup',
          ['aref']
        );
        const size = cap[4];
        let width: number | undefined;
        let height: number | undefined;
        if (size) {
          const [rawWidth, rawHeight] = size.substring(1).split(/[xX]/);
          if (rawWidth) {
            width = Number(rawWidth);
          }
          if (rawHeight) {
            height = Number(rawHeight);
          }
        }
        const startIndex = currentPosition() - cap[0].length;
        out = this.renderer.cat(
          out,
          this.outputLink(cap, fragments, startIndex, {
            href: InlineLexer.escapes(href),
            title: InlineLexer.escapes(title),
            width,
            height,
          })
        );

        href = href.trim().replace(/^<([\s\S]*)>$/, '$1');
        this.inLink = false;
        continue;
      }

      // reflink, nolink
      if (
        (cap = this.rules.reflink.exec(src)) ||
        (cap = this.rules.nolink.exec(src))
      ) {
        src = src.substring(cap[0].length);
        const linkKey = (cap[2] || cap[1]).replace(/\s+/g, ' ');
        const link = this.links[linkKey.toLowerCase()];
        if (!link || !link.href) {
          src = cap[0].substring(1) + src;
          out = this.renderer.cat(out, this.renderer.ofText(cap[0].charAt(0)));
          continue;
        }
        this.inLink = true;

        const startIndex = currentPosition() - cap[0].length;

        out = this.renderer.cat(
          out,
          this.outputLink(cap, fragments, startIndex, link)
        );
        this.inLink = false;
        continue;
      }

      // strong
      if ((cap = this.rules.strong.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult = cap[4] || cap[3] || cap[2] || cap[1];
        const marklength = (cap[0].length - innerresult.length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['strong']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        const output = this.renderer.strong(
          this.output(
            innerresult,
            fragments,
            currentPosition() - innerresult.length - marklength
          )
        );

        out = this.renderer.cat(out, output);
        continue;
      }

      // em
      if ((cap = this.rules.em.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult =
          cap[6] || cap[5] || cap[4] || cap[3] || cap[2] || cap[1];
        const marklength = (cap[0].length - innerresult.length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['em']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        const output = this.renderer.em(
          this.output(
            innerresult,
            fragments,
            currentPosition() - innerresult.length - marklength
          )
        );
        out = this.renderer.cat(out, output);
        continue;
      }

      // code
      if ((cap = this.rules.code.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult = cap[2];
        const marklength = (cap[0].length - innerresult.length) / 2;
        const leadingwslength = cap[2].length - cap[2].trimLeft().length;
        const trailingwslength = cap[2].length - cap[2].trimRight().length;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['code']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength + leadingwslength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength - trailingwslength,
          marklength + trailingwslength,
          'markup'
        );
        const output = this.renderer.codespan(
          this.renderer.ofText(cap[2].trim(), true)
        );

        out = this.renderer.cat(out, output);
        continue;
      }

      // br
      if ((cap = this.rules.br.exec(src))) {
        src = src.substring(cap[0].length);
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          'markup',
          ['br']
        );
        const tag = this.renderer.br();
        out = this.renderer.cat(out, tag);
        continue;
      }

      // del (gfm)
      if ((cap = this.rules.del.exec(src))) {
        src = src.substring(cap[0].length);
        const marklength = (cap[0].length - cap[1].length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['del']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        out = this.renderer.cat(
          out,
          this.renderer.del(
            this.output(
              cap[1],
              fragments,
              currentPosition() - cap[1].length - marklength
            )
          )
        );
        continue;
      }

      // sub
      if ((cap = this.rules.sub.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult = cap[4] || cap[3] || cap[2] || cap[1];
        const marklength = (cap[0].length - innerresult.length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['sub']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        const output = this.renderer.sub(
          this.output(
            innerresult,
            fragments,
            currentPosition() - innerresult.length - marklength
          )
        );

        out = this.renderer.cat(out, output);
        continue;
      }
      // sup
      if ((cap = this.rules.sup.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult = cap[4] || cap[3] || cap[2] || cap[1];
        const marklength = (cap[0].length - innerresult.length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['sup']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        const output = this.renderer.sup(
          this.output(
            innerresult,
            fragments,
            currentPosition() - innerresult.length - marklength
          )
        );

        out = this.renderer.cat(out, output);
        continue;
      }

      // mark
      if ((cap = this.rules.marked.exec(src))) {
        src = src.substring(cap[0].length);
        const innerresult = cap[4] || cap[3] || cap[2] || cap[1];
        const marklength = (cap[0].length - innerresult.length) / 2;
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['mark']
        );
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          marklength,
          'markup'
        );
        this.sliceFragments(
          fragments,
          currentPosition() - marklength,
          marklength,
          'markup'
        );
        const output = this.renderer.marked(
          this.output(
            innerresult,
            fragments,
            currentPosition() - innerresult.length - marklength
          )
        );

        out = this.renderer.cat(out, output);
        continue;
      }

      // autolink
      if ((cap = this.rules.autolink.exec(src))) {
        src = src.substring(cap[0].length);
        if (cap[2] === '@') {
          text = this.renderer.ofText(cap[1]);
          href = 'mailto:' + text;
        } else {
          text = this.renderer.ofText(cap[1]);
          href = cap[1];
        }

        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['a']
        );
        out = this.renderer.cat(out, this.renderer.link(href, null, text));
        continue;
      }

      // url (gfm)
      if (!this.inLink && (cap = this.rules.url.exec(src))) {
        if (cap[2] === '@') {
          text = this.renderer.ofText(cap[0]);
          href = 'mailto:' + text;
        } else {
          // do extended autolink path validation
          do {
            prevCapZero = cap[0];
            cap[0] = this.rules._backpedal.exec(cap[0])![0];
          } while (prevCapZero !== cap[0]);
          text = this.renderer.ofText(cap[0]);
          if (cap[1] === 'www.') {
            href = 'http://' + text;
          } else {
            href = cap[0];
          }
        }
        src = src.substring(cap[0].length);
        this.sliceFragments(
          fragments,
          currentPosition() - cap[0].length,
          cap[0].length,
          undefined,
          ['a']
        );
        out = this.renderer.cat(out, this.renderer.link(href, null, text));
        continue;
      }

      // text
      if ((cap = this.rules.text.exec(src))) {
        src = src.substring(cap[0].length);
        if (this.inRawBlock) {
          out = this.renderer.cat(
            out,
            this.renderer.text(
              this.renderer.ofText(
                this.options.sanitizer ? this.options.sanitizer(cap[0]) : cap[0]
              )
            )
          );
        } else {
          out = this.renderer.cat(
            out,
            this.renderer.text(this.renderer.ofText(this.smartypants(cap[0])))
          );
        }
        continue;
      }

      if (src) {
        throw new Error('Infinite loop on byte: ' + src.charCodeAt(0));
      }
    }

    return out;
  }

  static escapes(text: string) {
    return text ? text.replace(inline.normal._escapes, '$1') : text;
  }

  /**
   * Compile Link
   */
  outputLink(
    cap: RegExpExecArray,
    fragments: Fragment[],
    startIndex: number,
    link: { href: string; title?: string; width?: number; height?: number }
  ) {
    const href = link.href,
      title = link.title ? escape(link.title) : null;

    this.sliceFragments(fragments, startIndex, cap[0].length, 'markup', [
      cap[0].charAt(0) === '!' ? 'img' : 'a',
    ]);

    if (cap[0].charAt(0) !== '!') {
      const capoffset = cap[0].indexOf(cap[1]);
      this.sliceFragments(
        fragments,
        startIndex + capoffset,
        cap[1].length,
        'display'
      );
    }

    return cap[0].charAt(0) !== '!'
      ? this.renderer.link(href, title, this.output(cap[1], fragments))
      : this.renderer.image(
          href,
          title,
          escape(cap[1]),
          link.width,
          link.height
        );
  }

  /**
   * Smartypants Transformations
   */
  smartypants(text: string) {
    if (!this.options.smartypants) text;

    return (
      text
        // em-dashes
        .replace(/---/g, '\u2014')
        // en-dashes
        .replace(/--/g, '\u2013')
        // opening singles
        .replace(/(^|[-\u2014/(\[{"\s])'/g, '$1\u2018')
        // closing singles & apostrophes
        .replace(/'/g, '\u2019')
        // opening doubles
        .replace(/(^|[-\u2014/(\[{\u2018\s])"/g, '$1\u201c')
        // closing doubles
        .replace(/"/g, '\u201d')
        // ellipses
        .replace(/\.{3}/g, '\u2026')
    );
  }

  /**
   * Mangle Links
   */
  mangle(text: string) {
    return text;
  }
}
