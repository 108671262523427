import type { Route } from 'vue-router';
import type { RouteConfigSingleView } from 'vue-router/types/router';
import { COLLECTIBLE_LAYOUT_IMPORTS } from '../app.vue';
import { COLLECTIBLE_MODAL_IMPORTS } from '../components/modalHost.vue';
import Article from '../views/article/article.vue';
import NotFound from '../views/article/not-found.vue';
import Home from '../views/home/home.vue';
import { registerLoadableRoutes, registerLoaderModule } from './force-load';

type LoadableComponent = typeof import('*.vue');

export interface AppRouteMeta {
  permission?: string;
  title?: string;
  icon?: string;
  layout?: string;
}
export interface AppRouteConfig extends RouteConfigSingleView {
  meta?: AppRouteMeta;
  children?: AppRouteConfig[];
}
export interface AppRoute extends Route {
  meta?: AppRouteMeta;
}

export const loginRoutes: AppRouteConfig[] = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'LogIn',
      layout: 'simple',
    },
    component: (): Promise<LoadableComponent> => import('@/views/login.vue'),
  },
];
export const otherRoutes: AppRouteConfig[] = [
  {
    path: '/toc',
    name: 'home',
    meta: { permission: '', title: 'Contents' },
    component: Home,
  },
  {
    path: '/article/:id',
    name: 'article',

    meta: { permission: '', title: 'Article' },
    component: Article,
    props: true,
  },
  {
    path: '/edit/:id',
    name: 'edit-article',

    meta: { permission: '', title: 'EditArticle' },
    component: (): Promise<LoadableComponent> =>
      import('@/views/article/edit-article.vue'),
    props: true,
  },
  {
    path: '/revisions/:id',
    name: 'article-revisions',
    meta: { permission: '', title: 'Revisions' },
    component: (): Promise<LoadableComponent> =>
      import('@/views/article/article-revisions.vue'),
    props: true,
  },
  {
    path: '/notice/:id',
    name: 'edit-notice',

    meta: { permission: '', title: 'EditNotice' },
    component: (): Promise<LoadableComponent> =>
      import('@/views/notice/edit-notice.vue'),
    props: true,
  },
  {
    path: '/',
    name: 'display-notices',

    meta: { permission: '', title: 'Home' },
    component: (): Promise<LoadableComponent> =>
      import('@/views/notice/display-notices.vue'),
  },
  {
    path: '/preview/:id',
    name: 'preview-article',

    meta: { permission: '', title: 'Preview' },
    component: (): Promise<LoadableComponent> =>
      import('@/views/article/preview-article.vue'),
    props: true,
  },
  {
    path: '/not-found/:id',
    name: 'not-found',

    meta: { permission: '', title: 'NotFound' },
    component: NotFound,
    props: true,
  },
];
/**
 * Routes available within the application.
 * Issues:
 * 1. assumes nested views, which we may not want. Requires parent components to have <router-view /> tags.
 * 2. Navigation menu doesn't expand to show nav element currently selected.
 * 3. Not filtered by permissions.
 */
export const appRoutes: AppRouteConfig[] = [
  {
    path: '/setting',
    name: 'setting',

    meta: { icon: 'mdi-cog', permission: '', title: 'ManageSystem' },

    component: (): Promise<LoadableComponent> =>
      import('@/views/setting/setting.vue'),
    children: [
      {
        path: 'user',

        meta: {
          icon: 'mdi-account',
          permission: 'Pages.Users',
          title: 'ManageUsers',
        },

        name: 'user',
        component: (): Promise<LoadableComponent> =>
          import('@/views/setting/user/user.vue'),
      },
      /*
      {
        path: "role",
        
        meta: { icon: "mdi-account-group", permission: "Pages.Roles",title: "ManageRoles" },
        
        name: "role",
        component: () => import("@/views/setting/role/role.vue")
      },
      */
      {
        path: 'toc/edit',
        component: (): Promise<LoadableComponent> =>
          import('@/views/index-configuration/index-configuration.vue'),
        name: 'edit-toc',
        meta: { icon: 'mdi-cog', permission: '', title: 'ManageIndex' },
      },
      {
        path: 'articles',
        component: (): Promise<LoadableComponent> =>
          import('@/views/article/list-articles.vue'),
        name: 'articles',
        meta: {
          icon: 'mdi-newspaper-variant-multiple',
          permission: '',
          title: 'ManageArticles',
        },
      },
      {
        path: 'notices',
        component: (): Promise<LoadableComponent> =>
          import('@/views/notice/list-notices.vue'),
        name: 'notices',
        meta: {
          icon: 'mdi-newspaper-variant-multiple',
          permission: '',
          title: 'ManageNotices',
        },
      },
      {
        path: 'attachments',
        component: (): Promise<LoadableComponent> =>
          import('@/views/attachments/list-attachments.vue'),
        name: 'attachments',
        meta: {
          icon: 'mdi-folder-multiple-image',
          permission: '',
          title: 'ManageImages',
        },
      },
    ],
  },
];

registerLoadableRoutes(...loginRoutes, ...appRoutes, ...otherRoutes);
registerLoaderModule(COLLECTIBLE_LAYOUT_IMPORTS);
registerLoaderModule(COLLECTIBLE_MODAL_IMPORTS);

export const routers = [...loginRoutes, ...appRoutes, ...otherRoutes];
