
import { Component, Prop } from 'vue-property-decorator';

import AbpBase from '../lib/abpbase';
import type { IndexSection } from '../store/modules/index-configuration';
import type { ArticleSummary, Notice } from '../store/modules/article-list';
import {
  compareSortables,
  compareArrays,
  binarySearch,
} from '../lib/insert-sorted';
import type { CreateElement, VNode } from 'vue';

import {
  VList,
  VListGroup,
  VListItemTitle,
  VListItemContent,
  VListItemIcon,
  VListItem,
  VBadge,
  VIcon,
  VImg,
  VSkeletonLoader,
} from 'vuetify/lib';

interface PartialArticleSummary extends Partial<ArticleSummary> {
  isPublic: boolean;
  hierarchyParent: string[] | null;
  hierarchyWeight: string | null;
  documentName: string;
}

interface SectionDefinitionList extends Array<SectionDefinition> {}

interface SectionDefinition {
  icon?: string;
  name: string;
  children: SectionDefinitionList;
  articles: { documentName: string; hierarchyWeight: string }[];
}

export function renderSections(
  sections: IndexSection[] | undefined,
  level: number,
  articles: ArticleSummary[],
  searchPath: string[],
  _prefix = ''
): SectionDefinitionList {
  sections = sections || [];
  const results: SectionDefinitionList = [];
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    // const tag = level == 0 ? 'H1' : level == 1 ? 'H2' : 'H3';
    // const classes = level == 1 ? 'collapsible' : '';
    // const sectionName = prefix + section.name;

    const innerPath = [...searchPath, section.name];
    const searchKey: PartialArticleSummary = {
      isPublic: true,
      hierarchyParent: innerPath,
      hierarchyWeight: '',
      documentName: '',
    };
    const search = binarySearch(
      articles,
      searchKey,
      (v: ArticleSummary) => v as PartialArticleSummary,
      compareArticlesByPosition,
      'after'
    );
    const relevantArticles: {
      documentName: string;
      hierarchyWeight: string;
    }[] = [];
    let j = search.match == 'never' ? articles.length : search.index;
    for (; j < articles.length; j++) {
      const relevantDocument = articles[j];
      if (!relevantDocument.isPublic) {
        break;
      }
      if (
        compareArrays(
          searchKey.hierarchyParent!,
          relevantDocument.hierarchyParent!
        ) != 0
      ) {
        break;
      }
      relevantArticles.push({
        documentName: relevantDocument.documentName,
        hierarchyWeight: relevantDocument.hierarchyWeight!,
      });
    }

    results.push({
      name: section.name,
      icon: section.icon || undefined,
      children: renderSections(
        section.children,
        level + 1,
        articles,
        innerPath
      ),
      articles: relevantArticles,
    });
  }
  return results;
}

function compareArticlesByPosition(
  a: PartialArticleSummary,
  b: PartialArticleSummary
) {
  let r;
  if ((r = compareSortables(a.isPublic, b.isPublic)) != 0) {
    return r;
  }
  if (a.isPublic) {
    if ((r = compareArrays(a.hierarchyParent!, b.hierarchyParent!)) != 0) {
      return r;
    }
    if ((r = compareSortables(a.hierarchyWeight!, b.hierarchyWeight!)) != 0) {
      return r;
    }
  }
  return compareSortables(a.documentName, b.documentName);
}

@Component({
  components: {
    VList,
    VListGroup,
    VListItemTitle,
    VListItemContent,
    VListItemIcon,
    VListItem,
    VBadge,
    VIcon,
    VImg,
    VSkeletonLoader,
  },
})
export default class HomePageIndex extends AbpBase {
  @Prop(String)
  section!: 'ALS' | 'BLS';

  get content(): SectionDefinitionList {
    return [
      {
        name: 'ALS',
        articles: [],
        icon: 'mdi-needle',
        children: renderSections(
          this.$store.state.articleList.indexConfiguration.alsSections,
          1,
          this.sortedArticles.filter(v => v.protocolSection !== 'BLS'),
          []
        ),
      },
      {
        name: 'BLS',
        articles: [],
        icon: 'mdi-stethoscope',
        children: renderSections(
          this.$store.state.articleList.indexConfiguration.blsSections,
          1,
          this.sortedArticles.filter(v => v.protocolSection === 'BLS'),
          []
        ),
      },
    ];
  }

  get sortedArticles(): ArticleSummary[] {
    const tempList: ArticleSummary[] = [];
    for (let i = 0; i < this.$store.state.articleList.documents.length; i++) {
      tempList.push(this.$store.state.articleList.documents[i]);
    }
    tempList.sort(compareArticlesByPosition);
    return tempList;
  }

  created() {
    this.$store.dispatch('articleList/initDatastore');
  }

  render(h: CreateElement) {
    var _h = this.$createElement;
    const _c = _h;
    return _c(
      'div',
      {
        staticClass: 'small-index-host',
      },
      [
        _c('v-list', { attrs: { nav: '', dense: '', role: 'navigation' } }, [
          _c(
            'v-list-item',
            {
              attrs: {
                to: {
                  name: 'display-notices',
                },
              },
            },
            [
              h('v-icon', ['mdi-alert']),
              h(
                'v-list-item-title',
                {
                  attrs: {},
                  staticClass: 'notice-zone',
                },
                [
                  this.notices > 0
                    ? h(
                        'v-badge',
                        {
                          attrs: {
                            content: this.notices > 0 ? this.notices : '',
                            color: 'warning',
                            inline: true,
                          },
                        },
                        [this.L('Home')]
                      )
                    : this.L('Home'),
                ]
              ),
            ]
          ),
          ...this.renderSection(this.content),
          ...this.renderAdminLink(),
        ]),
      ]
    );
  }

  renderAdminLink() {
    if (this.$store.state.app.fromApp) {
      return [];
    }
    const h = this.$createElement;
    return [
      h(
        'v-list-item',
        {
          on: {
            click: () => {
              this.$emit('togglelayout');
            },
          },
        },
        [
          h('v-icon', ['mdi-cog']),
          h(
            'v-list-item-title',
            {
              staticClass: 'notice-zone',
            },
            [this.L('SwitchToAdminView')]
          ),
        ]
      ),
    ];
  }

  renderSection(content: SectionDefinitionList) {
    if (!content) {
      return [];
    }
    const h = this.$createElement;
    const results: VNode[] = [];
    for (let i = 0; i < content.length; i++) {
      const section = content[i];
      const children = this.renderSection(section.children);
      for (let j = 0; j < section.articles.length; j++) {
        const article = section.articles[j];
        children.push(
          h(
            'v-list-item',
            {
              key: article.documentName,
              attrs: {
                to: {
                  name: 'article',
                  params: {
                    id: article.documentName,
                  },
                },
              },
            },
            [
              h('v-list-item-content', [
                h('v-list-item-title', [article.documentName]),
              ]),
            ]
          )
        );
      }
      results.push(
        h(
          'v-list-group',
          {
            key: section.name,
            attrs: section.icon
              ? {
                  'prepend-icon': section.icon,
                  'sub-group': true,
                }
              : {
                  'sub-group': true,
                },
          },
          [
            h(
              'v-list-item-content',
              {
                slot: 'activator',
              },
              [h('v-list-item-title', [section.name])]
            ),
            ...children,
          ]
        )
      );
    }
    return results;
  }

  get notices() {
    const visibleNotices: Notice[] = [];
    const currentDate = this.$store.state.articleList.currentDate;
    const currentNotices =
      this.$store.state.articleList.noticesByDailyBucket[currentDate] || {};
    for (let i = 0; i < this.$store.state.articleList.notices.length; i++) {
      if (currentNotices[this.$store.state.articleList.notices[i].id]) {
        visibleNotices.push({
          ...this.$store.state.articleList.notices[i],
        });
      }
    }
    visibleNotices.sort(
      (a, b) =>
        compareSortables(
          a.fromDate == null || a.fromDate > a.modificationTime
            ? a.modificationTime
            : a.fromDate,
          b.fromDate == null || b.fromDate > b.modificationTime
            ? b.modificationTime
            : b.fromDate
        ) * -1
    );
    return visibleNotices.length;
  }
}
