
import { Component, Vue, Watch } from 'vue-property-decorator';
import { VApp } from 'vuetify/lib';
import { abp } from './lib/abp';

export const COLLECTIBLE_LAYOUT_IMPORTS: {
  [key: string]: () => Promise<any>;
} = {
  'web-app-layout': () => import('@/layouts/web/appLayout.vue'),
};

@Component({
  components: {
    ...COLLECTIBLE_LAYOUT_IMPORTS,
    VApp,
  },
})
export default class App extends Vue {
  get defaultLayout() {
    return this.$store.state.app.currentLayout;
  }

  get layout(): string {
    return `${this.$route.meta?.layout || this.defaultLayout}-layout`;
  }

  mounted() {
    window.addEventListener('keydown', ev => {
      if (ev.key == '`') {
        this.toggleLayout();
      }
    });
    this.$store.dispatch({
      type: 'app/init',
    });
    if (this.fromApp) {
      this.onPlayStoreChanged(true);
    }
  }

  toggleLayout() {
    this.$store.dispatch({
      type: 'app/toggleLayout',
    });
  }

  @Watch('defaultLayout')
  onLayoutChanged(layout: string) {
    abp.utils.setCookieValue('layout', layout);
    const root = document.firstElementChild;
    if (layout == 'web-app') {
      root!.classList.remove('mobile');
    } else {
      root!.classList.add('mobile');
    }
  }

  get fromApp() {
    return (this.$route.query['appsession'] as string) == '1';
  }

  @Watch('fromApp')
  onPlayStoreChanged(fromApp: boolean) {
    if (fromApp) {
      this.$store.dispatch({
        type: 'app/setFromApp',
      });
    }
  }
}
