import { BlockRenderer } from './Renderer';

export interface HighlightFunction<Out> {
  (code: string, lang: string): Out;
  (
    code: string,
    lang: string | undefined,
    cb: (err: any, result: Out) => void
  ): void;
}

export interface Options<Out = string> {
  type: 'gfm' | 'pedantic' | 'normal' | 'breaks';
  baseUrl: string | null;
  headerIds: boolean;
  headerPrefix: string;
  highlight?: HighlightFunction<Out>;
  langPrefix: string;
  mangle: boolean;
  sanitizer?: (input: string) => string;
  silent: boolean;
  smartLists: boolean;
  smartypants: boolean;
  xhtml: boolean;
  renderer?: BlockRenderer<Out>;
}

export function getDefaults(): Options<any> {
  return {
    type: 'gfm',
    baseUrl: null,
    headerIds: true,
    headerPrefix: '',
    langPrefix: 'language-',
    mangle: true,
    silent: false,
    smartLists: true,
    smartypants: true,
    xhtml: false,
  };
}

export const defaults = getDefaults();
