var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "contentbox" }, [
    _c("div", { staticClass: "scrollbox" }, [
      _c(
        "div",
        { staticClass: "contentcontainer" },
        [
          _c("raw-content", {
            staticClass: "markdown-viewer-content",
            class: { "full-bleed": !_vm.hasTabs },
            attrs: { content: _vm.content, docid: _vm.docid },
            on: { viewstatechanged: _vm.onViewStateChanged },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }