
import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import { mapState } from 'vuex';
import AppSystemBar from '../../components/mobileSystemBar.vue';
import NotificationHost from '../../components/notificationHost.vue';
import ModalHost from '../../components/modalHost.vue';
import SearchBar from '../../components/searchBar.vue';
import { VContent, VContainer, VProgressCircular } from 'vuetify/lib';
import { Touch } from 'vuetify/lib/directives';
import { abp } from '../../lib/abp';

@Component({
  components: {
    'app-system-bar': AppSystemBar,
    'notification-host': NotificationHost,
    'modal-host': ModalHost,
    'search-bar': SearchBar,
    VContent,
    VContainer,
    VProgressCircular,
  },
  directives: {
    Touch,
  },
  computed: {
    ...mapState('app', ['footerComponent']),
  },
})
export default class AppLayout extends Vue {
  get zoomfactor() {
    return this.zoom / 25;
  }

  _onResizedBound!: () => void;
  firstUpdate = false;
  mobile = true;

  //components = {"app-side-nav": AppSideNav}
  hideFooter() {
    this.$store.commit('app/hideFooterComponent');
  }

  _onResized() {
    const host = this.$refs['layout'] as HTMLDivElement;
    if (!host) {
      return;
    }
    this.firstUpdate = true;
    const rect = host.getBoundingClientRect();
    if (rect.width == 0) {
      window.setTimeout(() => {
        this._onResized();
      }, 500);
    } else {
      this.mobile = rect.width < 768;
    }
  }

  _changeLayout() {}

  mounted() {
    const rawZoom = abp.utils.getCookieValue('zoom');
    const rawShowall = abp.utils.getCookieValue('showall');
    this.zoom = rawZoom ? Number(rawZoom) : 50;
    this.showall = rawShowall == 'true';
    if (Number.isNaN(this.zoom)) {
      this.zoom = 50;
    }
    this._onResizedBound = this._onResized.bind(this);
    window.addEventListener('resize', this._onResizedBound, { passive: true });
    if (this.firstUpdate) {
      return;
    }
    window.setTimeout(() => {
      this._onResized();
    }, 500);
  }

  unmounted() {
    window.removeEventListener('resize', this._onResizedBound);
  }

  get documentsAreReady(): boolean {
    const state = this.$store.state.articleList;
    return state.completedInitialLoad;
  }

  updated() {}

  zoom = 50;
  showall = false;

  zoomChanged(e: number) {
    this.zoom = e;
  }

  @Watch('zoom')
  afterZoomChanged(zoom: number) {
    abp.utils.setCookieValue('zoom', `${zoom}`);
  }

  @Watch('showall')
  afterShowallChanged(showall: boolean) {
    abp.utils.setCookieValue('showall', `${showall}`);
    this.$store.commit({
      type: 'app/setShowAll',
      payload: showall,
    });
  }

  @Emit('togglelayout')
  toggleLayout() {}
}
