import Vue from 'vue';
import type { Route } from 'vue-router';
import type { ActionContext, Module } from 'vuex';
import Vuex from 'vuex';
import type { ActionPayload } from '..';
import { APP_CONSTS } from '../../init';
import { abp } from '../../lib/abp';
import ajax from '../../lib/ajax';
import Util from '../../lib/util';
import type { AppRouteConfig } from '../../router/routes';
import { appRoutes, otherRoutes } from '../../router/routes';
Vue.use(Vuex);

export interface Notification {
  message: string | string[];
  details?: string | string[];
  severity: 'info' | 'error' | 'warning';
  duration: number | 'infinite';
  tag?: string;
  index?: number;
  timeout?: string;
}

export interface DocViewState {
  id: string;
  tab?: string;
  expanders?: string[];
  expandall?: boolean;
}

export interface AppState {
  footerComponent: string | null;
  pageOpenedList: Partial<Route>[];
  currentPageName: string;
  menuList: AppRouteConfig[];
  drawer: boolean;
  notifications: Notification[];
  notificationIndex: number;
  modal:
    | 'link'
    | 'image'
    | 'article-info'
    | 'notice-info'
    | 'self-settings'
    | 'accept-tos'
    | null;
  loginTwoFactor: boolean;
  currentLayout: string;
  fromApp: boolean;
  showAll: boolean;
  docViewStates: {
    [statekey: string]: DocViewState;
  };
}

let notificationTimeout: number | undefined;
let currentTimeout: string | undefined;
const DEFAULT_LAYOUT = 'app';

function processExpirations(context: ActionContext<AppState, any>) {
  return (notifications: Notification[]) => {
    let earliest: string | undefined;
    for (const notification of notifications) {
      if (
        typeof notification.timeout != 'undefined' &&
        (typeof earliest === 'undefined' || earliest > notification.timeout)
      ) {
        earliest = notification.timeout;
      }
    }
    if (
      typeof earliest === 'undefined' ||
      typeof currentTimeout === 'undefined' ||
      earliest < currentTimeout
    ) {
      if (typeof notificationTimeout != 'undefined') {
        window.clearTimeout(notificationTimeout);
        notificationTimeout = undefined;
      }
      currentTimeout = earliest;
    }
    if (typeof currentTimeout !== 'undefined') {
      const nextTimeout = new Date(currentTimeout);
      const currentTime = new Date();
      let duration = nextTimeout.getTime() - currentTime.getTime();
      if (duration < 0) {
        duration = 0;
      }
      notificationTimeout = window.setTimeout(() => {
        notificationTimeout = undefined;
        currentTimeout = undefined;
        const currentTime = new Date().toISOString();
        context.commit({
          type: 'removeExpiredNotifications',
          payload: currentTime,
        });
        processExpirations(context)(context.state.notifications);
      }, duration);
    }
  };
}

class AppModule implements Module<AppState, any> {
  namespaced = true;
  state: AppState = {
    footerComponent: null,
    pageOpenedList: [
      {
        meta: { title: 'HomePage' },
        path: '',
        name: 'home',
      },
    ],
    currentPageName: '',
    menuList: [],
    drawer: false,
    notifications: [],
    notificationIndex: 0,
    modal: null,
    loginTwoFactor: false,
    currentLayout: DEFAULT_LAYOUT,
    fromApp: false,
    showAll: false,
    docViewStates: {},
  };

  mutations = {
    openModal(
      state: AppState,
      arg: ActionPayload<
        | 'link'
        | 'image'
        | 'article-info'
        | 'notice-info'
        | 'self-settings'
        | 'accept-tos'
        | null
      >
    ) {
      state.modal = arg.payload;
    },
    closeModal(state: AppState) {
      state.modal = null;
    },
    setShowAll(state: AppState, payload: ActionPayload<boolean>) {
      state.showAll = payload.payload;
    },
    setViewState(
      state: AppState,
      payload: ActionPayload<{ key: string; docViewState: DocViewState }>
    ) {
      state.docViewStates[payload.payload.key] = payload.payload.docViewState;
    },
    logout() {},
    setOpenedList(state: AppState) {
      state.pageOpenedList = localStorage.pageOpenedList
        ? JSON.parse(localStorage.pageOpenedList)
        : [(otherRoutes[0].children || [])[0]];
    },
    hideFooterComponent(state: AppState) {
      state.footerComponent = null;
    },
    toggleDrawer(state: AppState) {
      state.drawer = !state.drawer;
    },
    updateMenuList(state: AppState, arg: ActionPayload<AppRouteConfig[]>) {
      state.menuList = arg.payload;
    },
    insertNotification(state: AppState, arg: ActionPayload<Notification>) {
      state.notifications = [
        ...state.notifications.filter(
          n => !arg.payload.tag || n.tag != arg.payload.tag
        ),
        { ...arg.payload, index: state.notificationIndex },
      ];
      state.notificationIndex += 1;
    },
    removeNotification(state: AppState, arg: ActionPayload<number>) {
      state.notifications = state.notifications.filter(
        f => f.index != arg.payload
      );
    },
    removeExpiredNotifications(state: AppState, arg: ActionPayload<string>) {
      state.notifications = state.notifications.filter(
        f => typeof f.timeout === 'undefined' || f.timeout > arg.payload
      );
    },
    setTwoFactorSetupState(state: AppState, arg: ActionPayload<boolean>) {
      state.loginTwoFactor = arg.payload;
    },
    setLayout(state: AppState, arg: ActionPayload<string>) {
      state.currentLayout = arg.payload;
    },
    setFromApp(state: AppState) {
      state.fromApp = true;
    },
  };

  actions = {
    setFromApp(context: ActionContext<AppState, any>) {
      abp.utils.setCookieValue('fromapp', '1', undefined, abp.appPath);
      context.commit({
        type: 'setFromApp',
      });
    },
    toggleLayout(context: ActionContext<AppState, any>) {
      const newLayout =
        context.state.currentLayout == 'app' ? 'web-app' : 'app';
      abp.utils.setCookieValue('layout', newLayout, undefined, abp.appPath);

      context.commit({
        type: 'setLayout',
        payload: newLayout,
      });
    },
    init(context: ActionContext<AppState, any>) {
      const acceptedtos = abp.utils.getCookieValue('acceptedtos');
      const layout = abp.utils.getCookieValue('layout') || DEFAULT_LAYOUT;
      const fromApp = abp.utils.getCookieValue('fromapp');
      if (fromApp) {
        context.commit({
          type: 'setFromApp',
        });
      }
      context.commit({
        type: 'setLayout',
        payload: layout,
      });

      if (!acceptedtos) {
        context.commit({
          type: 'openModal',
          payload: 'accept-tos',
        });
      }
    },
    acceptTOS(context: ActionContext<AppState, any>) {
      abp.utils.setCookieValue(
        'acceptedtos',
        '1',
        new Date('2999-01-01T00:00:00Z'),
        abp.appPath
      );
      context.commit({
        type: 'openModal',
        payload: null,
      });
    },
    updateMenuList(context: ActionContext<AppState, any>) {
      const menuList = appRoutes
        .map(route => {
          return {
            ...route,
            children: (route.children || []).filter(item =>
              item.meta && item.meta.permission
                ? Util.abp.auth.isGranted(item.meta.permission)
                : true
            ),
          };
        })
        .filter(route => route.children && route.children.length);
      context.commit({
        type: 'updateMenuList',
        payload: menuList,
      });
    },

    loginClearTwoFactor(context: ActionContext<AppState, any>) {
      if (!context.state.loginTwoFactor) {
        return;
      }
      abp.auth.clearToken();
      abp.utils.deleteCookie(
        APP_CONSTS.authorization.encrptedAuthTokenName,
        abp.appPath
      );
      context.commit({
        type: 'setTwoFactorSetupState',
        payload: false,
      });
    },
    async completeTwoFactorChallenge(
      context: ActionContext<AppState, any>,
      arg: ActionPayload<{
        challengeMethod: string;
        code: string;
        rememberMe: boolean;
      }>
    ): Promise<boolean> {
      if (!abp.auth.getToken()) {
        context.dispatch('loginClearTwoFactor');
        return false;
      }
      const resp = await ajax.post(
        '/api/TokenAuth/CompleteTwoFactorChallenge',
        arg.payload
      );
      if (resp.status == 200) {
        const tokenExpireDate = arg.payload.rememberMe
          ? new Date(
              new Date().getTime() + 1000 * resp.data.result.expireInSeconds
            )
          : undefined;
        abp.auth.setToken(resp.data.result.accessToken, tokenExpireDate);
        abp.utils.setCookieValue(
          APP_CONSTS.authorization.encrptedAuthTokenName,
          resp.data.result.encryptedAccessToken,
          tokenExpireDate,
          abp.appPath
        );
        context.dispatch(
          {
            type: 'session/init',
            payload: true,
          },
          { root: true }
        );
        return true;
      } else {
        return false;
      }
    },
    async loginViaForgotPasswordCode(
      context: ActionContext<AppState, any>,
      payload: ActionPayload<{ code: string; emailAddress: string }>
    ) {
      const rep = await ajax.post(
        '/api/TokenAuth/AuthenticateViaCode',
        payload.payload
      );

      if (rep.status == 200) {
        abp.auth.setToken(rep.data.result.accessToken);
        abp.utils.setCookieValue(
          APP_CONSTS.authorization.encrptedAuthTokenName,
          rep.data.result.encryptedAccessToken,
          undefined,
          abp.appPath
        );
        abp.utils.setCookieValue(
          'passwordauthenticated',
          'false',
          undefined,
          abp.appPath
        );
        if (rep.data.result.requiresTwoFactor) {
          context.commit({
            type: 'setTwoFactorSetupState',
            payload: true,
          });
          return false;
        }

        context.dispatch(
          {
            type: 'session/init',
            payload: true,
          },
          { root: true }
        );
        return true;
      } else {
        return false;
      }
    },
    async login(
      content: ActionContext<AppState, any>,
      payload: any
    ): Promise<boolean> {
      const rep = await ajax.post('/api/TokenAuth/Authenticate', payload.data);

      if (rep.status == 200) {
        const tokenExpireDate = payload.data.rememberMe
          ? new Date(
              new Date().getTime() + 1000 * rep.data.result.expireInSeconds
            )
          : undefined;
        abp.auth.setToken(rep.data.result.accessToken, tokenExpireDate);
        abp.utils.setCookieValue(
          APP_CONSTS.authorization.encrptedAuthTokenName,
          rep.data.result.encryptedAccessToken,
          tokenExpireDate,
          abp.appPath
        );
        abp.utils.setCookieValue(
          'passwordauthenticated',
          'true',
          undefined,
          abp.appPath
        );
        if (rep.data.result.requiresTwoFactor) {
          content.commit({
            type: 'setTwoFactorSetupState',
            payload: true,
          });
          return false;
        }

        content.dispatch(
          {
            type: 'session/init',
            payload: true,
          },
          { root: true }
        );
        return true;
      } else {
        return false;
      }
    },
    async forgotPassword(
      _context: ActionContext<AppState, any>,
      arg: ActionPayload<{ emailAddress: string }>
    ) {
      const rep = await ajax.post(
        '/api/services/app/User/ForgotPassword',
        arg.payload
      );

      if (rep.status == 200) {
        return true;
      } else {
        return false;
      }
    },
    logout(context: ActionContext<AppState, any>) {
      abp.auth.clearToken();
      abp.utils.deleteCookie(
        APP_CONSTS.authorization.encrptedAuthTokenName,
        abp.appPath
      );
      localStorage.clear();
      sessionStorage.clear();
      context.dispatch({ type: 'session/clear' }, { root: true });
    },
    notify(
      context: ActionContext<AppState, any>,
      arg: ActionPayload<Notification>
    ) {
      let notification = arg.payload;
      if (typeof notification.duration == 'number') {
        const newTimeout = new Date();
        newTimeout.setMilliseconds(
          newTimeout.getMilliseconds() + notification.duration
        );
        const timestamp = newTimeout.toISOString();
        notification = {
          ...notification,
          timeout: timestamp,
        };
      }
      context.commit({
        type: 'insertNotification',
        payload: notification,
      });
      processExpirations(context)(context.state.notifications);
    },
    removeNotification(
      context: ActionContext<AppState, any>,
      arg: ActionPayload<Notification>
    ) {
      context.commit({
        type: 'removeNotification',
        payload: arg.payload,
      });
      processExpirations(context)(context.state.notifications);
    },
  };
}
const appModule = new AppModule();
export default appModule;
