import { ActionContext, Module } from 'vuex';
import { ActionPayload, RootState } from '..';
import ajax from '../../lib/ajax';
import insertIntoSorted, { binarySearch } from '../../lib/insert-sorted';
import util from '../../lib/util';
import { ArticleSummary } from './article-list';

interface RevisionHeader {
  documentName: string;
  published: boolean;
  revisionCount: number;
  modificationTime: string | null;
  modificationUserName: string | null;
}

export interface ArticleRevisionState {
  documentName: string;
  documentHeadersLoading: boolean;
  leftRevisionLoading: boolean;
  leftRevisionPicked: number;
  rightRevisionLoading: boolean;
  rightRevisionPicked: number;
  revisionHeaders: RevisionHeader[];
  loadedRevisions: ArticleSummary[];
  errorState: string | null;
}

class ArticleRevisionStore implements Module<ArticleRevisionState, any> {
  namespaced = true;
  state: ArticleRevisionState = {
    documentName: '',
    documentHeadersLoading: false,
    leftRevisionLoading: false,
    leftRevisionPicked: 0,
    rightRevisionLoading: false,
    rightRevisionPicked: 0,
    revisionHeaders: [],
    loadedRevisions: [],
    errorState: null,
  };
  actions = {
    async onRevisionUnderflow(
      context: ActionContext<ArticleRevisionState, RootState>,
      arg: ActionPayload<{
        documentName: string;
        fromRevision: number;
        uptoRevision: number;
        force?: boolean;
      }>
    ) {
      if (
        arg.payload.fromRevision >= arg.payload.uptoRevision &&
        arg.payload.force
      ) {
        // on the first run we need to load something, just so we have a payload.
        // This is effectively a no-op.
        const items = context.state.revisionHeaders;
        context.commit({
          type: 'finishLoadHeaders',
          payload: items,
        });
      } else if (arg.payload.fromRevision >= arg.payload.uptoRevision) {
        return; // nothing to do...
      }
      const getUrl = `/api/services/app/Document/GetRevisions?DocumentName=${encodeURIComponent(
        arg.payload.documentName
      )}&Version=1&MinRevisionCount=${
        arg.payload.fromRevision
      }&UptoRevisionCount=${arg.payload.uptoRevision}`;
      try {
        const resp = await ajax.get(getUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });
        const { items } = (resp?.data?.result || { items: [] }) as {
          items: RevisionHeader[];
        };
        context.commit({
          type: 'finishLoadHeaders',
          payload: items,
        });
      } catch {
        context.commit({
          type: 'setError',
          payload: 'ErrorReceivingRevisionData',
        });
      }
    },
    async pickDocument(
      context: ActionContext<ArticleRevisionState, RootState>,
      arg: ActionPayload<string>
    ) {
      if (arg.payload == context.state.documentName) {
        return;
      }
      const { payload } = arg;
      const id = payload;
      const docIndex = context.rootState.articleList.draftDocumentIndices[id];
      if (typeof docIndex === 'undefined') {
        context.commit('clearState');
      }
      const document = context.rootState.articleList.draftDocuments[docIndex];
      context.commit({
        type: 'startLoadDocument',
        payload: document,
      });
      context.dispatch({
        type: 'onRevisionUnderflow',
        payload: {
          documentName: arg.payload,
          fromRevision: 1,
          uptoRevision: document.revisionCount,
          force: true,
        },
      });
      let leftDocumentRevision =
        document.revisionCount == 1 ? 1 : document.revisionCount - 1;
      context.dispatch({
        type: 'pickRevision',
        payload: {
          documentName: document.documentName,
          number: leftDocumentRevision,
          hand: 'left',
        },
      });
    },
    updateFromRemote(
      context: ActionContext<ArticleRevisionState, RootState>,
      args: ActionPayload<ArticleSummary & PouchDB.Core.AllDocsMeta>
    ) {
      if (context.state.documentName != args.payload.documentName) {
        return;
      }
      context.commit({
        type: 'startLoadDocument',
        payload: args.payload,
      });
      context.dispatch({
        type: 'onRevisionUnderflow',
        payload: {
          documentName: args.payload.documentName,
          fromRevision: context.state.loadedRevisions.length + 1,
          uptoRevision: args.payload.revisionCount,
        },
      });
    },
    async pickRevision(
      context: ActionContext<ArticleRevisionState, RootState>,
      args: ActionPayload<{
        documentName: string;
        number: number;
        hand: 'left' | 'right';
      }>
    ) {
      context.commit({
        type: 'startPickRevision',
        payload: args.payload,
      });
      if (args.payload.hand == 'right') {
        let maximumLeftRevision =
          args.payload.number == 1 ? 1 : args.payload.number - 1;
        if (context.state.leftRevisionPicked > maximumLeftRevision) {
          context.dispatch({
            type: 'pickRevision',
            payload: {
              ...args.payload,
              number: maximumLeftRevision,
              hand: 'left',
            },
          });
        }
      } else {
        let minimumRightRevision =
          args.payload.number >= context.state.revisionHeaders.length
            ? args.payload.number
            : args.payload.number + 1;
        if (context.state.rightRevisionPicked < minimumRightRevision) {
          context.dispatch({
            type: 'pickRevision',
            payload: {
              ...args.payload,
              number: minimumRightRevision,
              hand: 'right',
            },
          });
        }
      }
      const { match } = binarySearch(
        context.state.loadedRevisions,
        args.payload.number,
        'revisionCount',
        'after'
      );
      if (match == 'exact') {
        return; // nothing to load;
      }
      const getUrl = `/api/services/app/Document/GetRevisions?DocumentName=${encodeURIComponent(
        args.payload.documentName
      )}&Version=1&MinRevisionCount=${args.payload.number}&UptoRevisionCount=${
        args.payload.number + 1
      }&IncludeBody=2`;
      try {
        const resp = await ajax.get(getUrl, {
          headers: {
            'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
          },
        });
        const { items } = (resp?.data?.result || { items: [] }) as {
          items: ArticleSummary[];
        };
        context.commit({
          type: 'finishLoadingRevisionBody',
          payload: items[0],
        });
      } catch {
        context.commit({
          type: 'setError',
          payload: 'ErrorReceivingRevisionData',
        });
      }
    },
  };
  mutations = {
    clearState(state: ArticleRevisionState) {
      state.documentName = '';
      state.documentHeadersLoading = false;
      state.leftRevisionLoading = false;
      state.leftRevisionPicked = 0;
      state.rightRevisionLoading = false;
      state.rightRevisionPicked = 0;
      state.revisionHeaders = [];
      state.loadedRevisions = [];
      state.errorState = null;
    },
    startLoadDocument(
      state: ArticleRevisionState,
      arg: ActionPayload<ArticleSummary>
    ) {
      state.documentName = arg.payload.documentName;
      state.documentHeadersLoading = true;
      state.leftRevisionLoading = true;
      state.rightRevisionLoading = false;
      state.leftRevisionPicked = arg.payload.revisionCount - 0;
      state.rightRevisionPicked = arg.payload.revisionCount - 0;
      const revisionHeaders: RevisionHeader[] = [];
      for (let i = 1; i < arg.payload.revisionCount; i++) {
        revisionHeaders.push({
          ...arg.payload,
          revisionCount: i,
          modificationTime: null,
          modificationUserName: null,
        });
      }
      revisionHeaders.push(arg.payload);
      state.revisionHeaders = revisionHeaders;
      state.loadedRevisions = [arg.payload];
      state.errorState = null;
    },
    startUpdateFromRemote(
      state: ArticleRevisionState,
      args: ActionPayload<ArticleSummary & PouchDB.Core.AllDocsMeta>
    ) {
      if (args.payload.documentName != state.documentName) {
        return;
      }
      const revisionHeaders = [...state.revisionHeaders];
      while (revisionHeaders.length < args.payload.revisionCount - 1) {
        revisionHeaders.push({
          ...args.payload,
          revisionCount: revisionHeaders.length,
          modificationTime: null,
          modificationUserName: null,
        });
      }
      revisionHeaders.push(args.payload);
      state.loadedRevisions.push(args.payload);
    },
    finishLoadHeaders(
      state: ArticleRevisionState,
      arg: ActionPayload<RevisionHeader[]>
    ) {
      if (arg.payload.length == 0) {
        return;
      }
      const firstDocument = arg.payload[0];
      if (firstDocument.documentName != state.documentName) {
        return;
      }
      const startPos = firstDocument.revisionCount - 1;
      state.revisionHeaders.splice(
        startPos,
        arg.payload.length,
        ...arg.payload
      );
      state.documentHeadersLoading = false;
    },
    startPickRevision(
      state: ArticleRevisionState,
      args: ActionPayload<{
        documentName: string;
        number: number;
        hand: 'left' | 'right';
      }>
    ) {
      if (args.payload.documentName != state.documentName) {
        return;
      }
      const { match } = binarySearch(
        state.loadedRevisions,
        args.payload.number,
        'revisionCount',
        'after'
      );
      if (args.payload.hand == 'left') {
        if (state.leftRevisionPicked != args.payload.number) {
          state.leftRevisionPicked = args.payload.number;
          state.leftRevisionLoading = match != 'exact';
        }
      } else {
        if (state.rightRevisionPicked != args.payload.number) {
          state.rightRevisionPicked = args.payload.number;
          state.rightRevisionLoading = match != 'exact';
        }
      }
    },
    finishLoadingRevisionBody(
      state: ArticleRevisionState,
      args: ActionPayload<ArticleSummary>
    ) {
      if (args.payload.documentName != state.documentName) {
        return;
      }
      insertIntoSorted(state.loadedRevisions, args.payload, 'revisionCount');
      if (args.payload.revisionCount == state.leftRevisionPicked) {
        state.leftRevisionLoading = false;
      }
      if (args.payload.revisionCount == state.rightRevisionPicked) {
        state.rightRevisionLoading = false;
      }
    },
    setError(state: ArticleRevisionState, arg: ActionPayload<string>) {
      state.errorState = arg.payload;
    },
  };
}

const articleRevision = new ArticleRevisionStore();
export default articleRevision;
