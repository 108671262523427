// src/plugins/vuetify.js

import Vue from 'vue';
import type { UserVuetifyPreset } from 'vuetify';
import Vuetify, {
  VImg,
  VTab,
  VTabItem,
  VTabsItems,
  VTabTransition,
} from 'vuetify/lib'; // should be vuetify/lib. This issue breaks it https://github.com/vuetifyjs/vue-cli-plugin-vuetify/issues/43
import { Ripple } from 'vuetify/lib/directives';
import Framework from './framework';

Vue.use(Vuetify, {
  components: {
    VTab,
    VTabItem,
    VTabsItems,
    VTabTransition,
    VImg,
  },
  directives: { Ripple },
});
Vue.use(Framework);

const opts: Partial<UserVuetifyPreset> = {
  icons: {
    iconfont: 'mdi',
    values: {},
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#359cb6',
        },
        secondary: {
          base: '#5854a3',
        },
      },
    },
  },
};

export default new Vuetify(opts);
