import { Module } from 'vuex';
import { ActionPayload } from '..';

export interface ImageViewState {
  modalOpen: boolean;
  currentImage: string;
}

class ImageViewModule implements Module<ImageViewState, any> {
  namespaced = true;
  state: ImageViewState = {
    modalOpen: false,
    currentImage: '',
  };
  mutations = {
    openModal(state: ImageViewState, action: ActionPayload<string>) {
      state.modalOpen = true;
      state.currentImage = action.payload;
    },
    closeModal(state: ImageViewState) {
      state.modalOpen = false;
      state.currentImage = '';
    },
  };
}

const imageView = new ImageViewModule();
export default imageView;
