import { Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { APP_CONSTS } from '../init';
import { RootState } from '../store';
import { abp } from './abp';
export interface TypedStore<S> extends Vue {
  $store: Store<S>;
}

export default class AbpBase extends Vue implements TypedStore<RootState> {
  shortTime(value: string): string {
    const m = new Date(value);
    if (Number.isNaN(m.valueOf()) || m.valueOf() < 0) {
      return '';
    }
    return new Intl.DateTimeFormat([], {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })
      .formatToParts(m)
      .map(v => v.value)
      .join('');
  }

  moment(value: string): string {
    const sourceDate = Date.parse(value);
    if (Number.isNaN(sourceDate) || sourceDate < 0) {
      return '';
    }
    const duration = Date.now() - sourceDate;
    const suffix = duration < 0 ? 'from now' : 'ago';
    const normalizedDuration = duration < 0 ? duration * -1 : duration;
    const secondsDuration = 1_000;
    const secondsCutoff = 45_000;
    const minuteDuration = 60_000;
    const minutesCutoff = 45 * 60_000;
    const hoursDuration = 60 * 60_000;
    const hoursCutoff = 18 * 60 * 60_000;
    const daysDuration = 24 * 60 * 60_000;
    const daysCutoff = 25 * 24 * 60 * 60_000;
    const monthDuration = 30.4 * 24 * 60 * 60_000;
    const monthsCutoff = 304 * 24 * 60 * 60_000;
    const yearDuration = 365 * 24 * 60 * 60_000;
    let { unit, factor } =
      normalizedDuration < secondsCutoff
        ? { unit: 'second', factor: secondsDuration }
        : normalizedDuration < minutesCutoff
        ? { unit: 'minute', factor: minuteDuration }
        : normalizedDuration < hoursCutoff
        ? { unit: 'hour', factor: hoursDuration }
        : normalizedDuration < daysCutoff
        ? { unit: 'day', factor: daysDuration }
        : normalizedDuration < monthsCutoff
        ? { unit: 'month', factor: monthDuration }
        : { unit: 'year', factor: yearDuration };
    let unitedDuration: string | number = (normalizedDuration / factor) | 0;
    if (unitedDuration < 1) {
      unitedDuration = unit == 'hour' ? 'About an' : 'About a';
    } else if (unitedDuration == 1) {
      unitedDuration = unit == 'hour' ? 'An' : 'A';
    } else if (unitedDuration < 4) {
      unitedDuration = 'A few';
      unit = unit + 's';
    } else {
      unitedDuration = `${unitedDuration}`;
      unit = unit + 's';
    }
    return `${unitedDuration} ${unit} ${suffix}`;
  }

  L(value: string, source?: string, ...argus: string[]): string {
    if (source) {
      return abp.localization.localize(value, source, ...argus);
    } else {
      return abp.localization.localize(
        value,
        APP_CONSTS.localization.defaultLocalizationSourceName,
        ...argus
      );
    }
  }
  hasPermission(permissionName: string) {
    return abp.auth.isGranted(permissionName);
  }
  hasAnyOfPermissions(...argus: string[]) {
    return abp.auth.isAnyGranted(...argus);
  }
  hasAllOfPermissions(...argus: string[]) {
    return abp.auth.areAllGranted(...argus);
  }
  $store!: Store<RootState>;
}
