
import { Component, Watch } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase';
import type { DocViewState } from '../../components/markdownViewer.vue';
import MarkdownViewer from '../../components/markdownViewer.vue';
import { VIcon } from 'vuetify/lib';

function randomId(length = 10) {
  const randoms = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ234567';
  let result = '';
  while (result.length < length) {
    result += randoms[(Math.random() * randoms.length) | 0];
  }
  return result;
}

const mdiStethoscope =
  'M19,8C19.56,8 20,8.43 20,9A1,1 0 0,1 19,10C18.43,10 18,9.55 18,9C18,8.43 18.43,8 19,8M2,2V11C2,13.96 4.19,16.5 7.14,16.91C7.76,19.92 10.42,22 13.5,22A6.5,6.5 0 0,0 20,15.5V11.81C21.16,11.39 22,10.29 22,9A3,3 0 0,0 19,6A3,3 0 0,0 16,9C16,10.29 16.84,11.4 18,11.81V15.41C18,17.91 16,19.91 13.5,19.91C11.5,19.91 9.82,18.7 9.22,16.9C12,16.3 14,13.8 14,11V2H10V5H12V11A4,4 0 0,1 8,15A4,4 0 0,1 4,11V5H6V2H2Z';
const mdiNeedle =
  'M5.421 3.226a.725.725 0 01.605-.222.723.723 0 01.644.5c.108.298.035.567-.186.79l-.053.053c-.18.158-.35.327-.492.462l1.586 1.59c.034-.04.08-.102.132-.156l2.238-2.24c.332-.333.803-.346 1.11-.033.302.31.285.77-.043 1.099-.274.272-.55.542-.86.847.073.054.135.09.185.138 2.524 2.524 5.05 5.05 7.577 7.573.09.09.13.176.13.306-.008.932-.002 1.864-.006 2.797a.44.44 0 00.142.354c.826.815 1.647 1.635 2.462 2.462.149.153.325.38.393.507.059.137.059.234 0 .423-.274.658-.975.665-1.417.194-.813-.869-1.677-1.69-2.514-2.536a.411.411 0 00-.323-.13c-.932.005-1.865 0-2.797.006a.432.432 0 01-.336-.14c-2.519-2.524-5.04-5.043-7.56-7.566-.05-.049-.079-.115-.13-.19-.316.322-.59.602-.869.878-.251.25-.574.314-.87.18-.492-.222-.593-.848-.197-1.247.666-.67 1.335-1.335 2.005-2.002.14-.14.285-.273.417-.398L4.824 5.95l-.555.557a.72.72 0 01-.605.223.717.717 0 01-.643-.498c-.109-.299-.037-.568.185-.792.683-.686 1.363-1.374 2.055-2.05zm1.544 5.879l1.159 1.157c.293.294.584.591.884.88a.342.342 0 00.208.096c1.335.006 2.671.004 4.007.004a.147.147 0 00.043-.007L9.05 7.023 6.965 9.105z';

@Component({
  components: {
    'markdown-viewer': MarkdownViewer,
    VIcon,
  },
})
export default class ArticleView extends AbpBase {
  get id(): string {
    return this.$route.params['id'];
  }

  get stateKey(): string | never[] {
    const result = this.$route.query['s'];
    return Array.isArray(result) ? [] : result;
  }

  set stateKey(v: string | never[]) {
    void this.$router.replace({
      name: 'article',
      params: { id: this.id },
      query: { s: v },
    });
  }

  get documentsAreReady(): boolean {
    const state = this.$store.state.articleList;
    return state.completedInitialLoad;
  }

  viewstate: DocViewState = {
    id: '',
  };

  created(): void {
    void this.$store.dispatch('articleList/initDatastore');
  }

  @Watch('stateKey')
  @Watch('id')
  onIdChanged(): void {
    if (this.viewstate && this.viewstate.id != this.id) {
      this.viewstate = {
        id: this.id,
      };
    }
    if (this.documentsAreReady) {
      void this.checkDocument();
    }
  }

  onViewStateChanged(docViewState: DocViewState): void {
    if (!this.stateKey || Array.isArray(this.stateKey)) {
      this.stateKey = randomId();
    }
    this.$store.commit({
      type: 'app/setViewState',
      payload: {
        key: `${this.id}\uffef${this.stateKey}`,
        docViewState: {
          ...docViewState,
          id: this.id,
        },
      },
    });
  }

  @Watch('documentsAreReady')
  onReadyStateChange(val: boolean, was: boolean) {
    if (!val || was) {
      return;
    }
    void this.checkDocument();
  }

  mounted() {
    if (this.documentsAreReady) {
      void this.checkDocument();
    }
  }

  get showWatermark(): boolean {
    const state = this.$store.state.article;
    if (!state.currentRemote) {
      return false;
    }
    if (!state.currentRemote.isPublic) {
      return false;
    }
    return true;
  }

  get watermark(): string {
    const state = this.$store.state.article;
    if (!state.currentRemote) {
      return '';
    }
    if (!state.currentRemote.isPublic) {
      return '';
    }
    if (state.currentRemote.protocolSection == 'BLS') {
      return mdiStethoscope;
    }
    return mdiNeedle;
  }

  get watermarkColor(): string {
    const state = this.$store.state.article;
    if (!state.currentRemote) {
      return '';
    }
    if (!state.currentRemote.isPublic) {
      return '';
    }
    if (state.currentRemote.protocolSection == 'BLS') {
      return 'blue--text';
    }
    return 'red--text';
  }

  get content(): string {
    const state = this.$store.state.article;
    if (state.currentRemote) {
      return state.currentRemote.documentBody || '';
    }
    return '';
  }

  get showAll(): boolean {
    return this.$store.state.app.showAll;
  }

  async checkDocument(): Promise<void> {
    const state = this.$store.state.articleList;
    if (this.stateKey && !Array.isArray(this.stateKey)) {
      if (
        this.$store.state.app.docViewStates[`${this.id}\uffef${this.stateKey}`]
      ) {
        this.viewstate = this.$store.state.app.docViewStates[
          `${this.id}\uffef${this.stateKey}`
        ];
        if (this.viewstate.id != this.id) {
          this.viewstate = {
            id: this.id,
          };
        }
      }
    } else {
      this.viewstate = {
        id: this.id,
      };
    }
    if (!(this.id in state.documentIndices)) {
      void this.$router.replace({ name: 'not-found', params: { id: this.id } });
      return;
    }
    const idWas = this.$store.state.article.currentRemote?.documentName;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const success: boolean = await this.$store.dispatch({
      type: 'articleList/pickDocument',
      payload: { id: this.id, warnOnContextSwitch: typeof idWas === 'string' },
    });
    if (!success) {
      void this.$router.replace({ name: 'article', params: { id: idWas! } });
    }
  }
}
