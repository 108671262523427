import { defaults, Options } from './defaults';
import Renderer, { BlockRenderer } from './Renderer';
import Slugger, { ISlugger } from './Slugger';
import {
  LexerToken,
  TextLexerToken,
  Fragment,
  ContentWithFragments,
} from './Lexer';
import InlineLexer from './InlineLexer';
import TextRenderer from './TextRenderer';

import { unescape, Ctor } from './helpers';

/**
 * Parsing & Compiling
 */
export default class Parser<Out = string> {
  options: Options<Out>;
  renderer: BlockRenderer<Out>;
  slugger: ISlugger = new Slugger();
  inline: InlineLexer<Out>;
  inlineText: InlineLexer<string>;
  tokens: LexerToken[] = [];

  constructor(
    renderFactory: Ctor<BlockRenderer<Out>>,
    options?: Partial<Options<Out>>
  ) {
    this.options = {
      ...defaults,
      ...(options || {}),
    };
    this.renderer = this.options.renderer =
      this.options.renderer || new renderFactory();
    this.renderer.setOptions(this.options);
    this.inline = new InlineLexer({}, this.options, this.renderer);
    this.inlineText = new InlineLexer({}, this.options, new TextRenderer());
  }

  /**
   * Static Parse Method
   */
  static parse(
    tokens: LexerToken[],
    links: { [key: string]: { href: string; title?: string } },
    options?: Partial<Options>
  ) {
    const parser = new Parser<string>(Renderer, options);
    return parser.parse(tokens, links);
  }

  /**
   * Parse Loop
   */
  parse(
    tokens: LexerToken[],
    links: { [key: string]: { href: string; title?: string } }
  ) {
    this.inline.reset(links);
    this.inlineText.reset(links);
    tokens = [...tokens];

    this.tokens = tokens.reverse();

    let out = this.renderer.start();
    let token: LexerToken | undefined;
    while ((token = this.next())) {
      out = this.renderer.cat(out, this.tok(token));
    }

    return out;
  }

  /**
   * Next Token
   */
  next() {
    return this.tokens.pop();
  }

  /**
   * Preview Next Token
   */
  peek(): LexerToken | undefined {
    return this.tokens[this.tokens.length - 1];
  }

  /**
   * Parse Text Tokens
   */
  parseText(token: TextLexerToken) {
    let body = token.text;
    let fragments = token.fragments;

    let nextToken: LexerToken | undefined;
    while ((nextToken = this.peek())) {
      if (nextToken.type !== 'text') {
        break;
      }
      this.next();
      fragments.push(
        {
          role: 'display',
          rawContent: '\n',
          start: fragments[fragments.length - 1].end,
          end: nextToken.fragments[0].start,
          contentLength: 1,
          contentOffset: body.length,
        },
        ...nextToken.fragments.map(fr => {
          if (typeof fr.contentOffset === 'undefined') {
            return fr;
          }
          return {
            ...fr,
            contentOffset: fr.contentOffset + body.length + 1,
          };
        })
      );
      nextToken.fragments = [];

      body += '\n' + nextToken.text;
    }

    return this.inline.output(body, fragments);
  }

  spliceCellFragments(fragments: Fragment[], cell: ContentWithFragments) {
    const results: Fragment[] = [];
    let insertPoint: undefined | number;
    let active = false;
    for (let i = 0; i < fragments.length; ) {
      const fragment = fragments[i];
      if (typeof fragment.tokenLocalIndex === 'undefined' && active) {
        fragments.splice(i, 1);
        continue;
      } else if (typeof fragment.tokenLocalIndex === 'undefined') {
        i++;
        continue;
      }
      if (
        cell.fragmentIndexes.to &&
        fragment.tokenLocalIndex! >= cell.fragmentIndexes.to
      ) {
        break;
      }
      if (fragment.tokenLocalIndex! >= cell.fragmentIndexes.from) {
        fragments.splice(i, 1);
        active = true;
        if (typeof insertPoint == 'undefined') {
          insertPoint = i;
        }
      } else {
        i++;
        continue;
      }
    }
    if (typeof insertPoint == 'undefined') {
      insertPoint = fragments.length;
    }
    fragments.splice(insertPoint, 0, ...cell.fragmentIndexes.fragments!);
    return results;
  }

  collectCellFragments(fragments: Fragment[], cell: ContentWithFragments) {
    const results: Fragment[] = [];
    let active = false;
    for (const fragment of fragments) {
      if (typeof fragment.tokenLocalIndex === 'undefined' && active) {
        results.push(fragment);
      } else if (typeof fragment.tokenLocalIndex === 'undefined') {
        continue;
      }
      if (
        cell.fragmentIndexes.to &&
        fragment.tokenLocalIndex! >= cell.fragmentIndexes.to
      ) {
        break;
      }
      if (fragment.tokenLocalIndex! >= cell.fragmentIndexes.from) {
        results.push(fragment);
        active = true;
      }
    }
    cell.fragmentIndexes.fragments = results;
    return results;
  }

  /**
   * Parse Current Token
   */
  tok(token: LexerToken): Out {
    let body: Out = this.renderer.start();
    switch (token.type) {
      case 'space': {
        return this.renderer.ofText('');
      }
      case 'hr': {
        return this.renderer.hr();
      }
      case 'heading': {
        const style = `h${token.depth}`;
        for (const fragment of token.fragments) {
          fragment.blockStyles = fragment.blockStyles || [];
          fragment.blockStyles.push(style);
        }
        return this.renderer.heading(
          this.inline.output(token.text, token.fragments),
          token.depth,
          unescape(this.inlineText.output(token.text, token.fragments)),
          this.slugger
        );
      }
      case 'code': {
        const style = `code`;
        for (const fragment of token.fragments) {
          fragment.blockStyles = fragment.blockStyles || [];
          fragment.blockStyles.push(style);
        }
        return this.renderer.code(token.text, token.lang, token.escaped);
      }
      case 'table': {
        const style = `table`;
        for (const fragment of token.fragments) {
          fragment.blockStyles = fragment.blockStyles || [];
          fragment.blockStyles.push(style);
        }
        let header = this.renderer.start(),
          i,
          row,
          cell,
          j;

        // header
        cell = this.renderer.start();
        for (i = 0; i < token.header.length; i++) {
          const fragments = this.collectCellFragments(
            token.fragments,
            token.header[i]
          );
          cell = this.renderer.cat(
            cell,
            this.renderer.tablecell(
              this.inline.output(token.header[i].content, fragments),
              { header: true, align: token.align[i] }
            )
          );
          this.spliceCellFragments(token.fragments, token.header[i]);
        }
        header = this.renderer.cat(header, this.renderer.tablerow(cell));

        for (i = 0; i < token.cells.length; i++) {
          row = token.cells[i];

          cell = this.renderer.start();
          for (j = 0; j < row.length; j++) {
            const fragments = this.collectCellFragments(
              token.fragments,
              row[j]
            );
            cell = this.renderer.cat(
              cell,
              this.renderer.tablecell(
                this.inline.output(row[j].content, fragments),
                { header: false, align: token.align[j] }
              )
            );
            this.spliceCellFragments(token.fragments, row[j]);
          }

          body = this.renderer.cat(body, this.renderer.tablerow(cell));
        }
        return this.renderer.table(header, body);
      }
      case 'blockquote_start': {
        body = this.renderer.start();

        let innertoken;
        while (
          (innertoken = this.next()) &&
          innertoken.type !== 'blockquote_end'
        ) {
          body = this.renderer.cat(body, this.tok(innertoken));
        }

        return this.renderer.blockquote(body);
      }
      case 'list_start': {
        body = this.renderer.start();
        const ordered = token.ordered,
          start = token.start;

        let innertoken;
        while ((innertoken = this.next()) && innertoken.type !== 'list_end') {
          body = this.renderer.cat(body, this.tok(innertoken));
        }

        return this.renderer.list(body, ordered, start, token.orderstyle);
      }
      case 'list_item_start': {
        body = this.renderer.start();
        const loose = token.loose;
        const checked = token.checked || false;
        const task = token.task;

        if (token.task) {
          body = this.renderer.cat(body, this.renderer.checkbox(checked));
        }

        let nextToken: LexerToken | undefined;
        while (
          (nextToken = this.next()) &&
          nextToken.type !== 'list_item_end'
        ) {
          body = this.renderer.cat(
            body,
            !loose && nextToken.type === 'text'
              ? this.parseText(nextToken)
              : this.tok(nextToken)
          );
        }
        return this.renderer.listitem(body, task, checked);
      }
      case 'html': {
        // TODO parse inline content if parameter markdown=1
        return this.renderer.html(token.text, true);
      }
      case 'paragraph': {
        return this.renderer.paragraph(
          this.inline.output(token.text, token.fragments)
        );
      }
      case 'text': {
        return this.renderer.paragraph(this.parseText(token));
      }
      case 'attr_start': {
        this.renderer.styleNextElement(
          token.classes,
          token.attributes,
          token.id
        );
        return body;
      }
      default: {
        const errMsg = 'Token with "' + token.type + '" type was not found.';
        if (this.options.silent) {
          console.log(errMsg);
        } else {
          throw new Error(errMsg);
        }
      }
    }
    return body;
  }
}
