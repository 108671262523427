import { Options } from './defaults';
import { escape } from './helpers';

/**
 * TextRenderer
 * returns only the textual part of the token
 */
export interface ITextRenderer<Out> {
  setOptions(options: Options<Out>): void;
  strong(text: Out): Out;
  sub(text: Out): Out;
  sup(text: Out): Out;
  marked(text: Out): Out;
  em(text: Out): Out;
  codespan(text: Out): Out;
  del(text: Out): Out;
  text(text: Out): Out;
  link(href: string, title: string | null, text: Out): Out;
  image(
    href: string,
    title: string | null,
    text: string,
    width: number | undefined,
    height: number | undefined
  ): Out;
  br(): Out;
  html(html: string, long: boolean): Out;

  ofText(text: string, encode?: boolean): Out;

  start(): Out;
  cat(a: Out, b: Out): Out;
}

export default class TextRenderer implements ITextRenderer<string> {
  start(): string {
    return '';
  }
  cat(a: string, b: string): string {
    return a + ' ' + b;
  }
  ofText(text: string, encodeIt?: boolean): string {
    return escape(text, encodeIt);
  }

  setOptions(_options: Options<any>) {
    // noop
  }

  // no need for block level renderers
  strong(text: string) {
    return text;
  }

  em(text: string) {
    return text;
  }

  codespan(text: string) {
    return text;
  }

  del(text: string) {
    return text;
  }

  text(text: string) {
    return text;
  }

  link(_href: string, _title: string | null, text: string) {
    return '' + text;
  }

  image(_href: string, _title: string | null, text: string) {
    return '' + text;
  }

  br() {
    return '';
  }
  sub(text: string) {
    return text;
  }
  sup(text: string) {
    return text;
  }

  marked(text: string) {
    return text;
  }

  html(): string {
    return '';
  }
}
