var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "search-results-host",
      attrs: { "aria-busy": _vm.isSearching, "aria-relevant": "additions" },
    },
    [
      _c("v-progress-linear", {
        attrs: {
          active: _vm.isSearching,
          indeterminate: _vm.isSearching,
          absolute: "",
          top: "",
          color: "secondary",
        },
      }),
      _vm.noResults
        ? _c(
            "v-list-item",
            {
              staticClass: "noresults",
              attrs: { disabled: "", role: "alert" },
            },
            [_vm._v(" " + _vm._s(_vm.L("NoResults")) + " ")]
          )
        : _vm._e(),
      _vm._l(_vm.searchResults, function (item) {
        return _c(
          "v-list-item",
          {
            key: item.documentName,
            attrs: {
              "three-line": "",
              to: { name: "article", params: { id: item.documentName } },
            },
            on: {
              click: function ($event) {
                return _vm.itemSelected()
              },
            },
          },
          [
            _c(
              "v-list-item-content",
              { attrs: { role: "" } },
              [
                _c(
                  "v-list-item-title",
                  _vm._l(item.documentNameFragments, function (fragment) {
                    return _c(
                      "span",
                      {
                        key: fragment.content,
                        class: { mark: fragment.highlighted },
                      },
                      [_vm._v(_vm._s(fragment.contents))]
                    )
                  }),
                  0
                ),
                _c(
                  "v-list-item-subtitle",
                  _vm._l(item.excerpts, function (excerpt) {
                    return _c(
                      "div",
                      { key: excerpt.ix, staticClass: "excerpt" },
                      _vm._l(excerpt.fragments, function (fragment) {
                        return _c(
                          "span",
                          {
                            key: fragment.content,
                            class: { mark: fragment.highlighted },
                          },
                          [_vm._v(_vm._s(fragment.contents))]
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("div", {
        directives: [
          {
            name: "intersect",
            rawName: "v-intersect",
            value: _vm.testIntersection,
            expression: "testIntersection",
          },
        ],
        staticClass: "intersection",
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }