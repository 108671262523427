import type { ActionContext } from 'vuex';
import type { ActionPayload } from '..';
import { abp } from '../../lib/abp';
import Ajax from '../../lib/ajax';
import type { PageResult } from '../../store/entities/page-result';
import type { Role } from '../entities/role';
import type { User } from '../entities/user';
import ListModule from './list-module';
import type ListState from './list-state';

export interface UserState extends ListState<User> {
  editUser: User;
  roles: Role[];
}
// class UserMutations extends ListMutations<User> {}
class UserModule extends ListModule<any, User> {
  state = {
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    list: [],
    loading: false,
    editUser: {
      id: 0,
    },
    roles: [],
  };

  actions = {
    async getAll(context: ActionContext<UserState, any>, payload: any) {
      context.state.loading = true;
      const reponse = await Ajax.get('/api/services/app/User/GetAll', {
        params: payload.data,
      });
      context.state.loading = false;
      const page = reponse.data.result as PageResult<User>;
      context.state.totalCount = page.totalCount;
      context.state.list = page.items;
    },
    async create(_context: ActionContext<UserState, any>, payload: any) {
      await Ajax.post('/api/services/app/User/Create', payload.data);
    },
    async update(_context: ActionContext<UserState, any>, payload: any) {
      await Ajax.put('/api/services/app/User/Update', payload.data);
    },
    async delete(_context: ActionContext<UserState, any>, payload: any) {
      await Ajax.delete('/api/services/app/User/Delete?Id=' + payload.data.id);
    },
    async get(_context: ActionContext<UserState, any>, payload: any) {
      const reponse = await Ajax.get(
        '/api/services/app/User/Get?Id=' + payload.id
      );
      return reponse.data.result as User;
    },
    async getRoles(context: ActionContext<UserState, any>) {
      const reponse = await Ajax.get('/api/services/app/User/GetRoles');
      context.state.roles = reponse.data.result.items as Role[];
    },
    async changeLanguage(
      _context: ActionContext<UserState, any>,
      payload: any
    ) {
      await Ajax.post('/api/services/app/User/ChangeLanguage', payload.data);
    },
    async updateSelf(
      context: ActionContext<UserState, any>,
      payload: ActionPayload<{
        name: string;
        surname: string;
        password: string;
      }>
    ) {
      await Ajax.put('/api/services/app/User/UpdateSelf', payload.payload);
      if (payload.payload.password) {
        abp.utils.setCookieValue(
          'passwordauthenticated',
          'true',
          undefined,
          abp.appPath
        );
      }
      context.commit(
        {
          type: 'app/openModal',
          payload: null,
        },
        { root: true }
      );
    },
  };

  mutations = {
    setCurrentPage(state: UserState, page: number) {
      state.currentPage = page;
    },
    setPageSize(state: UserState, pagesize: number) {
      state.pageSize = pagesize;
    },
    edit(state: UserState, user: User) {
      state.editUser = user;
    },
  };
}
const userModule = new UserModule();
export default userModule;
