import { ActionContext, Module } from 'vuex';
import { abp } from '../../lib/abp';
import ajax from '../../lib/ajax';
import util from '../../lib/util';
import { Notification } from '../../store/modules/app';
import { ActionPayload } from '../index';

export interface TenantLoginInfo {
  tenancyName: string | null;
  name: string | null;
  id: number;
}
export interface UserLoginInfo {
  name: string | null;
  surname: string | null;
  userName: string | null;
  emailAddress: string | null;
  id: number;
}
export interface ApplicationInfo {
  version: string | null;
  releaseDate: string;
  features: {
    [feature: string]: boolean;
  };
}
interface RolesResponse {
  items: { id: number }[];
}

export interface SessionState {
  sessionLoaded: boolean;
  application: ApplicationInfo | null;
  user: UserLoginInfo | null;
  tenant: TenantLoginInfo | null;
}

class SessionStore implements Module<SessionState, any> {
  namespaced = true;
  state = {
    sessionLoaded: false,
    application: null,
    user: null,
    tenant: null,
  };
  actions = {
    async clear(context: ActionContext<SessionState, any>) {
      util.abp.session = {
        userId: undefined,
        tenantId: util.abp.session.tenantId,
        multiTenancySide: util.abp.session.multiTenancySide,
      };
      const sessionState = {
        ...context.state,
        user: null,
      };
      context.commit({
        type: 'loadUser',
        payload: {
          ...sessionState,
        },
      });
      context.dispatch(
        {
          type: 'app/updateMenuList',
        },
        { root: true }
      );
      context.dispatch(
        {
          type: 'articleList/startLoadDocuments',
        },
        { root: true }
      );
    },
    async init(
      context: ActionContext<SessionState, any>,
      arg: ActionPayload<boolean>
    ) {
      try {
        const loginInfoRep = await ajax.get(
          '/api/services/app/Session/GetCurrentLoginInformations',
          {
            headers: {
              'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
            },
          }
        );
        const sessionState = loginInfoRep.data.result as SessionState;
        if (sessionState.user) {
          util.abp.session = {
            userId: sessionState.user.id || undefined,
            tenantId: sessionState.tenant!.id || undefined,
            multiTenancySide: util.abp.multiTenancy.sides.TENANT,
          };

          const rolesResp = await ajax.get('/api/services/app/User/GetRoles', {
            headers: {
              'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
            },
          });
          const rolesData = rolesResp.data.result as RolesResponse;
          const permissions: { [roles: string]: boolean } = {};
          for (const role of rolesData.items) {
            const roleDescription = await ajax.get(
              `/api/services/app/Role/Get?Id=${role.id}`
            );
            const roleData = roleDescription.data.result as {
              grantedPermissions: string[];
            };
            for (const role of roleData.grantedPermissions) {
              permissions[role] = true;
            }
          }
          util.abp.auth.grantedPermissions = permissions;
          const isPasswordAuthenticated =
            abp.utils.getCookieValue('passwordauthenticated') != 'false';
          if (!isPasswordAuthenticated) {
            context.commit(
              {
                type: 'app/openModal',
                payload: 'self-settings',
              },
              { root: true }
            );
          }
        } else {
          util.abp.auth.grantedPermissions = {};
          util.abp.session = {
            userId: undefined,
            tenantId: sessionState.tenant!.id || undefined,
            multiTenancySide: util.abp.multiTenancy.sides.TENANT,
          };
        }

        context.commit({
          type: 'loadUser',
          payload: {
            ...sessionState,
          },
        });
        context.dispatch(
          {
            type: 'app/updateMenuList',
          },
          { root: true }
        );
        context.dispatch(
          {
            type: 'articleList/startLoadDocuments',
          },
          { root: true }
        );
      } catch {
        if (arg.payload) {
          const payload: Notification = {
            message: 'CouldNotCompleteLoginOperation',
            severity: 'warning',
            duration: 15_000,
            tag: 'Connectivity',
          };
          context.dispatch(
            {
              type: 'app/notify',
              payload,
            },
            { root: true }
          );
        }
      }
      context.commit('sessionLoaded');
    },
  };
  mutations = {
    sessionLoaded(state: SessionState) {
      state.sessionLoaded = true;
    },
    loadUser(state: SessionState, arg: ActionPayload<SessionState>) {
      state.application = arg.payload.application;
      state.user = arg.payload.user;
      state.tenant = arg.payload.tenant;
    },
  };
}
const session = new SessionStore();
export default session;
