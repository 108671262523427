import type { Module } from 'vuex';
import type ListState from './list-state';

export default class ListModule<R, U> implements Module<ListState<U>, R> {
  namespaced = true;
  state: ListState<U> = {
    totalCount: 0,
    currentPage: 1,
    pageSize: 10,
    list: [],
    loading: false,
  };

  mutations = {
    setCurrentPage(state: ListState<U>, page: number) {
      state.currentPage = page;
    },
    setPageSize(state: ListState<U>, pagesize: number) {
      state.pageSize = pagesize;
    },
  };
}
