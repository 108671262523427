import { Module } from 'vuex';
import ajax from '../../lib/ajax';
export interface AccountState {}

const account: Module<AccountState, any> = {
  namespaced: true,
  state: {},
  actions: {
    async isTenantAvailable(_state: any, payload: any) {
      let rep = await ajax.post(
        '/api/services/app/Account/IsTenantAvailable',
        payload.data
      );
      return rep.data.result;
    },
  },
};
export default account;
