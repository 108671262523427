
import { Component, Prop, Emit } from 'vue-property-decorator';
import { articlesAreSame } from '../store/modules/article';

import {
  VSpacer,
  VImg,
  VMenu,
  VBtn,
  VFlex,
  VSlider,
  VIcon,
  VList,
  VListItemContent,
  VListItem,
  VListItemAction,
  VListGroup,
  VCheckbox,
  VListItemGroup,
  VListItemTitle,
} from 'vuetify/lib';
import AbpBase from '../lib/abpbase';
import VMenuCaret from '../components/vMenuCaret.vue';

@Component({
  components: {
    VSpacer,
    VImg,
    VMenu,
    VBtn,
    VFlex,
    VSlider,
    VIcon,
    VMenuCaret,
    VList,
    VListItemContent,
    VListItem,
    VListItemAction,
    VListGroup,
    VListItemTitle,
    VCheckbox,
    VListItemGroup,
  },
})
export default class AppSystemBar extends AbpBase {
  @Prop(Boolean)
  showall!: boolean;

  get showLogo() {
    return this.$store.state.route.name == 'display-notices';
  }

  get id() {
    return this.$store.state.route.params.id;
  }

  get showTabs() {
    return [
      'article',
      'not-found',
      'edit-article',
      'preview-article',
      'article-revisions',
    ].includes(this.$store.state.route.name!);
  }

  get pageTitle() {
    if (
      [
        'article',
        'not-found',
        'edit-article',
        'preview-article',
        'article-revisions',
      ].includes(this.$store.state.route.name!)
    ) {
      return this.$store.state.route.params.id;
    }
    if (this.$store.state.route.meta && this.$store.state.route.meta.title) {
      return this.L(this.$store.state.route.meta.title);
    } else {
      return this.L(this.$store.state.route.name || '');
    }
  }

  get longCategory() {
    if (
      [
        'article',
        'not-found',
        'edit-article',
        'preview-article',
        'article-revisions',
      ].includes(this.$store.state.route.name!)
    ) {
      const path = this.$store.state.article.currentRemote?.hierarchyParent || [
        '',
      ];
      return path.length ? path[path.length - 1] : '';
    }
    return '';
  }

  get shortCategory() {
    if (
      [
        'article',
        'not-found',
        'edit-article',
        'preview-article',
        'article-revisions',
      ].includes(this.$store.state.route.name!)
    ) {
      return this.$store.state.article.currentRemote?.hierarchyWeight || '';
    }
    return '';
  }

  get notificationCount() {
    return 0;
  }

  get avatarImg() {
    return null;
  }

  get avatarInitials() {
    if (this.$store.state.session && this.$store.state.session.user) {
      const currentUser = this.$store.state.session.user;
      return (
        (currentUser.name || '').substring(0, 1) +
        (currentUser.surname || '').substring(0, 1)
      );
    }
    return '';
  }

  get hasEdits() {
    const state = this.$store.state.article;
    return (
      !articlesAreSame(state.currentEditing, state.currentEditingSrc) ||
      this.$store.state.route.name == 'preview-article'
    );
  }

  get loggedIn() {
    return !!(this.$store.state.session && this.$store.state.session.user);
  }

  readonly colorArray: Array<string> = [
    'pink',
    'purple',
    'deep-purple',
    'indigo',
    'teal',
    'amber',
    'blue-grey',
  ];

  get avatarColor() {
    try {
      if (this.$store.state.session && this.$store.state.session.user) {
        let colorIndex =
          this.$store.state.session.user.id % this.colorArray.length;
        return this.colorArray[colorIndex];
      } else {
        return 'grey darken-1';
      }
    } catch (e) {
      return 'red';
    }
  }

  get avatarTextColor() {
    return 'white--text';
  }

  goBack() {
    this.$router.back();
  }

  toggleDrawer() {
    this.$store.commit('app/toggleDrawer');
  }
  async logout() {
    await this.$store.dispatch('app/logout');
  }

  login() {
    this.$router.push({
      name: 'login',
    });
  }

  get docExists(): boolean {
    const state = this.$store.state.articleList;
    return this.id in state.documentIndices;
  }

  @Prop(Number)
  zoom: number = 50;

  @Emit('zoomchanged')
  zoomChanged() {}
}
