
import { Component, Watch, Emit } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';

import {
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VProgressLinear,
} from 'vuetify/lib';
import { Intersect } from 'vuetify/lib/directives';

@Component({
  components: {
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemSubtitle,
    VProgressLinear,
  },
  directives: {
    Intersect,
  },
})
export default class SearchResults extends AbpBase {
  reachedEnd = false;
  nextEmit = false;
  emit = false;

  get isSearching() {
    return this.$store.state.search.isSearching;
  }

  get expectedResults() {
    return this.$store.state.search.count;
  }

  get searchResults() {
    return this.$store.state.search.results?.documents || [];
  }

  get noResults() {
    return this.$store.state.search.results && this.searchResults.length == 0;
  }

  testIntersection(entries: IntersectionObserverEntry[]): void {
    this.reachedEnd = entries[0].intersectionRatio > 0;
  }

  @Emit('selected')
  itemSelected() {}

  @Watch('isSearching')
  @Watch('reachedEnd')
  checkUnderflow() {
    this.nextEmit =
      !this.isSearching &&
      this.reachedEnd &&
      this.searchResults.length == this.expectedResults;
  }

  @Watch('nextEmit')
  propagateEmit() {
    if (!this.nextEmit) {
      this.emit = false;
    } else if (!this.emit) {
      window.setTimeout(() => {
        if (!this.emit && this.nextEmit) {
          this.emit = true;
          this.$emit('underflow');
        }
      }, 200);
    }
  }
}
