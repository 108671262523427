import '@mdi/font/scss/materialdesignicons.scss';
import merge from 'lodash/merge';
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from './app.vue';
import VTabs from './components/vTabs';
import abpUserConfiguration from './config/abp-user-configuration.json';
import { nullToEmpty } from './filters/nullToEmpty';
import init from './init';
import AppLayout from './layouts/mobile/appLayout.vue';
import SimpleLayout from './layouts/simpleLayout.vue';
import type { Abp } from './lib/abp';
import { abp } from './lib/abp';
import Util from './lib/util';
import './local-roboto.css';
import vuetify from './plugins/vuetify'; // path to vuetify export
import { router } from './router/index';
import store from './store/index';
import './theme.less';

init();

window.Vue = window.Vue || Vue;

Vue.component('SimpleLayout', SimpleLayout);
Vue.component('AppLayout', AppLayout);

Vue.component('VTabsEx', VTabs);

type DeepPartialObject<S extends {}> = {
  [K in keyof S]?: DeepPartial<S[K]>;
};

type DeepPartial<S> = S extends {} ? DeepPartialObject<S> : S;

Vue.config.productionTip = false;
sync(store, router, { moduleName: 'route' });

Vue.filter('nullToEmpty', nullToEmpty);

if (!abp.utils.getCookieValue('Abp.Localization.CultureName')) {
  const language = navigator.language;
  abp.utils.setCookieValue(
    'Abp.Localization.CultureName',
    language,
    new Date(new Date().getTime() + 5 * 365 * 86400000),
    abp.appPath
  );
}

Promise.resolve(abpUserConfiguration)
  .then(data => {
    data.result.localization.values.SJEM = {
      ...data.result.localization.values.Abp,
      ...data.result.localization.values.AbpWeb,
      ...data.result.localization.values.AbpZero,
      // include this last.
      ...data.result.localization.values.SJEM,
    };
    Util.abp = merge(Util.abp, data.result as DeepPartial<Abp>);
    new Vue({
      data: {},
      async mounted(this: App) {
        await this.$store.dispatch({
          type: 'session/init',
          payload: false,
        });
      },
      created() {},
      render: h => h(App),
      router: router,
      store: store,
      vuetify: vuetify,
    }).$mount('#app');
  })
  .catch(() => {
    alert('Error loading configuration');
  });
