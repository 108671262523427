
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import RawMarkdownContents from './contentViewer';
import type {
  SimpleVNodeList,
  SimpleVNode,
  SimpleVElement,
} from '../lib/marked/marked';
import { parseHTML, marked } from '../lib/marked/marked';
import { VImg, VSkeletonLoader } from 'vuetify/lib';

function tagIsBlacklisted(tag: string) {
  switch (tag) {
    case 'script':
    case 'style':
    case 'template':
    case 'object':
    case 'iframe':
    case 'embed':
    case 'applet':
    case 'base':
    case 'basefont':
    case 'command':
    case 'frame':
    case 'frameset':
    case 'keygen':
    case 'link':
    case 'meta':
    case 'noframes':
    case 'noscript':
    case 'param':
    case 'title':
    case 'audio':
    case 'head':
    case 'math':
    case 'script':
    case 'style':
    case 'template':
    case 'svg':
    case 'video':
      return true;
  }
  return false;
}

function uriSafeAttribute(attr: string) {
  switch (attr) {
    case 'alt':
    case 'class':
    case 'for':
    case 'id':
    case 'label':
    case 'name':
    case 'pattern':
    case 'placeholder':
    case 'summary':
    case 'title':
    case 'value':
    case 'style':
    case 'xmlns':
      return true;
  }
  return false;
}

function attrIsBlacklisted(attr: string) {
  switch (attr) {
    case 'accept-charset':
    case 'accesskey':
    case 'allow':
    case 'async':
    case 'autocapitalize':
    case 'autofocus':
    case 'autoplay':
    case 'buffered':
    case 'challenge':
    case 'charset':
    case 'code':
    case 'codebase':
    case 'content':
    case 'contenteditable':
    case 'contextmenu':
    case 'codebase':
    case 'content':
    case 'contenteditable':
    case 'contextmenu':
    case 'controls':
    case 'data':
    case 'decoding':
    case 'defer':
    case 'dirname':
    case 'draggable':
    case 'dropzone':
    case 'form':
    case 'formaction':
    case 'http-equiv':
    case 'icon':
    case 'importance':
    case 'itemprop':
    case 'keytype':
    case 'kind':
    case 'language':
    case 'lazyload':
    case 'manifest':
    case 'minlength':
    case 'muted':
    case 'ping':
    case 'sandbox':
    case 'scoped':
    case 'slot':
    case 'spellcheck':
    case 'srcdoc':
    case 'srclang':
    case 'target':
    case 'translate':
    case 'wrap':
      return true;
  }
  return false;
}

function tagIsSelfClosing(tag: string) {
  switch (tag) {
    case 'area':
    case 'base':
    case 'br':
    case 'col':
    case 'command':
    case 'embed':
    case 'keygen':
    case 'menuitem':
    case 'hr':
    case 'img':
    case 'input':
    case 'link':
    case 'meta':
    case 'param':
    case 'source':
    case 'track':
    case 'wbr':
      return true;
  }
  return false;
}

export interface ViewStateChange {
  tab?: string;
  expanders?: {
    [expander: string]: boolean;
  };
}

@Component({
  components: {
    'raw-content': RawMarkdownContents,
    VImg,
    VSkeletonLoader,
  },
})
export default class MarkdownViewer extends Vue {
  mounted() {
    if (this.value) {
      this.onValueChanged();
    }
  }

  @Prop(String)
  docid!: string;

  @Prop()
  value!: string;

  @Prop(Boolean)
  expandall!: boolean;

  @Prop(Object)
  viewstate!: DocViewState | undefined;

  cachedviewstate: DocViewState | undefined;

  content: SimpleVNodeList = [];

  hasTabs = false;

  @Watch('value')
  @Watch('expandall')
  @Watch('viewstate')
  onValueChanged() {
    if (this.cachedviewstate && this.cachedviewstate.id != this.docid) {
      this.cachedviewstate = undefined;
    }
    this.cachedviewstate = this.cachedviewstate || this.viewstate;
    if (!this.cachedviewstate || this.cachedviewstate.id != this.docid) {
      this.cachedviewstate = {
        id: this.docid,
      };
    }

    const result = parseHTML(marked(this.value || '') as string);
    this.hasTabs = visitHTML(
      result,
      'tab',
      {
        id: this.cachedviewstate.id,
        tab: this.cachedviewstate.tab,
        expanders: this.cachedviewstate.expanders,
        expandall: this.expandall,
      },
      { count: 1 }
    );
    this.content = result;
  }

  @Watch('docid')
  onDocIdChanged(): void {
    if (this.cachedviewstate && this.cachedviewstate.id != this.docid) {
      this.cachedviewstate = undefined;
    }
    this.cachedviewstate = this.cachedviewstate || this.viewstate;
    if (this.cachedviewstate && this.cachedviewstate.id != this.docid) {
      this.cachedviewstate = {
        id: this.docid,
      };
    }
  }

  onViewStateChanged(vs: ViewStateChange): void {
    let { tab, expanders } = this.cachedviewstate || {};
    if (vs.tab) {
      tab = vs.tab;
    }
    if (vs.expanders) {
      expanders = (expanders || []).filter(
        e => !(e in vs.expanders!) || vs.expanders![e]
      );
      expanders = [
        ...expanders,
        ...Object.entries(vs.expanders)
          .filter(([, v]) => v)
          .map(([v]) => v),
      ];
    }

    this.cachedviewstate = {
      id: this.docid,
      tab,
      expanders,
    };
    this.$emit('viewstatechanged', this.cachedviewstate);
  }
}

export interface DocViewState {
  id: string;
  tab?: string;
  expanders?: string[];
  expandall?: boolean;
}

export function visitHTML(
  nodes: SimpleVNodeList,
  mode: 'tab' | 'h1expando' | 'h2expando' | 'nodelevel' = 'tab',
  viewstate: DocViewState,
  renderstate: { count: number }
): boolean {
  for (const node of nodes) {
    if (typeof node == 'string') {
      continue;
    }
    for (const attr in node.attributes) {
      if (attr != attr.toLowerCase()) {
        const value = node.attributes[attr];
        node.attributes[attr.toLowerCase()] = value;
        delete node.attributes[attr];
      }
    }
  }
  if (mode == 'tab') {
    const tabNodes = nodes
      .map((f, i) => {
        return { el: f, index: i };
      })
      .filter(
        f =>
          typeof f.el === 'object' &&
          f.el.tag == 'H1' &&
          (f.el.attributes['class'] || '')
            .toUpperCase()
            .split(' ')
            .includes('TAB')
      );

    if (tabNodes.length) {
      const firstTabNodeIndex = tabNodes[0].index;
      let injectNode = false;
      for (let i = 0; i < firstTabNodeIndex; i++) {
        if (typeof nodes[i] === 'object') {
          injectNode = true;
        }
      }
      if (injectNode) {
        nodes.unshift({
          tag: 'h1',
          attributes: {
            class: 'tab',
          },
          children: ['Unnamed Section'],
        });
        for (const tabNode of tabNodes) {
          tabNode.index += 1;
        }
        tabNodes.unshift({
          el: nodes[0],
          index: 0,
        });
      }
      const newResults: SimpleVNodeList = [];
      let value: undefined | number = 1;
      for (let i = tabNodes.length - 1; i >= 0; i--) {
        const tabNode = tabNodes[i];
        const tabContentsIndex = tabNode.index + 1;
        const tabContents = nodes.splice(tabContentsIndex);
        visitHTML(tabContents, 'h1expando', viewstate, renderstate);
        const [tab] = nodes.splice(tabNode.index);
        const tabId =
          (typeof tab !== 'string' && tab.attributes['id']) ||
          `unknown-tab-${renderstate.count++}`;
        const tabIsActive = viewstate.tab && viewstate.tab == tabId;
        if (tabIsActive) {
          value = i;
        }
        newResults.unshift({
          tag: 'v-tab-item',
          attributes: {
            partid: `${tabId}__tabitem`,
          },
          children: [
            {
              tag: 'v-card',
              attributes: { flat: '' },
              children: [
                {
                  tag: 'v-card-text',
                  attributes: {
                    'v-touch': '',
                  },
                  children: tabContents,
                },
              ],
            },
          ],
        });
        newResults.unshift({
          tag: 'v-tab',
          attributes: {
            partid: `${tabId}__tab`,
          },
          children: typeof tab == 'object' ? tab.children : [tab],
        });
      }
      nodes.splice(0, nodes.length, {
        tag: 'v-tabs-ex',
        attributes: { 'margin-left': '24', 'margin-right': '24' },
        children: newResults,
        props: {
          value,
        },
      });
      return true;
    }
    visitHTML(nodes, 'h1expando', viewstate, renderstate);
    return false;
  }
  if (mode == 'h1expando') {
    const tabNodes = nodes
      .map((f, i) => {
        return { el: f, index: i };
      })
      .filter(f => typeof f.el === 'object' && f.el.tag == 'H1');
    if (tabNodes.length) {
      const newResults: SimpleVNodeList = [];
      for (let i = tabNodes.length - 1; i >= 0; i--) {
        const tabNode = tabNodes[i];
        const tabContentsIndex = tabNode.index + 1;
        const tabContents = nodes.splice(tabContentsIndex);
        visitHTML(tabContents, 'h2expando', viewstate, renderstate);
        const [tab] = nodes.splice(tabNode.index);
        const expansionId =
          (typeof tab !== 'string' && tab.attributes['id']) ||
          `unknown-expansion-${renderstate.count++}`;
        visitHTML(
          (tab as SimpleVElement).children,
          'nodelevel',
          viewstate,
          renderstate
        );
        newResults.unshift({
          tag: 'v-expansion-panel',
          attributes: {
            partid: `${expansionId}__panel`,
          },
          props: {
            readonly: viewstate.expandall,
          },
          children: [
            {
              tag: 'v-expansion-panel-header',
              attributes: {
                class: 'h1-accordion',
                partid: `${expansionId}__header`,
              },
              children: [tab],
            },
            {
              tag: 'v-expansion-panel-content',
              attributes: {
                partid: `${expansionId}__content`,
              },
              children: tabContents,
            },
          ],
        });
      }

      visitHTML(nodes, 'h2expando', viewstate, renderstate);
      const value: number[] = newResults
        .map<[SimpleVNode, number]>((n, i) => [n, i])
        .filter(
          ([f]) =>
            viewstate.expandall ||
            (typeof f != 'string' &&
              viewstate.expanders &&
              viewstate.expanders.includes(
                f.attributes['partid'].substring(
                  0,
                  f.attributes['partid'].length - 7
                )
              ))
        )
        .map(([, i]) => i);
      nodes.push({
        tag: 'v-expansion-panels',
        props: {
          value,
        },
        attributes: { flat: '', focusable: '', multiple: '', accordion: '' },
        children: newResults,
      });
    } else {
      visitHTML(nodes, 'h2expando', viewstate, renderstate);
    }
    return false;
  }
  if (mode == 'h2expando') {
    const tabNodes = nodes
      .map((f, i) => {
        return { el: f, index: i };
      })
      .filter(
        f => typeof f.el === 'object' && (f.el.tag == 'HR' || f.el.tag == 'H2')
      );
    if (tabNodes.length) {
      let lastWasHR: boolean | undefined;
      let lastWasNakedH2 = false;
      let endIndex = nodes.length;

      for (let i = tabNodes.length - 1; i >= 0; i--) {
        const tabNode = tabNodes[i];
        if (
          typeof tabNode.el === 'object' &&
          (tabNode.el.tag == 'HR' ||
            (tabNode.el.tag == 'H2' &&
              !(tabNode.el.attributes['class'] || '')
                .toUpperCase()
                .split(' ')
                .some(cl => ['COLLAPSIBLE', 'COLLAPSABLE'].includes(cl))))
        ) {
          const setToVisit = nodes.slice(tabNode.index + 1, endIndex);
          const originalLength = setToVisit.length;

          visitHTML(setToVisit, 'nodelevel', viewstate, renderstate);
          nodes.splice(tabNode.index + 1, originalLength, ...setToVisit);
          visitHTML(tabNode.el.children, 'nodelevel', viewstate, renderstate);

          endIndex = tabNode.index;
          lastWasHR = tabNode.el.tag == 'HR';
          lastWasNakedH2 = !lastWasHR;
          continue;
        }
        const tabContentsIndex = tabNode.index + 1;
        if (lastWasHR) {
          const previousIndex = tabNodes[i + 1].index;
          nodes.splice(previousIndex, 1);
        }
        const tabContents = nodes.splice(
          tabContentsIndex,
          endIndex - tabContentsIndex
        );
        visitHTML(tabContents, 'nodelevel', viewstate, renderstate);
        const [tab] = nodes.splice(tabNode.index, 1);
        const expansionId =
          (typeof tab !== 'string' && tab.attributes['id']) ||
          `unknown-expansion-${renderstate.count++}`;
        visitHTML(
          (tab as SimpleVElement).children,
          'nodelevel',
          viewstate,
          renderstate
        );
        const insertionPoint: SimpleVNode = {
          tag: 'v-expansion-panel',
          attributes: {
            partid: `${expansionId}__panel`,
          },
          props: {
            readonly: viewstate.expandall,
          },
          children: [
            {
              tag: 'v-expansion-panel-header',
              attributes: {
                class: 'h2-accordion',
                partid: `${expansionId}__header`,
              },
              children: [tab],
            },
            {
              tag: 'v-expansion-panel-content',
              attributes: {
                partid: `${expansionId}__content`,
              },
              children: tabContents,
            },
          ],
        };
        if (typeof lastWasHR === 'boolean' && !lastWasHR && !lastWasNakedH2) {
          const lastExpanderIndex = nodes[tabNode.index];
          if (typeof lastExpanderIndex === 'object') {
            lastExpanderIndex.props = lastExpanderIndex.props || {};
            lastExpanderIndex.props.value = lastExpanderIndex.props.value || [];
            let value = lastExpanderIndex.props.value as number[];
            for (let i = 0; i < value.length; i++) {
              value[i] = value[i] + 1;
            }
            if (
              viewstate.expandall ||
              (viewstate.expanders && viewstate.expanders.includes(expansionId))
            ) {
              value.unshift(0);
            }
            lastExpanderIndex.children.unshift(insertionPoint);
          }
        } else {
          nodes.splice(tabNode.index, 0, {
            tag: 'v-expansion-panels',
            attributes: {
              flat: '',
              focusable: '',
              multiple: '',
              accordion: '',
            },
            props: {
              value:
                viewstate.expandall ||
                (viewstate.expanders &&
                  viewstate.expanders.includes(expansionId))
                  ? [0]
                  : [],
            },
            children: [insertionPoint],
          });
        }
        endIndex = tabNode.index;
        lastWasHR = false;
        lastWasNakedH2 = false;
      }
      const setToVisit = nodes.slice(0, endIndex);
      const originalLength = setToVisit.length + (lastWasHR ? 1 : 0);
      visitHTML(setToVisit, 'nodelevel', viewstate, renderstate);
      nodes.splice(0, originalLength, ...setToVisit);
    } else {
      visitHTML(nodes, 'nodelevel', viewstate, renderstate);
    }
    return false;
  }
  if (mode == 'nodelevel') {
    for (let i = nodes.length - 1; i >= 0; i--) {
      const node = nodes[i];
      if (typeof node == 'string') {
        continue;
      }
      const tag = node.tag.toLowerCase();

      if (tagIsBlacklisted(tag)) {
        nodes.splice(i, 1);
        continue;
      }

      if (tag.includes('-')) {
        nodes.splice(i, 1);
        continue;
      }
      for (const attr in node.attributes) {
        if (attrIsBlacklisted(attr.toLowerCase())) {
          delete node.attributes[attr];
          continue;
        }
        if (attr.toLowerCase().startsWith('on')) {
          // reject any script attributes.
          delete node.attributes[attr];
          continue;
        }
        if (attr.toLowerCase().trim() == 'is') {
          // reject any attempt to spin up vue components.
          delete node.attributes[attr];
          continue;
        }
        const value = node.attributes[attr];
        if (
          !uriSafeAttribute(attr) &&
          value.toLowerCase().includes('javascript:')
        ) {
          delete node.attributes[attr];
          continue;
        }
      }
      if (tagIsSelfClosing(tag)) {
        node.children = [];
      }
      if (tag == 'img' && (node.attributes.src || '').startsWith('icon:')) {
        const icon = node.attributes.src.substring(5).trim();
        node.tag = 'V-ICON';
        node.attributes = {};
        node.children = [icon];
      }
      if (tag == 'img') {
        node.tag = 'V-IMG';
        //attachments.add(node.attributes.src);
        if ((node.attributes.src || '').startsWith('attachment:')) {
          node.attributes['original-src'] = node.attributes.src;
          // node.attributes['eager'] = '';
          //let cachedSrc = cachedAttachments.get(node.attributes.src);
          node.attributes.src = '';
        }
      }

      if (tag == 'table') {
        const originalChildren = node.children;
        const originalAttributes = node.attributes;
        node.tag = 'DIV';
        node.attributes = {
          class: 'table-wrapper',
        };
        node.children = [
          {
            tag: 'TABLE',
            attributes: originalAttributes,
            children: originalChildren,
          },
        ];
        visitHTML(originalChildren, 'nodelevel', viewstate, renderstate);
        continue;
      }

      if (tag == 'a' && (node.attributes.href || '').startsWith('/article/')) {
        const [, , rawDocumentId] = node.attributes['href'].split('/');
        const documentId = decodeURIComponent(rawDocumentId);
        delete node.attributes['href'];
        node.attributes['to'] = JSON.stringify({
          name: 'article',
          params: { id: documentId },
        });
        node.tag = 'router-link';
      }
      visitHTML(node.children, 'nodelevel', viewstate, renderstate);

      if (node.tag.toUpperCase() == 'V-IMG') {
        node.children = [
          {
            tag: 'V-SKELETON-LOADER',
            attributes: {
              slot: 'placeholder',

              maxWidth: '100%',
              type: 'image',
            },
            children: [],
          },
        ];
      }
    }
  }
  return false;
}
