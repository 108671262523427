
import { Component, Watch } from 'vue-property-decorator';
import AbpBase from '../lib/abpbase';
import { VBtn, VIcon } from 'vuetify/lib';

@Component({
  components: {
    VBtn,
    VIcon,
  },
})
export default class ZoomableImage extends AbpBase {
  imageSized: boolean = false;

  imageNaturalHeight: number = 0;
  imageNaturalWidth: number = 0;

  clientHeight: number = 0;
  clientWidth: number = 0;
  clientFontSize: number = 0;
  firstUpdate = false;

  get scaleFactorToFit() {
    const availableHeight = this.clientHeight - 2 * this.clientFontSize;
    const availableWidth = this.clientWidth - 2 * this.clientFontSize;
    const heightScale = availableHeight / this.imageNaturalHeight;
    const widthScale = availableWidth / this.imageNaturalWidth;
    return heightScale < widthScale ? heightScale : widthScale;
  }

  get generatedHeight() {
    return this.imageNaturalHeight;
  }
  get generatedWidth() {
    return this.imageNaturalWidth;
  }
  get generatedLeft() {
    return (this.clientWidth - this.generatedWidth) / 2;
  }
  get generatedTop() {
    return (this.clientHeight - this.generatedHeight) / 2;
  }
  translateX: number = 0;
  translateY: number = 0;

  _onResizedBound!: () => void;

  get attachmentMap() {
    return this.$store.state.articleList.attachmentMap;
  }

  get visible() {
    return this.$store.state.imageView.modalOpen;
  }

  get imageIdentifier() {
    return this.$store.state.imageView.currentImage;
  }

  get attachmentURL() {
    if (this.imageIdentifier in this.attachmentMap) {
      return this.attachmentMap[this.imageIdentifier].url;
    }
    return '';
  }

  get attachmentStyle() {
    if (!this.imageSized) {
      return {
        'background-image': `url(${this.attachmentURL})`,
      };
    }
    return {
      'background-image': `url(${this.attachmentURL})`,
      width: `${this.generatedWidth}px`,
      height: `${this.generatedHeight}px`,
      left: `${this.generatedLeft}px`,
      top: `${this.generatedTop}px`,
      transform: `scale(${this.scaleFactorToFit}) translate(${this.translateX}, ${this.translateY})`,
    };
  }

  _onResized() {
    const host = this.$refs['host'] as HTMLDivElement;
    if (!host) {
      return;
    }
    const rect = host.getBoundingClientRect();
    this.clientWidth = rect.width;
    this.clientHeight = rect.height;
    this.firstUpdate = true;
  }

  mounted() {
    this.onImageIdentifierChanged(this.imageIdentifier);
    this._onResizedBound = this._onResized.bind(this);
    window.addEventListener('resize', this._onResizedBound, { passive: true });
  }

  unmounted() {
    window.removeEventListener('resize', this._onResizedBound);
  }

  updated() {
    if (this.firstUpdate) {
      return;
    }
    this._onResized();
    const host = this.$refs['host'] as HTMLDivElement;
    const style = window.getComputedStyle(host);
    const rawFontSize = style.getPropertyValue('font-size');
    if (rawFontSize.endsWith('px')) {
      this.clientFontSize = Number(
        rawFontSize.substring(0, rawFontSize.length - 2)
      );
    }
  }

  @Watch('imageIdentifier')
  onImageIdentifierChanged(imageId: string) {
    this.imageSized = false;
    const pending = imageId ? [imageId] : [];
    this.$store.dispatch({
      type: 'articleList/blobifyAttachments',
      payload: { pending, user: 'markdown-viewer' },
    });
  }
  close() {
    this.$store.commit({
      type: 'imageView/closeModal',
    });
  }

  noop() {}

  imageLoaded() {
    const imgProxy = this.$refs['imgProxy'] as HTMLImageElement;
    this.imageNaturalHeight = imgProxy.naturalHeight;
    this.imageNaturalWidth = imgProxy.naturalWidth;
    this.imageSized = true;
  }
}
