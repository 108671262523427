var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "notification-host" },
    _vm._l(_vm.notifications, function (notification) {
      return _c(
        "v-snackbar",
        {
          key: notification.index,
          attrs: {
            value: true,
            timeout: 0,
            bottom: "",
            right: "",
            absolute: "",
            vertical: "",
          },
        },
        [
          notification.details
            ? _c("h5", [_vm._v(_vm._s(_vm.L(notification.message)))])
            : _vm._e(),
          notification.details
            ? _c("p", [_vm._v(_vm._s(_vm.L(notification.details)))])
            : _c("p", [_vm._v(_vm._s(_vm.L(notification.message)))]),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function ($event) {
                  return _vm.closeNotification(notification.index)
                },
              },
            },
            [_vm._v(_vm._s(_vm.L("Close")))]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }