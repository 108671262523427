
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class VMenuCaret extends Vue {
  @Prop(Boolean)
  right!: boolean;
}
