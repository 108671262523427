var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "article-host" },
    [
      _c("markdown-viewer", {
        attrs: {
          docid: _vm.id,
          expandall: _vm.showAll,
          viewstate: _vm.viewstate,
        },
        on: { viewstatechanged: _vm.onViewStateChanged },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
      _vm.showWatermark
        ? _c("div", { class: "watermark " + _vm.watermarkColor }, [
            _c("svg", { attrs: { viewBox: "0 0 24 24" } }, [
              _c("path", { attrs: { d: _vm.watermark } }),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }