
import { Component, Vue } from 'vue-property-decorator';
import NotificationHost from '../components/notificationHost.vue';
import ModalHost from '../components/modalHost.vue';

@Component({
  components: {
    'notification-host': NotificationHost,
    'modal-host': ModalHost,
  },
})
export default class SimpleLayout extends Vue {}
