const chunkSize = 6 * 1024;

export function base64Encode(buffer: Uint8Array): string {
  let result = '';
  const rawBufferStart = buffer.byteOffset;
  const rawBufferEnd = buffer.byteLength + rawBufferStart;
  for (let i = rawBufferStart; i < rawBufferEnd; i += chunkSize) {
    const thisChunkSize =
      rawBufferEnd < i + chunkSize ? rawBufferEnd - i : chunkSize;
    const chunk = new Uint8Array(buffer.buffer, i, thisChunkSize);
    result += btoa(
      String.fromCharCode.apply(String, (chunk as any) as Array<number>)
    );
  }
  return result;
}

export function base64Decode(content: string): Uint8Array {
  const contents = atob(content);
  const rawBuffer = new Uint8Array(contents.length);
  for (let i = 0; i < contents.length; i++) {
    rawBuffer[i] = contents.charCodeAt(i);
  }
  return rawBuffer;
}
