import { edit, noopTest } from './helpers';
// import { noop } from 'vue-class-component/lib/util';

export interface FragmentMatcher {
  exec: (src: string) => RegExpExecArray | null;
}

type BlockRule = keyof typeof blockBase | 'nptable' | 'table' | 'attrs';

export type BlockGrammar = {
  [K in BlockRule]: FragmentMatcher;
} & { item: RegExp; bullet: RegExp };

/**
 * Block-Level Grammar
 */
const _def = /^ {0,3}\[(label)\]: *\n? *<?([^\s>]+)>?(?:(?: +\n? *| *\n *)(title))? *(?:\n+|$)/;
const _label = /(?!\s*\])(?:\\[\[\]]|[^\[\]])+/;
const _title = /(?:"(?:\\"?|[^"\\])*"|'[^'\n]*(?:\n[^'\n]+)*\n?'|\([^()]*\))/;
const def = edit(_def)
  .replace('label', _label)
  .replace('title', _title)
  .getRegex();
const _list = /^( {0,3})(bull) [\s\S]+?(?:hr|def|\n{2,}(?! )(?!\1bull )\n*|\s*$)/;
const bullet = /(?:[*+-]|\d{1,9}\.)/;
const list = edit(_list)
  .replace(/bull/g, bullet)
  .replace(
    'hr',
    '\\n+(?=\\1?(?:(?:- *){3,}|(?:_ *){3,}|(?:\\* *){3,})(?:\\n+|$))'
  )
  .replace('def', '\\n+(?=' + def.source + ')')
  .getRegex();
const _tag = edit(
  'address|article|aside|base|basefont|blockquote|body|caption' +
    '|center|col|colgroup|dd|details|dialog|dir|div|dl|dt|fieldset|figcaption' +
    '|figure|footer|form|frame|frameset|h[1-6]|head|header|hr|html|iframe' +
    '|legend|li|link|main|menu|menuitem|meta|nav|noframes|ol|optgroup|option' +
    '|p|param|section|source|summary|table|tbody|td|tfoot|th|thead|title|tr' +
    '|track|ul'
).getRegex();

const _html = edit(
  '^ {0,3}(?:' + // optional indentation
    '<(script|pre|style)[\\s>][\\s\\S]*?(?:</\\1>[^\\n]*\\n+|$)' + // (1)
    '|comment[^\\n]*(\\n+|$)' + // (2)
    '|<\\?[\\s\\S]*?\\?>\\n*' + // (3)
    '|<![A-Z][\\s\\S]*?>\\n*' + // (4)
    '|<!\\[CDATA\\[[\\s\\S]*?\\]\\]>\\n*' + // (5)
    '|</?(tag)(?: +|\\n|/?>)[\\s\\S]*?(?:\\n{2,}|$)' + // (6)
    '|<(?!script|pre|style)([a-z][\\w-]*)(?:attribute)*? */?>(?=[ \\t]*(?:\\n|$))[\\s\\S]*?(?:\\n{2,}|$)' + // (7) open tag
    '|</(?!script|pre|style)[a-z][\\w-]*\\s*>(?=[ \\t]*(?:\\n|$))[\\s\\S]*?(?:\\n{2,}|$)' + // (7) closing tag
    ')'
).getRegex();

const _comment = /<!--(?!-?>)[\s\S]*?-->/;
const html = edit(_html, 'i')
  .replace('comment', _comment)
  .replace('tag', _tag)
  .replace(
    'attribute',
    / +[a-zA-Z:_][\w.:-]*(?: *= *"[^"\n]*"| *= *'[^'\n]*'| *= *[^\s"'=<>`]+)?/
  )
  .getRegex();
const _paragraph = /^([^\n]+(?:\n(?!hr|heading|lheading|blockquote|fences|list|html)[^\n]+)*)/;
const hr = /^ {0,3}((?:- *){3,}|(?:_ *){3,}|(?:\* *){3,})(?:\n+|$)/;
const paragraph = edit(_paragraph)
  .replace('hr', hr)
  .replace('heading', ' {0,3}#{1,6} +')
  .replace('|lheading', '') // setex headings don't interrupt commonmark paragraphs
  .replace('blockquote', ' {0,3}>')
  .replace('fences', ' {0,3}(?:`{3,}|~{3,})[^`\\n]*\\n')
  .replace('list', ' {0,3}(?:[*+-]|1[.)]) ') // only lists starting from 1 can interrupt
  .replace('html', '</?(?:tag)(?: +|\\n|/?>)|<(?:script|pre|style|!--)')
  .replace('tag', _tag) // pars can be interrupted by type (6) html blocks
  .getRegex();
const _blockQuote = /^( {0,3}> ?([^\n]*)(?:\n|$))+/;
const blockquote = edit(_blockQuote).replace('paragraph', paragraph).getRegex();
export const blockBase = {
  newline: /^\n+/,
  code: /^( {4}[^\n]+\n*)+/,
  fences: /^ {0,3}(`{3,}|~{3,})([^`~\n]*)\n(?:|([\s\S]*?)\n)(?: {0,3}\1[~`]* *(?:\n+|$)|$)/,
  hr: hr,
  heading: /^ {0,3}(#{1,6}) +([^\n]*?)(?: +#+)? *(?:\n+|$)/,
  blockquote,
  list: list,
  html,
  def,
  //nptable: noopTest,
  //table: noopTest,
  lheading: /^([^\n]+)\n {0,3}(=+|-+) *(?:\n+|$)/,
  // regex template, placeholders will be replaced according to different paragraph
  // interruption rules of commonmark and the original markdown spec:
  _paragraph: _paragraph,
  text: /^[^\n]+/,
  _label,
  _title,
  bullet,
  item: edit(/^( *)(bull) ?[^\n]*(?:\n(?!\1bull ?)[^\n]*)*/, 'gm')
    .replace(/bull/g, /(?:[*+-]|\d{1,9}\.)/)
    .getRegex(),
  _comment: _comment,
  paragraph,
};

/**
 * Normal Block Grammar
 */
const blockNormal: BlockGrammar = {
  ...blockBase,
  nptable: noopTest,
  table: noopTest,
  attrs: noopTest,
};

/**
 * GFM Block Grammar
 */

const expandedBullet = /(?:[*+-]|\d{1,9}\.|[a-z]\.|[A-Z]\.|[lxiv]{1,8}\.)/;

const blockGfm: BlockGrammar = {
  ...blockNormal,
  nptable: /^ *([^|\n ].*\|.*)\n *([-:]+ *\|[-| :]*)(?:\n((?:.*[^>\n ].*(?:\n|$))*)\n*|$)/,
  table: /^ *\|(.+)\n *\|?( *[-:]+[-| :]*)(?:\n((?: *[^>\n ].*(?:\n|$))*)\n*|$)/,

  bullet: expandedBullet,
  list: edit(_list)
    .replace(/bull/g, expandedBullet)
    .replace(
      'hr',
      '\\n+(?=\\1?(?:(?:- *){3,}|(?:_ *){3,}|(?:\\* *){3,})(?:\\n+|$))'
    )
    .replace('def', '\\n+(?=' + def.source + ')')
    .getRegex(),
  item: edit(/^( *)(bull) ?[^\n]*(?:\n(?!\1bull ?) *[^\n ][^\n]*)*/, 'gm')
    .replace(/bull/g, expandedBullet)
    .getRegex(),
  attrs: /^([^\n]*)( *\{([^}]+)\} *)((?=\n)|$)/,
};

/**
 * Pedantic grammar (original John Gruber's loose markdown specification)
 */

const blockPedantic: BlockGrammar = {
  ...blockNormal,
  html: edit(
    '^ *(?:comment *(?:\\n|\\s*$)' +
      '|<(tag)[\\s\\S]+?</\\1> *(?:\\n{2,}|\\s*$)' + // closed tag
      '|<tag(?:"[^"]*"|\'[^\']*\'|\\s[^\'"/>\\s]*)*?/?> *(?:\\n{2,}|\\s*$))'
  )
    .replace('comment', blockBase._comment)
    .replace(
      /tag/g,
      '(?!(?:' +
        'a|em|strong|small|s|cite|q|dfn|abbr|data|time|code|var|samp|kbd|sub' +
        '|sup|i|b|u|mark|ruby|rt|rp|bdi|bdo|span|br|wbr|ins|del|img)' +
        '\\b)\\w+(?!:|[^\\w\\s@]*@)\\b'
    )
    .getRegex(),
  def: /^ *\[([^\]]+)\]: *<?([^\s>]+)>?(?: +(["(][^\n]+[")]))? *(?:\n+|$)/,
  heading: /^ *(#{1,6}) *([^\n]+?) *(?:#+ *)?(?:\n+|$)/,
  fences: noopTest, // fences not supported
  paragraph: edit(blockBase._paragraph)
    .replace('hr', blockBase.hr)
    .replace('heading', ' *#{1,6} *[^\n]')
    .replace('lheading', blockBase.lheading)
    .replace('blockquote', ' {0,3}>')
    .replace('|fences', '')
    .replace('|list', '')
    .replace('|html', '')
    .getRegex(),
};

export const block = {
  normal: blockNormal,
  gfm: blockGfm,
  pedantic: blockPedantic,
};

const _em = /^_([^\s_])_(?!_)|^\*([^\s*<\[])\*(?!\*)|^_([^\s<][\s\S]*?[^\s_])_(?!_|[^\spunctuation])|^_([^\s_<][\s\S]*?[^\s])_(?!_|[^\spunctuation])|^\*([^\s<"][\s\S]*?[^\s\*])\*(?!\*|[^\spunctuation])|^\*([^\s*"<\[][\s\S]*?[^\s])\*(?!\*)/;
const _autolink = /^<(scheme:[^\s\x00-\x1f<>]*|email)>/;
const _taginline = edit(
  '^comment' +
    '|^</[a-zA-Z][\\w:-]*\\s*>' + // self-closing tag
    '|^<[a-zA-Z][\\w-]*(?:attribute)*?\\s*/?>' + // open tag
    '|^<\\?[\\s\\S]*?\\?>' + // processing instruction, e.g. <?php ?>
    '|^<![a-zA-Z]+\\s[\\s\\S]*?>' + // declaration, e.g. <!DOCTYPE html>
    '|^<!\\[CDATA\\[[\\s\\S]*?\\]\\]>'
).getRegex(); // CDATA section

// list of punctuation marks from common mark spec
// without ` and ] to workaround Rule 17 (inline code blocks/links)
const _punctuation = '!"#$%&\'()*+,\\-./:;<=>?@\\[^_{|}~';
const em = edit(_em)
  .replace(/punctuation/g, _punctuation)
  .getRegex();
const _scheme = /[a-zA-Z][a-zA-Z0-9+.-]{1,31}/;
const _escapes = /\\([!"#$%&'()*+,\-./:;<=>?@\[\]\\^_`{|}~])/g;
const _email = /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+(?![-_])/;
const autolink = edit(_autolink)
  .replace('scheme', _scheme)
  .replace('email', _email)
  .getRegex();
const _attribute = /\s+[a-zA-Z:_][\w.:-]*(?:\s*=\s*"[^"]*"|\s*=\s*'[^']*'|\s*=\s*[^\s"'=<>`]+)?/;
const taginline = edit(_taginline)
  .replace('comment', blockBase._comment)
  .replace('attribute', _attribute)
  .getRegex();

const _link = /^\[(label)\]\(\s*(href)(?:\s+(title))?\s*\)/;
const _imglink = /^!\[(label)\]\(\s*(href)(?:\s+(title))?\s*\)/;
const _labelinline = /(?:\[[^\[\]]*\]|\\.|`[^`]*`|[^\[\]\\`])*?/;
const _href = /<(?:\\[<>]?|[^\s<>\\])*>|[^\s\x00-\x1f]*/;
const _titleinline = /"(?:\\"?|[^"\\])*"|'(?:\\'?|[^'\\])*'|\((?:\\\)?|[^)\\])*\)/;
const reflink = /^!?\[(label)\]\[(?!\s*\])((?:\\[\[\]]?|[^\[\]\\])+)\]/;
/**
 * Inline-Level Grammar
 */
const inlineBase = {
  escape: /^\\([!"#$%&'()*+,\-./:;<=>?@\[\]\\^_`{|}~])/,
  //url: noopTest,
  tag: taginline,
  link: edit(_link)
    .replace('label', _labelinline)
    .replace('href', _href)
    .replace('title', _titleinline)
    .getRegex(),
  imglink: edit(_imglink)
    .replace('label', _labelinline)
    .replace('href', _href)
    .replace('title', _titleinline)
    .getRegex(),
  reflink: edit(reflink).replace('label', _labelinline).getRegex(),
  nolink: /^!?\[(?!\s*\])((?:\[[^\[\]]*\]|\\[\[\]]|[^\[\]])*)\](?:\[\])?/,
  strong: /^__([^\s_])__(?!_)|^\*\*([^\s*])\*\*(?!\*)|^__([^\s][\s\S]*?[^\s])__(?!_)|^\*\*([^\s][\s\S]*?[^\s])\*\*(?!\*)/,
  em,
  code: /^(`+)([^`]|[^`][\s\S]*?[^`])\1(?!`)/,
  br: /^( {2,}|\\)\n(?!\s*$)/,
  //del: noopTest,
  text: /^(`+|[^`])(?:[\s\S]*?(?:(?=[\\<!\[`*^]|\b_|$)|[^ ](?= {2,}\n))|(?= {2,}\n))/,
  _scheme,
  _escapes,
  _email,
  autolink,
  _attribute,
  _label: _labelinline,
};

type InlineRule =
  | keyof typeof inlineBase
  | 'del'
  | '_backpedal'
  | 'url'
  | 'marked'
  | 'sub'
  | 'sup';

export type InlineGrammar = {
  [K in InlineRule]: FragmentMatcher;
} & { _escapes: RegExp };

/**
 * Normal Inline Grammar
 */

const inlineNormal: InlineGrammar = {
  ...inlineBase,
  del: noopTest,
  _backpedal: noopTest,
  url: noopTest,
  marked: noopTest,
  sub: noopTest,
  sup: noopTest,
};

/**
 * Pedantic Inline Grammar
 */

const inlinePedantic = {
  ...inlineNormal,
  strong: /^__(?=\S)([\s\S]*?\S)__(?!_)|^\*\*(?=\S)([\s\S]*?\S)\*\*(?!\*)/,
  em: /^_(?=\S)([\s\S]*?\S)_(?!_)|^\*(?=\S)([\s\S]*?\S)\*(?!\*)/,
  link: edit(/^\[(label)\]\((.*?)\)/)
    .replace('label', inlineBase._label)
    .getRegex(),
  imglink: edit(/^!\[(label)\]\((.*?)\)/)
    .replace('label', inlineBase._label)
    .getRegex(),
  reflink: edit(/^!?\[(label)\]\s*\[([^\]]*)\]/)
    .replace('label', inlineBase._label)
    .getRegex(),
};

/**
 * GFM Inline Grammar
 */

const inlineGfm = {
  ...inlineNormal,
  escape: edit(inlineBase.escape).replace('])', '~|])').getRegex(),
  _extended_email: /[A-Za-z0-9._+-]+(@)[a-zA-Z0-9-_]+(?:\.[a-zA-Z0-9-_]*[a-zA-Z0-9])+(?![-_])/,
  url: /^((?:ftp|https?):\/\/|www\.)(?:[a-zA-Z0-9\-]+\.?)+[^\s<]*|^email/,
  _backpedal: /(?:[^?!.,:;*_~()&]+|\([^)]*\)|&(?![a-zA-Z0-9]+;$)|[?!.,:;*_~)]+(?!$))+/,
  del: /^~~+(?=\S)([\s\S]*?\S)~~+/,
  sub: /^~(?=\S)([\s\S]*?\S)~/,
  sup: /^\^+(?=\S)([\s\S]*?\S)\^+/,
  marked: /^==(?=\S)([\s\S]*?\S)==/,
  text: /^(`+|[^`])(?:[\s\S]*?(?:(?=[\\<!\[`*~^]|\b_|https?:\/\/|ftp:\/\/|www\.|$)|[^ ](?= {2,}\n)|[^a-zA-Z0-9.!#$%&'*+\/=?_`{\|}~-](?=[a-zA-Z0-9.!#$%&'*+\/=?_`{\|}~-]+@))|(?= {2,}\n|[a-zA-Z0-9.!#$%&'*+\/=?_`{\|}~-]+@))/,
  imglink: edit(
    /^!\[(label)\]\(\s*(href)(?:\s+(title))?(?:\s+(=\d*x\d+|=\d+x\d*))?\)/
  )
    .replace('label', _labelinline)
    .replace('href', _href)
    .replace('title', _titleinline)
    .getRegex(),
};

inlineGfm.url = edit(inlineGfm.url, 'i')
  .replace('email', inlineGfm._extended_email)
  .getRegex();
/**
 * GFM + Line Breaks Inline Grammar
 */

const inlineBreaks = {
  ...inlineGfm,
  br: edit(inlineBase.br).replace('{2,}', '*').getRegex(),
  text: edit(inlineGfm.text)
    .replace('\\b_', '\\b_| {2,}\\n')
    .replace(/\{2,\}/g, '*')
    .getRegex(),
};

export const inline = {
  normal: inlineNormal,
  gfm: inlineGfm,
  pedantic: inlinePedantic,
  breaks: inlineBreaks,
};
