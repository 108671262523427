import type { AppRouteConfig } from './routes';

let isLoaded = false;
const collectedImports: (() => Promise<unknown>)[] = [];

export function registerLoadableRoutes(...routes: AppRouteConfig[]): void {
  collectImports(collectedImports, routes);
}

export function registerLoaderModule(map: {
  [key: string]: () => Promise<unknown>;
}): void {
  for (const [, value] of Object.entries(map)) {
    if (isLoaded) {
      void value();
    } else {
      collectedImports.push(value);
    }
  }
}

export async function forceLoadAllChunks(): Promise<unknown[]> {
  isLoaded = true;
  const copy = [...collectedImports];
  collectedImports.splice(0, collectedImports.length);
  return Promise.all(copy.map(f => f()));
}

// Force the application to load all chunks, so we can prepare to
// switch over service workers.

function collectImports(
  collectedImports: (() => Promise<unknown>)[],
  routes: AppRouteConfig[]
) {
  for (const route of routes) {
    if (typeof route.component !== 'function') {
      continue;
    }
    if ('prototype' in route.component) {
      continue;
    }
    collectedImports.push(route.component as () => Promise<void>);
    if (route.children) {
      collectImports(collectedImports, route.children);
    }
  }
}
