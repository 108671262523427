import { SimpleVNodeList } from './marked/marked';

export function collectImages(
  tokens: SimpleVNodeList,
  images = new Set<string>()
): string[] {
  for (const token of tokens) {
    if (typeof token == 'string') {
      continue;
    }
    if (token.tag.toLowerCase() == 'img') {
      for (const attr in token.attributes) {
        if (
          attr.toLowerCase() == 'src' &&
          token.attributes[attr].startsWith('attachment:')
        ) {
          images.add(token.attributes[attr]);
        }
      }
    }
    collectImages(token.children, images);
  }
  return [...images];
}
