export type Sortable = boolean | string | number;

export interface InsertIntoSorted {
  <
    T extends {},
    K extends keyof T,
    V extends Sortable & T[K] = T[K] & Sortable
  >(
    docs: T[],
    doc: T,
    key: K
  ): void;
  <T extends {}, V extends Sortable = Sortable>(
    docs: T[],
    doc: T,
    keyFunc: (v: T) => V
  ): void;
  <T extends {}>(docs: T[], doc: T, keyFunc: (a: T, b: T) => number): void;
}

function getGreatestPowerOfTwoLessThanOrEqualTo(n: number) {
  n |= n >> 1;
  n |= n >> 2;
  n |= n >> 4;
  n |= n >> 8;
  n |= n >> 16;
  n = n + 1;
  return n >> 1;
}

export function compareSortables<V extends Sortable>(a: V, b: V) {
  if (typeof a === 'boolean' && typeof b !== 'boolean') {
    return -1;
  }
  if (typeof b === 'boolean' && typeof a !== 'boolean') {
    return 1;
  }
  if (typeof a === 'boolean') {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  if (typeof a === 'number' && typeof b === 'string') {
    return -1;
  }
  if (typeof a === 'string' && typeof b === 'number') {
    return 1;
  }
  if (typeof a === 'string' && typeof b === 'string') {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  return a < b ? -1 : a > b ? 1 : 0;
}

export function compareArrays<V extends Sortable>(a: V[], b: V[]) {
  const minLength = a.length < b.length ? a.length : b.length;
  for (let i = 0; i < minLength; i++) {
    let r;
    if ((r = compareSortables(a[i], b[i])) != 0) {
      return r;
    }
  }
  return a.length < b.length ? -1 : a.length > b.length ? 1 : 0;
}

function rawBinarySearch<S, D>(
  docs: S[],
  insertion: D,
  convert: (i: S) => D,
  comparison: (a: D, b: D) => number,
  mode: 'before' | 'after'
): { index: number; match: 'exact' | 'before' | 'after' | 'never' } {
  if (docs.length == 0) {
    return { index: -1, match: 'never' };
  }
  let lockedDigit = 0;
  let smallestIndexGreaterThan = -1;
  let largestIndexLessThan = -1;
  let currentSearch = getGreatestPowerOfTwoLessThanOrEqualTo(docs.length - 1);
  let raggedEnd = true;
  while (currentSearch > 0) {
    const sampleToTest = lockedDigit + currentSearch;
    const testedKey = convert(docs[sampleToTest]);
    const comparisonResult = comparison(testedKey, insertion);
    if (comparisonResult == 0) {
      return {
        index: sampleToTest,
        match: 'exact',
      };
    }
    if (comparisonResult < 0) {
      largestIndexLessThan = sampleToTest;
      lockedDigit = sampleToTest;
      if (raggedEnd) {
        currentSearch = getGreatestPowerOfTwoLessThanOrEqualTo(
          docs.length - lockedDigit - 1
        );
      } else {
        currentSearch = currentSearch >> 1;
      }
    } else {
      smallestIndexGreaterThan = sampleToTest;
      currentSearch = currentSearch >> 1;
      raggedEnd = false;
    }
  }

  if (smallestIndexGreaterThan != largestIndexLessThan + 1) {
    const testedKey = convert(docs[lockedDigit]);
    const comparisonResult = comparison(testedKey, insertion);
    if (comparisonResult == 0) {
      return {
        index: lockedDigit,
        match: 'exact',
      };
    }
    if (comparisonResult < 0) {
      largestIndexLessThan = lockedDigit;
    } else {
      smallestIndexGreaterThan = lockedDigit;
    }
  }
  if (mode == 'before' && largestIndexLessThan == -1) {
    return { index: -1, match: 'never' };
  }
  if (mode == 'before') {
    return { index: largestIndexLessThan, match: 'before' };
  }
  if (smallestIndexGreaterThan == -1) {
    return { index: -1, match: 'never' };
  }
  return { index: smallestIndexGreaterThan, match: 'after' };
}

export interface BinarySearch {
  <
    T extends {},
    K extends keyof T,
    V extends Sortable & T[K] = T[K] & Sortable
  >(
    docs: T[],
    doc: V,
    key: K,
    mode: 'before' | 'after'
  ): { index: number; match: 'exact' | 'before' | 'after' | 'never' };
  <T extends {}, V extends Sortable = Sortable>(
    docs: T[],
    doc: V,
    keyFunc: (v: T) => V,
    mode: 'before' | 'after'
  ): { index: number; match: 'exact' | 'before' | 'after' | 'never' };
  <T extends {}>(
    docs: T[],
    doc: T,
    keyFunc: (a: T, b: T) => number,
    mode: 'before' | 'after'
  ): { index: number; match: 'exact' | 'before' | 'after' | 'never' };
  <T, D>(
    docs: T[],
    doc: D,
    extractFunction: (i: T) => D,
    keyFunc: (a: D, b: D) => number,
    mode: 'before' | 'after'
  ): { index: number; match: 'exact' | 'before' | 'after' | 'never' };
}

export const binarySearch: BinarySearch = (<
  T,
  D,
  K extends keyof T,
  V extends Sortable & T[K] = T[K] & Sortable
>(
  docs: T[],
  doc: D | T | V,
  extractKeyOrFunc:
    | string
    | ((v: T) => Sortable)
    | ((a: T, b: T) => number)
    | ((v: T) => D),
  keyFuncOrMode: ((a: D, b: D) => number) | 'before' | 'after',
  modeOpt: 'before' | 'after' | undefined
): { index: number; match: 'exact' | 'before' | 'after' | 'never' } => {
  const mode: 'before' | 'after' = modeOpt || (keyFuncOrMode as any);
  let extractFn: (i: T) => D =
    modeOpt === undefined ? v => v : (extractKeyOrFunc as any);
  let keyOrFunc = modeOpt === undefined ? extractKeyOrFunc : keyFuncOrMode;
  if (typeof keyOrFunc === 'string') {
    const key = keyOrFunc;
    keyOrFunc = (a: D, b: D) => {
      return compareSortables((a as any) as Sortable, (b as any) as Sortable);
    };
    extractFn = (doc: T) => {
      return (doc[key as keyof T] as any) as D;
    };
  } else if (keyOrFunc.length == 1) {
    const keyFunc = keyOrFunc as (v: T) => Sortable;
    keyOrFunc = (a: T, b: T) => {
      const sortA = keyFunc(a);
      const sortB = keyFunc(b);
      return compareSortables(sortA, sortB);
    };
  }
  const comparison: (a: D, b: D) => number = keyOrFunc as any;
  return rawBinarySearch<T, D>(docs, doc as D, extractFn, comparison, mode);
}) as BinarySearch;

const insertIntoSorted: InsertIntoSorted = <T>(
  docs: T[],
  doc: T,
  keyOrFunc: string | ((v: T) => Sortable) | ((a: T, b: T) => number)
) => {
  if (typeof keyOrFunc === 'string') {
    const key = keyOrFunc;
    keyOrFunc = (doc: T) => {
      return (doc[key as keyof T] as any) as Sortable;
    };
  }
  if (keyOrFunc.length == 1) {
    const keyFunc = keyOrFunc as (v: T) => Sortable;
    keyOrFunc = (a: T, b: T) => {
      const sortA = keyFunc(a);
      const sortB = keyFunc(b);
      return compareSortables(sortA, sortB);
    };
  }
  const keyFunc = keyOrFunc as (a: T, b: T) => number;
  const { index, match } = rawBinarySearch(docs, doc, v => v, keyFunc, 'after');
  if (match == 'exact') {
    docs.splice(index, 1, doc);
    return;
  }
  if (match == 'never') {
    docs.push(doc);
  } else {
    docs.splice(index, 0, doc);
  }
};

export default insertIntoSorted;
