var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("content-viewer", {
        staticClass: "markdown-viewer-content full-bleed",
        attrs: { content: _vm.content, docid: "__home__" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }