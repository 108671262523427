var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            ref: "host",
            staticClass: "zoomable-image-host",
            on: {
              click: function ($event) {
                return _vm.close()
              },
            },
          },
          [
            _vm.attachmentURL && !_vm.imageSized
              ? _c("img", {
                  ref: "imgProxy",
                  staticClass: "invisible",
                  attrs: { src: _vm.attachmentURL },
                  on: {
                    load: function ($event) {
                      return _vm.imageLoaded()
                    },
                  },
                })
              : _vm._e(),
            _c("div", { staticClass: "zoomableframe" }, [
              _c("div", {
                staticClass: "image",
                style: _vm.attachmentStyle,
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.noop()
                  },
                },
              }),
            ]),
            _c(
              "v-btn",
              {
                staticClass: "close",
                attrs: {
                  "aria-label": _vm.L("Close"),
                  large: "",
                  icon: "",
                  color: "white",
                },
                on: {
                  click: function ($event) {
                    return _vm.close()
                  },
                },
              },
              [_c("v-icon", [_vm._v("mdi-close")])],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }