var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "layout",
      staticClass: "mobile-layout",
      attrs: { zoom: _vm.zoomfactor },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "touch",
              rawName: "v-touch",
              value: {
                left: () => _vm.$router.forward(),
                right: () => _vm.$router.back(),
              },
              expression:
                "{ left: () => $router.forward(), right: () => $router.back() }",
            },
          ],
          staticClass: "content-frame",
          class: { mobile: _vm.mobile },
        },
        [
          _c("app-system-bar", {
            attrs: { zoom: _vm.zoom, showall: _vm.showall },
            on: {
              zoomchanged: function ($event) {
                return _vm.zoomChanged($event)
              },
              "update:showall": function ($event) {
                _vm.showall = $event
              },
            },
          }),
          _c(
            "v-content",
            { staticClass: "sized" },
            [
              _c(
                "v-container",
                { staticClass: "padx", attrs: { fluid: "" } },
                [_c("router-view", { attrs: { name: "default" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("notification-host"),
      _c("modal-host", { attrs: { mobile: _vm.mobile } }),
      _c("search-bar", {
        attrs: { mobile: _vm.mobile },
        on: { togglelayout: _vm.toggleLayout },
      }),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "loading-indicator" },
          [
            !_vm.documentsAreReady
              ? _c("v-progress-circular", {
                  attrs: { color: "primary", indeterminate: "" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }