export const abp = window.abp || {};
/* Application paths *****************************************/

//Current application root path (including virtual directory if exists).
abp.appPath = abp.appPath || '/';
abp.pageLoadTime = new Date();

//Converts given path to absolute path using abp.appPath variable.
abp.toAbsAppPath = path => {
  if (path.indexOf('/') == 0) {
    path = path.substring(1);
  }

  return abp.appPath + path;
};

/* MULTITENANCY */

abp.multiTenancy = abp.multiTenancy || {};

abp.multiTenancy.isEnabled = false;

abp.multiTenancy.sides = {
  TENANT: 1,
  HOST: 2,
};

abp.multiTenancy.tenantIdCookieName = 'Abp.TenantId';

abp.multiTenancy.setTenantIdCookie = tenantId => {
  if (tenantId) {
    abp.utils.setCookieValue(
      abp.multiTenancy.tenantIdCookieName,
      tenantId.toString(),
      new Date(new Date().getTime() + 5 * 365 * 86400000), //5 years
      abp.appPath,
      abp.domain
    );
  } else {
    abp.utils.deleteCookie(abp.multiTenancy.tenantIdCookieName, abp.appPath);
  }
};

abp.multiTenancy.getTenantIdCookie = () => {
  const value = abp.utils.getCookieValue(abp.multiTenancy.tenantIdCookieName);
  if (!value) {
    return null;
  }

  return parseInt(value);
};

/* SESSION */

abp.session = abp.session || {
  multiTenancySide: abp.multiTenancy.sides.HOST,
};

const listeners = [];

abp.sessionChanged = function sessionChanged(isLoggedIn) {
  for (const listener of listeners) {
    try {
      listener(isLoggedIn);
    } catch {}
  }
};
abp.onSessionChanged = function onSessionChanged(cb) {
  listeners.push(cb);
};

/* LOCALIZATION ***********************************************/
//Implements Localization API that simplifies usage of localization scripts generated by Abp.

abp.localization = abp.localization || {};

abp.localization.languages = [];

abp.localization.currentLanguage = {};

abp.localization.sources = [];

abp.localization.values = {};

abp.localization.localize = (key, sourceName, ...copiedArguments) => {
  sourceName = sourceName || abp.localization.defaultSourceName;

  const source = abp.localization.values[sourceName];

  if (!source) {
    abp.log.warn('Could not find localization source: ' + sourceName);
    return key;
  }

  const value = source[key];
  if (value == undefined) {
    console.warn(`There was no translation for key ${key}`);
    return key;
  }

  return abp.utils.formatString(value, ...copiedArguments);
};

abp.localization.getSource = sourceName => {
  return (key, ...args) => {
    const copiedArguments = [key, ...args];
    copiedArguments.splice(1, 0, sourceName);
    return abp.localization.localize(...copiedArguments);
  };
};

abp.localization.isCurrentCulture = name => {
  return (
    abp.localization.currentCulture &&
    abp.localization.currentCulture.name &&
    abp.localization.currentCulture.name.indexOf(name) == 0
  );
};

abp.localization.defaultSourceName = undefined;
abp.localization.abpWeb = abp.localization.getSource('AbpWeb');

/* AUTHORIZATION **********************************************/
//Implements Authorization API that simplifies usage of authorization scripts generated by Abp.

abp.auth = abp.auth || {};

abp.auth.allPermissions = abp.auth.allPermissions || {};

abp.auth.grantedPermissions = abp.auth.grantedPermissions || {};

//Deprecated. Use abp.auth.isGranted instead.
abp.auth.hasPermission = (...args) => {
  return abp.auth.isGranted(...args);
};

//Deprecated. Use abp.auth.isAnyGranted instead.
abp.auth.hasAnyOfPermissions = (...args) => {
  return abp.auth.isAnyGranted(...args);
};

//Deprecated. Use abp.auth.areAllGranted instead.
abp.auth.hasAllOfPermissions = (...args) => {
  return abp.auth.areAllGranted(...args);
};

abp.auth.isGranted = permissionName => {
  return (
    abp.auth.allPermissions[permissionName] != undefined &&
    abp.auth.grantedPermissions[permissionName] != undefined
  );
};

abp.auth.isAnyGranted = (...args) => {
  if (args.length == 0) {
    return true;
  }

  for (let i = 0; i < args.length; i++) {
    if (abp.auth.isGranted(args[i])) {
      return true;
    }
  }

  return false;
};

abp.auth.areAllGranted = (...args) => {
  if (args.length == 0) {
    return true;
  }

  for (let i = 0; i < args.length; i++) {
    if (!abp.auth.isGranted(args[i])) {
      return false;
    }
  }

  return true;
};

abp.auth.tokenCookieName = 'Abp.AuthToken';

abp.auth.setToken = (authToken, expireDate) => {
  abp.utils.setCookieValue(
    abp.auth.tokenCookieName,
    authToken,
    expireDate,
    abp.appPath,
    abp.domain
  );
};

abp.auth.getToken = () => {
  return abp.utils.getCookieValue(abp.auth.tokenCookieName);
};

abp.auth.clearToken = () => {
  abp.auth.setToken();
};

/* FEATURE SYSTEM *********************************************/
//Implements Features API that simplifies usage of feature scripts generated by Abp.

abp.features = abp.features || {};

abp.features.allFeatures = abp.features.allFeatures || {};

abp.features.get = name => {
  return abp.features.allFeatures[name];
};

abp.features.getValue = name => {
  const feature = abp.features.get(name);
  if (feature == undefined) {
    return undefined;
  }

  return feature.value;
};

abp.features.isEnabled = name => {
  const value = abp.features.getValue(name);
  return value == 'true' || value == 'True';
};

/* SETTINGS **************************************************/
//Implements Settings API that simplifies usage of setting scripts generated by Abp.

abp.setting = abp.setting || {};

abp.setting.values = abp.setting.values || {};

abp.setting.get = name => {
  return abp.setting.values[name];
};

abp.setting.getBoolean = name => {
  const value = abp.setting.get(name);
  return value == 'true' || value == 'True';
};

abp.setting.getInt = name => {
  return parseInt(abp.setting.values[name]);
};

/* REALTIME NOTIFICATIONS ************************************/

abp.notifications = abp.notifications || {};

abp.notifications.severity = {
  INFO: 0,
  SUCCESS: 1,
  WARN: 2,
  ERROR: 3,
  FATAL: 4,
};

abp.notifications.userNotificationState = {
  UNREAD: 0,
  READ: 1,
};

abp.notifications.getUserNotificationStateAsString = userNotificationState => {
  switch (userNotificationState) {
    case abp.notifications.userNotificationState.READ:
      return 'READ';
    case abp.notifications.userNotificationState.UNREAD:
      return 'UNREAD';
    default:
      abp.log.warn(
        'Unknown user notification state value: ' + userNotificationState
      );
      return '?';
  }
};

abp.notifications.getUiNotifyFuncBySeverity = severity => {
  switch (severity) {
    case abp.notifications.severity.SUCCESS:
      return abp.notify.success;
    case abp.notifications.severity.WARN:
      return abp.notify.warn;
    case abp.notifications.severity.ERROR:
      return abp.notify.error;
    case abp.notifications.severity.FATAL:
      return abp.notify.error;
    case abp.notifications.severity.INFO:
    default:
      return abp.notify.info;
  }
};

abp.notifications.messageFormatters = {};

abp.notifications.messageFormatters[
  'Abp.Notifications.MessageNotificationData'
] = userNotification => {
  return (
    userNotification.notification.data.message ||
    userNotification.notification.data.properties.Message
  );
};

abp.notifications.messageFormatters[
  'Abp.Notifications.LocalizableMessageNotificationData'
] = userNotification => {
  const message =
    userNotification.notification.data.message ||
    userNotification.notification.data.properties.Message;
  const localizedMessage = abp.localization.localize(
    message.name,
    message.sourceName
  );

  if (userNotification.notification.data.properties) {
    const properties = Object.keys(
      userNotification.notification.data.properties
    );
    for (let i = 0; i < properties.length; i++) {
      localizedMessage = localizedMessage.replace(
        '{' + properties[i] + '}',
        userNotification.notification.data.properties[properties[i]]
      );
    }
  }

  return localizedMessage;
};

abp.notifications.getFormattedMessageFromUserNotification = userNotification => {
  const formatter =
    abp.notifications.messageFormatters[
      userNotification.notification.data.type
    ];
  if (!formatter) {
    abp.log.warn(
      'No message formatter defined for given data type: ' +
        userNotification.notification.data.type
    );
    return '?';
  }

  if (!abp.utils.isFunction(formatter)) {
    abp.log.warn(
      'Message formatter should be a function! It is invalid for data type: ' +
        userNotification.notification.data.type
    );
    return '?';
  }

  return formatter(userNotification);
};

abp.notifications.showUiNotifyForUserNotification = (
  userNotification,
  options
) => {
  const message = abp.notifications.getFormattedMessageFromUserNotification(
    userNotification
  );
  const uiNotifyFunc = abp.notifications.getUiNotifyFuncBySeverity(
    userNotification.notification.severity
  );
  uiNotifyFunc(message, undefined, options);
};

/* LOGGING ***************************************************/
//Implements Logging API that provides secure & controlled usage of console.log

abp.log = abp.log || {};

abp.log.levels = {
  DEBUG: 1,
  INFO: 2,
  WARN: 3,
  ERROR: 4,
  FATAL: 5,
};

abp.log.level = abp.log.levels.DEBUG;

abp.log.log = (logObject, logLevel) => {
  if (!window.console || !window.console.log) {
    return;
  }

  if (logLevel != undefined && logLevel < abp.log.level) {
    return;
  }

  console.log(logObject);
};

abp.log.debug = logObject => {
  abp.log.log('DEBUG: ', abp.log.levels.DEBUG);
  abp.log.log(logObject, abp.log.levels.DEBUG);
};

abp.log.info = logObject => {
  abp.log.log('INFO: ', abp.log.levels.INFO);
  abp.log.log(logObject, abp.log.levels.INFO);
};

abp.log.warn = logObject => {
  abp.log.log('WARN: ', abp.log.levels.WARN);
  abp.log.log(logObject, abp.log.levels.WARN);
};

abp.log.error = logObject => {
  abp.log.log('ERROR: ', abp.log.levels.ERROR);
  abp.log.log(logObject, abp.log.levels.ERROR);
};

abp.log.fatal = logObject => {
  abp.log.log('FATAL: ', abp.log.levels.FATAL);
  abp.log.log(logObject, abp.log.levels.FATAL);
};

/* NOTIFICATION *********************************************/
//Defines Notification API, not implements it

abp.notify = abp.notify || {};

abp.notify.success = (message, title, options) => {
  abp.log.warn('abp.notify.success is not implemented!');
};

abp.notify.info = (message, title, options) => {
  abp.log.warn('abp.notify.info is not implemented!');
};

abp.notify.warn = (message, title, options) => {
  abp.log.warn('abp.notify.warn is not implemented!');
};

abp.notify.error = (message, title, options) => {
  abp.log.warn('abp.notify.error is not implemented!');
};

/* MESSAGE **************************************************/
//Defines Message API, not implements it

abp.message = abp.message || {};

const showMessage = (message, title) => {
  alert((title || '') + ' ' + message);
};

abp.message.info = (message, title) => {
  abp.log.warn('abp.message.info is not implemented!');
  return showMessage(message, title);
};

abp.message.success = (message, title) => {
  abp.log.warn('abp.message.success is not implemented!');
  return showMessage(message, title);
};

abp.message.warn = (message, title) => {
  abp.log.warn('abp.message.warn is not implemented!');
  return showMessage(message, title);
};

abp.message.error = (message, title) => {
  abp.log.warn('abp.message.error is not implemented!');
  return showMessage(message, title);
};

abp.message.confirm = (message, titleOrCallback, callback) => {
  abp.log.warn('abp.message.confirm is not implemented!');

  if (titleOrCallback && !(typeof titleOrCallback == 'string')) {
    callback = titleOrCallback;
  }

  const result = confirm(message);
  callback && callback(result);
};

/* UI *******************************************************/

abp.ui = abp.ui || {};

/* UI BLOCK */
//Defines UI Block API, not implements it

abp.ui.block = elm => {
  abp.log.warn('abp.ui.block is not implemented!');
};

abp.ui.unblock = elm => {
  abp.log.warn('abp.ui.unblock is not implemented!');
};

/* UI BUSY */
//Defines UI Busy API, not implements it

abp.ui.setBusy = (elm, optionsOrPromise) => {
  abp.log.warn('abp.ui.setBusy is not implemented!');
};

abp.ui.clearBusy = elm => {
  abp.log.warn('abp.ui.clearBusy is not implemented!');
};

/* SIMPLE EVENT BUS *****************************************/

abp.event = (() => {
  const _callbacks = {};

  // Public interface ///////////////////////////////////////////////////

  return {
    on(eventName, callback) {
      if (!_callbacks[eventName]) {
        _callbacks[eventName] = new Set();
      }

      _callbacks[eventName].add(callback);
    },
    off(eventName, callback) {
      const callbacks = _callbacks[eventName];
      if (!callbacks) {
        return;
      }

      callbacks.delete(callback);
      if (callbacks.size == 0) {
        delete _callbacks[eventName];
      }
    },
    trigger(eventName, ...args) {
      const callbacks = _callbacks[eventName];
      if (!callbacks) {
        return;
      }

      for (const callback of callbacks) {
        callback.apply(this, args);
      }
    },
  };
})();

/* UTILS ***************************************************/

abp.utils = abp.utils || {};

/* Creates a name namespace.
 *  Example:
 *  const taskService = abp.utils.createNamespace(abp, 'services.task');
 *  taskService will be equal to abp.services.task
 *  first argument (root) must be defined first
 ************************************************************/
abp.utils.createNamespace = (root, ns) => {
  const parts = ns.split('.');
  for (let i = 0; i < parts.length; i++) {
    if (typeof root[parts[i]] == 'undefined') {
      root[parts[i]] = {};
    }

    root = root[parts[i]];
  }

  return root;
};

/* Find and replaces a string (search) to another string (replacement) in
 *  given string (str).
 *  Example:
 *  abp.utils.replaceAll('This is a test string', 'is', 'X') = 'ThX X a test string'
 ************************************************************/
abp.utils.replaceAll = (str, search, replacement) => {
  const fix = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  return str.replace(new RegExp(fix, 'g'), replacement);
};

/* Formats a string just like string.format in C#.
 *  Example:
 *  abp.utils.formatString('Hello {0}','Tuana') = 'Hello Tuana'
 ************************************************************/
abp.utils.formatString = (str, ...args) => {
  if (typeof str === 'undefined') {
    return null;
  }

  for (let i = 0; i < args.length; i++) {
    const placeHolder = '{' + i + '}';
    str = abp.utils.replaceAll(str, placeHolder, args[i]);
  }

  return str;
};

abp.utils.toPascalCase = str => {
  if (!str || !str.length) {
    return str;
  }

  if (str.length === 1) {
    return str.charAt(0).toUpperCase();
  }

  return str.charAt(0).toUpperCase() + str.substr(1);
};

abp.utils.toCamelCase = str => {
  if (!str || !str.length) {
    return str;
  }

  if (str.length === 1) {
    return str.charAt(0).toLowerCase();
  }

  return str.charAt(0).toLowerCase() + str.substr(1);
};

abp.utils.truncateString = (str, maxLength) => {
  if (!str || !str.length || str.length <= maxLength) {
    return str;
  }

  return str.substr(0, maxLength);
};

abp.utils.truncateStringWithPostfix = (str, maxLength, postfix) => {
  postfix = postfix || '...';

  if (!str || !str.length || str.length <= maxLength) {
    return str;
  }

  if (maxLength <= postfix.length) {
    return postfix.substr(0, maxLength);
  }

  return str.substr(0, maxLength - postfix.length) + postfix;
};

/**
 * parameterInfos should be an array of { name, value } objects
 * where name is query string parameter name and value is it's value.
 * includeQuestionMark is true by default.
 */
abp.utils.buildQueryString = (parameterInfos, includeQuestionMark) => {
  if (includeQuestionMark === undefined) {
    includeQuestionMark = true;
  }

  let qs = '';

  function addSeperator() {
    if (!qs.length) {
      if (includeQuestionMark) {
        qs = qs + '?';
      }
    } else {
      qs = qs + '&';
    }
  }

  for (let i = 0; i < parameterInfos.length; ++i) {
    const parameterInfo = parameterInfos[i];
    if (parameterInfo.value === undefined) {
      continue;
    }

    if (parameterInfo.value === null) {
      parameterInfo.value = '';
    }

    addSeperator();

    if (
      parameterInfo.value.toJSON &&
      typeof parameterInfo.value.toJSON === 'function'
    ) {
      qs =
        qs +
        parameterInfo.name +
        '=' +
        encodeURIComponent(parameterInfo.value.toJSON());
    } else if (
      Array.isArray(parameterInfo.value) &&
      parameterInfo.value.length
    ) {
      for (let j = 0; j < parameterInfo.value.length; j++) {
        if (j > 0) {
          addSeperator();
        }

        qs =
          qs +
          parameterInfo.name +
          '[' +
          j +
          ']=' +
          encodeURIComponent(parameterInfo.value[j]);
      }
    } else {
      qs =
        qs + parameterInfo.name + '=' + encodeURIComponent(parameterInfo.value);
    }
  }

  return qs;
};

/**
 * Sets a cookie value for given key.
 * This is a simple implementation created to be used by ABP.
 * Please use a complete cookie library if you need.
 * @param {string} key
 * @param {string} value
 * @param {Date} expireDate (optional). If not specified the cookie will expire at the end of session.
 * @param {string} path (optional)
 */
abp.utils.setCookieValue = (key, value, expireDate, path, domain) => {
  let cookieValue = encodeURIComponent(key) + '=';

  if (value) {
    cookieValue = cookieValue + encodeURIComponent(value);
  }

  if (expireDate) {
    cookieValue = cookieValue + '; expires=' + expireDate.toUTCString();
  }

  if (path) {
    cookieValue = cookieValue + '; path=' + path;
  }

  if (domain) {
    cookieValue = cookieValue + '; domain=' + domain;
  }

  document.cookie = cookieValue;
};

/**
 * Gets a cookie with given key.
 * This is a simple implementation created to be used by ABP.
 * Please use a complete cookie library if you need.
 * @param {string} key
 * @returns {string} Cookie value or null
 */
abp.utils.getCookieValue = key => {
  const equalities = document.cookie.split('; ');
  for (let i = 0; i < equalities.length; i++) {
    if (!equalities[i]) {
      continue;
    }

    const splitted = equalities[i].split('=');
    if (splitted.length != 2) {
      continue;
    }

    if (decodeURIComponent(splitted[0]) === key) {
      return decodeURIComponent(splitted[1] || '');
    }
  }

  return null;
};

/**
 * Deletes cookie for given key.
 * This is a simple implementation created to be used by ABP.
 * Please use a complete cookie library if you need.
 * @param {string} key
 * @param {string} path (optional)
 */
abp.utils.deleteCookie = (key, path) => {
  let cookieValue = encodeURIComponent(key) + '=';

  cookieValue =
    cookieValue +
    '; expires=' +
    new Date(new Date().getTime() - 86400000).toUTCString();

  if (path) {
    cookieValue = cookieValue + '; path=' + path;
  }

  document.cookie = cookieValue;
};

/**
 * Gets the domain of given url
 * @param {string} url
 * @returns {string}
 */
abp.utils.getDomain = url => {
  const domainRegex = /(https?:){0,1}\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i;
  const matches = domainRegex.exec(url);
  return matches && matches[2] ? matches[2] : '';
};

/* TIMING *****************************************/
abp.timing = abp.timing || {};

abp.timing.utcClockProvider = (() => {
  const toUtc = date => {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  };

  const now = () => {
    return new Date();
  };

  const normalize = date => {
    if (!date) {
      return date;
    }

    return new Date(toUtc(date));
  };

  // Public interface ///////////////////////////////////////////////////

  return {
    now: now,
    normalize: normalize,
    supportsMultipleTimezone: true,
  };
})();

abp.timing.localClockProvider = (() => {
  const toLocal = date => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    );
  };

  const now = () => {
    return toLocal(new Date());
  };

  const normalize = date => {
    if (!date) {
      return date;
    }

    return toLocal(date);
  };

  // Public interface ///////////////////////////////////////////////////

  return {
    now: now,
    normalize: normalize,
    supportsMultipleTimezone: false,
  };
})();

abp.timing.unspecifiedClockProvider = (() => {
  const now = () => {
    return new Date();
  };

  const normalize = date => {
    return date;
  };

  // Public interface ///////////////////////////////////////////////////

  return {
    now: now,
    normalize: normalize,
    supportsMultipleTimezone: false,
  };
})();

abp.timing.convertToUserTimezone = date => {
  const localTime = date.getTime();
  const utcTime = localTime + date.getTimezoneOffset() * 60000;
  const targetTime =
    parseInt(utcTime) +
    parseInt(abp.timing.timeZoneInfo.windows.currentUtcOffsetInMilliseconds);
  return new Date(targetTime);
};

/* CLOCK *****************************************/
abp.clock = abp.clock || {};

abp.clock.now = () => {
  if (abp.clock.provider) {
    return abp.clock.provider.now();
  }

  return new Date();
};

abp.clock.normalize = date => {
  if (abp.clock.provider) {
    return abp.clock.provider.normalize(date);
  }

  return date;
};

abp.clock.provider = abp.timing.unspecifiedClockProvider;

/* SECURITY ***************************************/
abp.security = abp.security || {};
abp.security.antiForgery = abp.security.antiForgery || {};

abp.security.antiForgery.tokenCookieName = 'XSRF-TOKEN';
abp.security.antiForgery.tokenHeaderName = 'X-XSRF-TOKEN';

abp.security.antiForgery.getToken = () => {
  return abp.utils.getCookieValue(abp.security.antiForgery.tokenCookieName);
};

abp.security.antiForgery.shouldSendToken = settings => {
  if (settings.crossDomain === undefined || settings.crossDomain === null) {
    return (
      abp.utils.getDomain(location.href) === abp.utils.getDomain(settings.url)
    );
  }

  return !settings.crossDomain;
};
