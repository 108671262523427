/**
 * Slugger generates header id
 */
export type ISlugger = Slugger;

export default class Slugger {
  private seen: { [key: string]: number };
  constructor() {
    this.seen = {};
  }

  /**
   * Convert string to unique id
   */
  slug(value: string) {
    let slug = value
      .toLowerCase()
      .trim()
      .replace(
        /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,./:;<=>?@[\]^`{|}~]/g,
        ''
      )
      .replace(/\s/g, '-');

    if (typeof this.seen[slug] != 'undefined') {
      const originalSlug = slug;
      do {
        this.seen[originalSlug]++;
        slug = originalSlug + '-' + this.seen[originalSlug];
      } while (typeof this.seen[slug] != 'undefined');
    }
    this.seen[slug] = 0;

    return slug;
  }
}
