
import { Component, Prop } from 'vue-property-decorator';
import AbpBase from '../../lib/abpbase';
import type { IndexSection } from '../../store/modules/index-configuration';
import type { ArticleSummary } from '../../store/modules/article-list';
import {
  compareSortables,
  compareArrays,
  binarySearch,
} from '../../lib/insert-sorted';
import type { SimpleVNodeList } from '../../lib/marked/marked';
import RawContentViewer from '../../components/contentViewer';
import { VImg, VSkeletonLoader } from 'vuetify/lib';

export function renderSections(
  result: SimpleVNodeList,
  sections: IndexSection[],
  level: number,
  articles: ArticleSummary[],
  searchPath: string[],
  prefix = ''
) {
  if (level < 2) {
    const newresults: SimpleVNodeList = [];
    result.push({
      tag: 'v-expansion-panels',
      attributes: { flat: '', focusable: '', multiple: '', accordion: '' },
      children: newresults,
    });
    result = newresults;
  }
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const tag = level == 0 ? 'H1' : level == 1 ? 'H2' : 'H3';
    const classes =
      level == 0 ? 'h1-accordion' : level == 1 ? 'h2-accordion' : '';
    const sectionName = prefix + section.name;
    const children: SimpleVNodeList = [sectionName];
    if (section.icon) {
      children.unshift({
        tag: 'v-icon',
        attributes: {},
        children: [section.icon],
      });
    }

    let childResults = result;
    if (level < 2) {
      childResults = [];
      result.push({
        tag: 'v-expansion-panel',
        attributes: {},
        children: [
          {
            tag: 'v-expansion-panel-header',
            attributes: { class: classes },
            children: [
              {
                tag,
                attributes: {},
                children,
              },
            ],
          },
          {
            tag: 'v-expansion-panel-content',
            attributes: {},
            children: childResults,
          },
        ],
      });
    } else {
      result.push({
        tag,
        attributes: {},
        children,
      });
    }

    const innerPath = [...searchPath, section.name];
    if (section.children.length) {
      const innerPrefix = level >= 1 ? prefix + section.name + ': ' : '';
      renderSections(
        childResults,
        section.children,
        level + 1,
        articles,
        innerPath,
        innerPrefix
      );
    }
    const searchKey: PartialArticleSummary = {
      isPublic: true,
      hierarchyParent: innerPath,
      hierarchyWeight: '',
      documentName: '',
    };
    const search = binarySearch(
      articles,
      searchKey,
      (v: ArticleSummary) => v as PartialArticleSummary,
      compareArticlesByPosition,
      'after'
    );
    const relevantArticles: {
      documentName: string;
      hierarchyWeight: string;
    }[] = [];
    let j = search.match == 'never' ? articles.length : search.index;
    for (; j < articles.length; j++) {
      const relevantDocument = articles[j];
      if (!relevantDocument.isPublic) {
        break;
      }
      if (
        compareArrays(
          searchKey.hierarchyParent!,
          relevantDocument.hierarchyParent!
        ) != 0
      ) {
        break;
      }
      relevantArticles.push({
        documentName: relevantDocument.documentName,
        hierarchyWeight: relevantDocument.hierarchyWeight!,
      });
    }
    if (relevantArticles.length) {
      const innerResults: SimpleVNodeList = [];
      for (const article of relevantArticles) {
        innerResults.push({
          tag: 'LI',
          attributes: {},
          children: [
            {
              tag: 'A',
              attributes: {
                href: `/article/${encodeURIComponent(article.documentName)}`,
              },
              children: [article.documentName],
            },
          ],
        });
      }
      childResults.push({
        tag: 'UL',
        attributes: {
          class: 'toc',
        },
        children: innerResults,
      });
    }
  }
}

interface PartialArticleSummary extends Partial<ArticleSummary> {
  isPublic: boolean;
  hierarchyParent: string[] | null;
  hierarchyWeight: string | null;
  documentName: string;
}

function compareArticlesByPosition(
  a: PartialArticleSummary,
  b: PartialArticleSummary
) {
  let r;
  if ((r = compareSortables(a.isPublic, b.isPublic)) != 0) {
    return r;
  }
  if (a.isPublic) {
    if ((r = compareArrays(a.hierarchyParent!, b.hierarchyParent!)) != 0) {
      return r;
    }
    if ((r = compareSortables(a.hierarchyWeight!, b.hierarchyWeight!)) != 0) {
      return r;
    }
  }
  return compareSortables(a.documentName, b.documentName);
}

@Component({
  components: {
    'content-viewer': RawContentViewer,
    VImg,
    VSkeletonLoader,
  },
})
export default class Home extends AbpBase {
  @Prop(String)
  section!: 'ALS' | 'BLS';

  get content(): SimpleVNodeList {
    const children: SimpleVNodeList = [];
    renderSections(
      children,
      this.section == 'BLS'
        ? this.$store.state.articleList.indexConfiguration.blsSections
        : this.$store.state.articleList.indexConfiguration.alsSections,
      0,
      this.sortedArticles,
      []
    );
    return children;
  }

  get sortedArticles(): ArticleSummary[] {
    const tempList: ArticleSummary[] = [];
    for (let i = 0; i < this.$store.state.articleList.documents.length; i++) {
      tempList.push(this.$store.state.articleList.documents[i]);
    }
    tempList.sort(compareArticlesByPosition);
    return tempList;
  }

  created() {
    void this.$store.dispatch('articleList/initDatastore');
  }
}
