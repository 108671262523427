import { VNode } from 'vue';
import { VTabs } from 'vuetify/lib/components/VTabs/index';
import VTabsBar from 'vuetify/lib/components/VTabs/VTabsBar';
import VTabsItems from 'vuetify/lib/components/VTabs/VTabsItems';
import { convertToUnit } from 'vuetify/lib/util/helpers';

const VTabsBarEx = VTabsBar.extend({
  props: {
    marginLeft: {
      type: Number,
      default: 0,
    },
    marginRight: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    genContent(this: any) {
      const render = VTabsBar.options.methods.genContent.call(this);
      render.data = render.data || {};
      render.data.style = render.data.style || {};
      render.data.style['marginLeft'] = '' + this.marginLeft + 'px';
      render.data.style['marginRight'] = '' + this.marginRight + 'px';
      return render;
    },
    onTouchEnd(this: any) {
      const { content, wrapper } = this.$refs;
      const maxScrollOffset =
        content.clientWidth +
        this.marginLeft +
        this.marginRight -
        wrapper.clientWidth;
      content.style.setProperty('transition', null);
      content.style.setProperty('willChange', null);

      if (this.$vuetify.rtl) {
        /* istanbul ignore else */
        if (this.scrollOffset > 0 || !this.isOverflowing) {
          this.scrollOffset = 0;
        } else if (this.scrollOffset <= -maxScrollOffset) {
          this.scrollOffset = -maxScrollOffset;
        }
      } else {
        /* istanbul ignore else */
        if (this.scrollOffset < 0 || !this.isOverflowing) {
          this.scrollOffset = 0;
        } else if (this.scrollOffset >= maxScrollOffset) {
          this.scrollOffset = maxScrollOffset;
        }
      }
    },
    setWidths(this: any) {
      window.requestAnimationFrame(() => {
        const { content, wrapper } = this.$refs;
        this.widths = {
          content: content
            ? content.clientWidth + this.marginLeft + this.marginRight
            : 0,
          wrapper: wrapper ? wrapper.clientWidth : 0,
        };
        this.isOverflowing = this.widths.wrapper < this.widths.content;
        this.scrollIntoView();
      });
    },
  },
});

export default VTabs.extend({
  props: {
    marginLeft: {
      type: Number,
      default: 0,
    },
    marginRight: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    genItems(items: VNode | null, item: VNode[]) {
      // If user provides items
      // opt to use theirs
      if (items) return items;

      // If no tabs are provided
      // render nothing
      if (!item.length) return null;

      return this.$createElement(
        VTabsItems,
        {
          props: {
            value: this.internalValue,
            touchless: true,
          },
          on: {
            change: (val: any) => {
              this.internalValue = val;
            },
          },
        },
        item
      );
    },
    genBar(this: any, items: any, slider: any) {
      const data = {
        style: {
          height: convertToUnit(this.height),
        },
        props: {
          activeClass: this.activeClass,
          centerActive: this.centerActive,
          dark: this.dark,
          light: this.light,
          mandatory: !this.optional,
          mobileBreakPoint: this.mobileBreakPoint,
          nextIcon: this.nextIcon,
          prevIcon: this.prevIcon,
          showArrows: this.showArrows,
          value: this.internalValue,
          marginLeft: this.marginLeft,
          marginRight: this.marginRight,
        },
        on: {
          'call:slider': this.callSlider,
          change: (val: any) => {
            this.internalValue = val;
          },
        },
        ref: 'items',
      };
      this.setTextColor(this.computedColor, data);
      this.setBackgroundColor(this.backgroundColor, data);
      return this.$createElement(VTabsBarEx, data, [
        this.genSlider(slider),
        items,
      ]);
    },
  },
});
