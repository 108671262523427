import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { forceLoadAllChunks } from './router/force-load';
import { reloadWhenRouteChanged } from './router/reload-when-route-changes';

window.process = window.process || {};
const env = window.process.env || {};
window.process.env = env;

env['NODE_ENV'] = env['NODE_ENV'] || 'development';
env['VERSION'] = env['VERSION'] || '00000000.0';

if (!('requestIdleCallback' in window)) {
  (window as any)!.requestIdleCallback = function (fn: () => void) {
    window.setTimeout(fn, 0);
  };
}

// Register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// function windowHasAlreadyLoaded() {
//   if ('getEntriesByType' in window.performance) {
//     const navData = window.performance.getEntriesByType('navigation');
//     return (
//       navData.length &&
//       (navData[0] as PerformanceNavigationTiming).loadEventEnd > 0
//     );
//   }
//   return !!(window.performance as any).timing.loadEventEnd;
// }

interface HookFunctions {
  registered?: (registration: ServiceWorkerRegistration) => void;
  updated?: (registration: ServiceWorkerRegistration) => void;
  updatefound?: (registration: ServiceWorkerRegistration) => void;
  cached?: (registration: ServiceWorkerRegistration) => void;
  error?: (error: any) => void;
  registrationOptions?: RegistrationOptions;
}

interface EmitFunction {
  (
    event: 'registered' | 'updated' | 'updatefound' | 'cached',
    registration: ServiceWorkerRegistration
  ): void;
  (event: 'error', error: any): void;
}

// function onLoadedOrImmediate(cb: () => void) {
//   if (windowHasAlreadyLoaded()) {
//     cb();
//     return;
//   }
//   window.addEventListener('load', cb);
// }

function register(swUrl: string, hooks: HookFunctions) {
  if (hooks === void 0) hooks = {};

  const registrationOptions = hooks.registrationOptions || {};
  delete hooks.registrationOptions;

  const emit: EmitFunction = (
    hook: 'registered' | 'updated' | 'updatefound' | 'cached' | 'error',
    registration: any
  ) => {
    const hookFn = hooks[hook];
    if (hookFn) {
      hookFn(registration);
    }
  };

  if ('serviceWorker' in navigator) {
    registerValidSW(swUrl, emit, registrationOptions);
  }
}

async function registerValidSW(
  swUrl: string,
  emit: EmitFunction,
  registrationOptions?: RegistrationOptions
) {
  try {
    const registration = await navigator.serviceWorker.register(
      swUrl,
      registrationOptions
    );
    emit('registered', registration);
    function handleInstalling(registration: ServiceWorkerRegistration) {
      emit('updatefound', registration);
      const installingWorker = registration.installing;
      if (installingWorker) {
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              emit('updated', registration);
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              emit('cached', registration);
            }
          }
        };
      }
    }
    if (registration.waiting) {
      handleInstalling(registration);
    }
    registration.onupdatefound = () => {
      handleInstalling(registration);
    };
  } catch (err) {
    emit('error', err);
  }
}

function swapStyleSheets(modules: boolean) {
  const isIOSWKWebView = navigator.userAgent.includes('iOSWKWebView');
  if (isIOSWKWebView) {
    document.body.style.overflow = 'hidden';
    document.body.style.height = null!;
    document.body.classList.add('iosshell');
  }
  const app = document.getElementById('app')!;
  app.style.opacity = '0';
  app.style.transition = 'opacity 200ms linear';
  const stylesheets = document.querySelectorAll('link');
  const finishLoading = () => {
    app.style.opacity = '1';
    window.setTimeout(() => {
      app.style.opacity = null!;
      app.style.transition = null!;
    }, 10_000);
  };
  window.requestAnimationFrame(() => {
    window.requestAnimationFrame(() => {
      finishLoading();
    });
  });
  for (let i = 0; i < stylesheets.length; i++) {
    if (stylesheets[i].rel != 'stylesheet') {
      continue;
    }
    const isNomodules = stylesheets[i].href.endsWith('.es5.css');
    if ((isNomodules && !modules) || (!isNomodules && modules)) {
      stylesheets[i].media = 'all';
    }
  }
  document.getElementById('scrim')?.remove();
}

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'bec52d17-bb8a-48fe-bb9c-5998280ad49b',
    enableCorsCorrelation: true,
    enableAutoRouteTracking: false,
    maxMessageLimit: 50,
    isBeaconApiDisabled: false,
  },
});

export default function init() {
  swapStyleSheets(true);
  appInsights.loadAppInsights();
  appInsights.trackPageView({
    properties: {
      spaVersion: process.env.VERSION || '00000000.0',
    },
  });

  window.addEventListener('unhandledrejection', ev => {
    appInsights.trackException({
      exception: ev.reason,
    });
  });
  window.addEventListener('error', ev => {
    appInsights.trackException({
      exception: ev.error,
    });
  });
  if (process.env.NODE_ENV === 'production' && location.protocol == 'https:') {
    window.setTimeout(() => {
      (window as any).requestIdleCallback(async () => {
        register('/service-worker.js', {
          registrationOptions: {
            scope: '/',
          },
          cached() {
            console.log('Content has been cached for offline use.');
          },
          async updatefound(worker) {
            console.log(
              'Server content has changed. Preparing for the switch.'
            );
            await forceLoadAllChunks();
            console.log(
              'Loading expired content into window in preparation of the service worker changeover.'
            );
            // if there are any other clients, give them time to catch up...
            window.setTimeout(() => {
              if (worker.waiting) {
                worker.waiting.postMessage({
                  type: 'SKIP_WAITING',
                });
                reloadWhenRouteChanged();
              }
            }, 15000);
          },
          async updated() {
            console.log(
              'New service worker ready. Are we ready for the new service worker?'
            );
            await forceLoadAllChunks();
            reloadWhenRouteChanged();
          },

          error(error) {
            console.error('Error during service worker registration:', error);
          },
        });
      });
    }, 4000);
  } else {
    window.setTimeout(() => {
      void forceLoadAllChunks();
    }, 4000);
  }
}

const url =
  process.env.NODE_ENV === 'production' ? '' : 'http://localhost:21021/';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const APP_CONSTS = {
  userManagement: {
    defaultAdminUserName: 'webmaster@inspironix.com',
  },
  localization: {
    defaultLocalizationSourceName: 'SJEM',
  },
  authorization: {
    encrptedAuthTokenName: 'enc_auth_token',
  },
  appBaseUrl: url,
  remoteServiceBaseUrl: url,
};
