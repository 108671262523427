import type { ComponentOptions } from 'vue';
import type VueBasic from 'vue/types/index';
import type {
  CreateElement,
  VNode,
  VNodeChildrenArrayContents,
  VNodeData,
} from 'vue/types/index';
import {
  VCard,
  VCardText,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VIcon,
  VImg,
  VSkeletonLoader,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
} from 'vuetify/lib';
import { Ripple, Touch } from 'vuetify/lib/directives';
import type { Store } from 'vuex';
import type { SimpleVElement, SimpleVNodeList } from '../lib/marked/marked';
import type { RootState } from '../store';
import type { ArticleListState } from '../store/modules/article-list';

function renderBlockLevelToken(
  h: CreateElement,
  nodes: SimpleVNodeList,
  host: RawContentViewer,
  attachments: string[],
  cachedAttachments: { [key: string]: { url: string } },
  docId: string
): VNodeChildrenArrayContents {
  const results: VNodeChildrenArrayContents = [];
  for (const node of nodes) {
    if (typeof node === 'string') {
      results.push(node);
      continue;
    }
    const tag = node.tag.toLowerCase();
    const children = renderBlockLevelToken(
      h,
      node.children,
      host,
      attachments,
      cachedAttachments,
      docId
    );
    const options: VNodeData & { attrs: { [key: string]: any } } = {
      attrs: {},
      directives: [],
      staticClass: '',
      on: {},
      props: {},
    };

    const attributes = {
      ...node.attributes,
    };
    const { props } = node;

    if (tag == 'v-card') {
      options.attrs['flat'] = 'flat' in attributes;
      delete attributes['flat'];
    }
    if (tag == 'v-expansion-panels') {
      options.attrs['flat'] = 'flat' in attributes;
      options.attrs['focusible'] = 'focusible' in attributes;
      options.attrs['multiple'] = 'multiple' in attributes;
      options.attrs['accordion'] = 'accordion' in attributes;
      delete attributes['flat'];
      delete attributes['focusible'];
      delete attributes['multiple'];
      delete attributes['accordion'];
    }

    if (tag == 'v-expansion-panel') {
      options.attrs['flat'] = 'flat' in attributes;
      options.attrs['focusible'] = 'focusible' in attributes;
      options.attrs['multiple'] = 'multiple' in attributes;
      delete attributes['flat'];
      delete attributes['focusible'];
      delete attributes['multiple'];
    }

    if (tag == 'router-link') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      options.attrs['to'] = JSON.parse(attributes['to']);
      delete attributes['to'];
    }

    if (tag == 'v-tabs-ex') {
      options.attrs['margin-left'] = Number(attributes['margin-left']);
      options.attrs['margin-right'] = Number(attributes['margin-right']);
      const tabs = node.children.filter(
        t => typeof t === 'object' && t.tag == 'v-tab'
      ).length;
      options.attrs['value'] =
        node.props &&
        'value' in node.props &&
        typeof node.props.value === 'number'
          ? node.props.value
          : tabs > 1
          ? 1
          : 0;
      options.key = docId;
      delete attributes['margin-left'];
      delete attributes['margin-right'];
    }

    if (tag == 'v-img' && attributes['original-src']) {
      attributes['src'] =
        cachedAttachments[attributes['original-src']]?.url || '';
      attachments.push(attributes['original-src']);
      options.on!['click'] = (e: MouseEvent) =>
        host.$store.commit({
          type: 'imageView/openModal',
          payload: (e.currentTarget as HTMLElement).getAttribute(
            'original-src'
          )!,
        });
      options.directives!.push({ name: 'Ripple' });
    }
    if (tag == 'v-img') {
      options.on!['error'] = (e: string) => {
        host.onImgError(e);
      };
    }
    if (tag == 'v-expansion-panels') {
      options.on!['change'] = (v: number[]) => {
        host.onExpanderChanged(v, node.children);
      };
    }
    if (tag == 'v-tabs-ex') {
      options.on!['change'] = (v: number | number[]) => {
        if (Array.isArray(v)) {
          v = v[0];
        }

        host.onTabChanged(
          v,
          node.children.filter((_, index) => index % 2 == 0)
        );
      };
    }
    if ('v-touch' in attributes) {
      delete attributes['v-touch'];
    }
    for (const attr in attributes) {
      if (attr.toLowerCase() == 'class') {
        options.staticClass = attributes[attr];
        continue;
      }
      options.attrs[attr] = attributes[attr];
    }
    if (props) {
      for (const [k, v] of Object.entries(props)) {
        options.props = options.props || {};
        if (typeof v === 'undefined') {
          continue;
        }
        options.props[k] = v;
      }
    }
    results.push(h(tag, options, children));
  }
  return results;
}
interface RawContentViewer {
  content: SimpleVNodeList;
  attachmentMap: { [key: string]: { url: string } };
  docid: string;
  $store: Store<RootState>;

  onImgError(url: string): void;
  onExpanderChanged(number: number[], children: SimpleVNodeList): void;
  onTabChanged(number: number, children: SimpleVNodeList): void;
  goBack(): void;
  goForward(): void;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const RawContentViewer: ComponentOptions<VueBasic> = {
  props: {
    content: {
      type: Array,
    },
    docid: {
      type: String,
    },
  },
  components: {
    VImg,
    VTab,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    VTabs,
    VTabItem,
    VCard,
    VCardText,
    VIcon,
    VSkeletonLoader,
    VTabsItems,
  },
  directives: {
    Ripple,
    Touch,
  },
  computed: {
    attachmentMap(this: VueBasic) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return (this.$store.state.articleList as ArticleListState).attachmentMap;
    },
  },
  methods: {
    onImgError(url: string) {
      console.error(`URL ${url} could not be loaded`);
    },

    onExpanderChanged(number: number[], children: SimpleVNodeList): void {
      const openChildren = number
        .map(i => (children.length > i ? children[i] : null))
        .filter<SimpleVElement>(
          (n): n is SimpleVElement => !!n && typeof n !== 'string'
        )
        .map(n => n.attributes['partid']);
      const closedChildren = children
        .map((n, ix) => (number.includes(ix) ? null : n))
        .filter<SimpleVElement>(
          (n): n is SimpleVElement => !!n && typeof n !== 'string'
        )
        .map(n => n.attributes['partid']);
      const expanders: { [partid: string]: boolean } = {};
      for (const openChild of openChildren) {
        const partId = openChild.substring(0, openChild.length - 7);
        expanders[partId] = true;
      }
      for (const closedChild of closedChildren) {
        const partId = closedChild.substring(0, closedChild.length - 7);
        expanders[partId] = false;
      }
      this.$emit('viewstatechanged', { expanders });
    },

    onTabChanged(number: number, children: SimpleVNodeList): void {
      const tabId = children[number];
      if (typeof tabId === 'string' || typeof tabId === 'undefined') {
        return;
      }
      const tabPart = tabId.attributes['partid'];
      this.$emit('viewstatechanged', {
        tab: tabPart.substring(0, tabPart.length - 5),
      });
    },

    goBack(): void {
      this.$router.back();
    },

    goForward(): void {
      this.$router.forward();
    },
  },
  render(this: RawContentViewer, h: CreateElement): VNode {
    const attachments: string[] = [];
    const children: VNode[] = renderBlockLevelToken(
      h,
      this.content,
      this,
      attachments,
      this.attachmentMap,
      this.docid
    ) as VNode[];
    void this.$store.dispatch({
      type: 'articleList/blobifyAttachments',
      payload: { pending: attachments, user: 'markdown-viewer' },
    });
    return h(
      'div',
      {
        attrs: {
          role: 'document',
          'aria-label': 'main',
        },
      },
      children
    );
  },
};

export default RawContentViewer;
