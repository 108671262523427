/* eslint-disable @typescript-eslint/naming-convention */
import PouchDB from 'pouchdb-browser';
import type { ActionContext, Module } from 'vuex';
import type { ActionPayload, RootState } from '..';
import insertIntoSorted, {
  binarySearch,
  compareArrays,
  compareSortables,
} from '../../lib/insert-sorted';
import type { WordKey } from '../../lib/wordcounts';
import getSearchKeys, {
  commonWordsSet,
  getRawContents,
  searchifyText,
} from '../../lib/wordcounts';
import type { DatabaseStorable } from './article-list';
import { datastore } from './article-list';

const MAXIMUM_FRAGMENT_LENGTH = 15;
const MAXIMUM_FRAGMENT_WORDS_PRECEDING = 3;
const MAXIMUM_FRAGMENT_WORDS_AFTER = 5;
const MAXIMUM_FRAGMENT_PIECES = 3;

// const MAX_FULL_MATCHES = 10;
const MAX_FULL_PARTIALS = 50;
const MAXIMUM_DOCUMENT_RESULTS = 10;

interface SearchOptions {
  startkey?: [string] | [string, number] | WordKey;
  endkey?: [string] | [string, number] | WordKey;
  limit: number;
  skip?: number;
  stale?: 'ok' | 'update_after';
  section?: 'ALS' | 'BLS';
}

interface QueryRow {
  key: WordKey;
  id: string;
  seq: string | number;
}

interface QueryResults {
  rows: QueryRow[];
}

interface SearchIndex {
  wordsToId: {
    key: WordKey;
    seq: number | string;
  }[];
  idToKeys: {
    [id: string]: {
      words: WordKey[];
      section?: 'ALS' | 'BLS';
      seq: number | string;
    };
  };
  schema?: number;
  seq: number | string;
  _rev: string | undefined;
}

const searchIndexStore: PouchDB.Database<SearchIndex> = new PouchDB<
  SearchIndex
>('textsearch');
let searchIndex: SearchIndex | undefined;

function updateSearchIndex(
  searchIndex: SearchIndex,
  changes: PouchDB.Core.ChangesResponse<DatabaseStorable>
) {
  searchIndex.seq = changes.last_seq;
  for (const change of changes.results) {
    const previousEntry = searchIndex.idToKeys[change.id] || {
      words: [],
      section: 'ALS',
      seq: 0,
    };
    const doc = change.doc!;
    if (
      doc.type != 'article' ||
      doc.published === false ||
      doc._id.startsWith('draft:')
    ) {
      continue;
    }

    const newKeys = doc.deleted ? [] : getSearchKeys(doc);
    for (const word of newKeys) {
      insertIntoSorted(
        searchIndex.wordsToId,
        { key: word, seq: change.seq },
        (
          a: { key: WordKey; seq: number | string },
          b: { key: WordKey; seq: number | string }
        ) => compareArrays(a.key, b.key)
      );
    }

    for (const word of previousEntry.words) {
      const entry = binarySearch(
        searchIndex.wordsToId,
        { key: word, seq: 0 },
        (a, b) => compareArrays(a.key, b.key),
        'after'
      );
      if (entry.match != 'exact') {
        continue;
      }
      const previousEntry = searchIndex.wordsToId[entry.index];
      if (previousEntry.seq == change.seq) {
        continue;
      }
      searchIndex.wordsToId.splice(entry.index, 1);
    }
    searchIndex.idToKeys[doc._id] = {
      section: doc.protocolSection || 'ALS',
      words: newKeys,
      seq: change.seq,
    };
  }
}

function querySync(
  searchIndex: SearchIndex,
  options: SearchOptions
): QueryResults {
  const results: QueryResults = {
    rows: [],
  };
  let startIndex = 0;
  if (options.startkey) {
    const key: WordKey =
      options.startkey.length == 1
        ? ([options.startkey[0], Number.NEGATIVE_INFINITY, ''] as WordKey)
        : options.startkey.length == 2
        ? ([options.startkey[0], options.startkey[1], ''] as WordKey)
        : (options.startkey as WordKey);
    const match = binarySearch(
      searchIndex.wordsToId,
      { key, seq: 0 },
      (a, b) => compareArrays(a.key, b.key),
      'after'
    );
    if (match.match === 'never') {
      startIndex = searchIndex.wordsToId.length;
    } else {
      startIndex = match.index;
    }
  }
  const skip = options.skip || 0;
  const limit: number | undefined = options.limit;
  startIndex += skip;
  let maxIndex = searchIndex.wordsToId.length;

  const endkey: WordKey | undefined =
    typeof options.endkey === 'undefined'
      ? undefined
      : options.endkey.length == 1
      ? ([options.endkey[0], Number.NEGATIVE_INFINITY, ''] as WordKey)
      : options.endkey.length == 2
      ? ([options.endkey[0], options.endkey[1], ''] as WordKey)
      : options.endkey;
  for (
    let i = startIndex;
    i < maxIndex &&
    (typeof limit === 'undefined' || results.rows.length < limit);
    i++
  ) {
    const key = searchIndex.wordsToId[i];
    if (endkey && compareArrays(key.key, endkey) >= 0) {
      break;
    }
    const [, , id] = key.key;
    if (options.section) {
      const { section } = searchIndex.idToKeys[id];
      if ((section || 'ALS') != options.section) {
        continue;
      }
    }
    results.rows.push({
      key: key.key,
      id: key.key[2],
      seq: key.seq,
    });
  }
  return results;
}

function query(
  db: PouchDB.Database<DatabaseStorable>,
  options: SearchOptions
): Promise<QueryResults> {
  return enqueueOp(
    async (): Promise<QueryResults> => {
      // let _rev: string | undefined;

      if (!searchIndex) {
        try {
          const localSI = await searchIndexStore.get('searchindex');
          searchIndex = localSI;
          if (
            typeof searchIndex.schema === 'undefined' ||
            searchIndex.schema < 2
          ) {
            searchIndex = {
              wordsToId: [],
              idToKeys: {},
              seq: 0,
              _rev: undefined,
              schema: 2,
            };
          }
        } catch {
          searchIndex = {
            wordsToId: [],
            idToKeys: {},
            seq: 0,
            _rev: undefined,
            schema: 2,
          };
        }
      }
      if (options.stale === 'ok') {
      } else if (options.stale == 'update_after') {
        void enqueueOp(async () => {
          const changes = await db.changes({
            filter: (e: DatabaseStorable) => e.type == 'article',
            since: searchIndex!.seq,
            include_docs: true,
          });
          if (changes.last_seq != searchIndex!.seq) {
            updateSearchIndex(searchIndex!, changes);
            // move this off the current stack
            void enqueueOp(async () => {
              try {
                await searchIndexStore.put({
                  ...searchIndex!,
                  _id: 'searchindex',
                });
              } catch {}
            });
          }
        });
      } else {
        const changes = await db.changes({
          filter: (e: DatabaseStorable) => e.type == 'article',
          since: searchIndex.seq,
          include_docs: true,
        });
        if (changes.last_seq != searchIndex.seq) {
          updateSearchIndex(searchIndex, changes);
          // move this off the current stack
          void enqueueOp(async () => {
            try {
              const rev = await searchIndexStore.put({
                ...searchIndex!,
                _id: 'searchindex',
              });
              searchIndex!._rev = rev.rev;
            } catch {}
          });
        }
      }
      return querySync(searchIndex, options);
    }
  );
}

export interface DocumentExceprt {
  fragments: { contents: string; highlighted: boolean }[];
  goodness: number;
  ix: number;
}

export interface SearchResults {
  documents: SearchResult[];
  documentBodies: {
    [name: string]: {
      documentBody: string;
      section: 'ALS' | 'BLS' | undefined;
    };
  };
  search: string;
  count: number;
  section: 'ALS' | 'BLS' | undefined;
}

export interface SearchResult {
  documentName: string;
  documentBody: string;
  section: 'ALS' | 'BLS';
  documentNameFragments: { contents: string; highlighted: boolean }[];
  excerpts: DocumentExceprt[];
  matchScore: number;
}

export interface SearchStep {
  fullWordResults: {
    [word: string]: [WordKey, string][];
  };
  partialWordMatches: {
    matches: [WordKey, string][];
    word: string;
    bigraph?: string;
    overflow: boolean;
  } | null;
  results: SearchResults;
}

export interface SearchState {
  count: number;
  section: 'ALS' | 'BLS' | undefined;
  rawSearch: string;
  search: string;
  isSearching: boolean;
  fullWordResults: {
    [word: string]: [WordKey, string][];
  };
  partialWordMatches: {
    matches: [WordKey, string][];
    word: string;
    bigraph?: string;
    overflow: boolean;
  } | null;
  results: SearchResults | null;
}

function countSpaces(s: string) {
  return s.split(/ /g).length - 1;
}

let fragmentIx = 0;

function highlightText(
  rawLine: string,
  infixedWords: { index: number; length: number }[],
  startsWithSpace = false,
  startIndex = 0,
  endIndex = rawLine.length
) {
  let endOfCurrentlyHighlightedFragment = -1;
  const fragments: { contents: string; highlighted: boolean }[] = [];
  let currentChunk = startsWithSpace ? '\u2026' : '';
  for (let i = startIndex; i < endIndex; i++) {
    if (
      endOfCurrentlyHighlightedFragment < 0 &&
      infixedWords.length &&
      infixedWords[0].index <= i
    ) {
      endOfCurrentlyHighlightedFragment =
        infixedWords[0].index + infixedWords[0].length;
      infixedWords.shift();
      if (currentChunk) {
        fragments.push({
          contents: currentChunk,
          highlighted: false,
        });
      }
      currentChunk = '';
    } else if (
      endOfCurrentlyHighlightedFragment >= 0 &&
      i >= endOfCurrentlyHighlightedFragment
    ) {
      endOfCurrentlyHighlightedFragment = -1;
      if (currentChunk) {
        fragments.push({
          contents: currentChunk,
          highlighted: true,
        });
      }
      currentChunk = '';
    }
    currentChunk += rawLine[i];
  }
  if (endIndex != rawLine.length && endOfCurrentlyHighlightedFragment < 0) {
    currentChunk += '\u2026';
  }
  if (currentChunk) {
    fragments.push({
      contents: currentChunk,
      highlighted: endOfCurrentlyHighlightedFragment >= 0,
    });
  }
  if (endIndex != rawLine.length && endOfCurrentlyHighlightedFragment >= 0) {
    fragments.push({
      contents: '\u2026',
      highlighted: false,
    });
  }
  return fragments;
}

function generateExceptsFromLine(
  rawLine: string,
  searchableLine: string,
  matchers: RegExp[]
): DocumentExceprt[] {
  const wordsFoundInLine: { index: number; length: number }[] = [];
  for (const matcher of matchers) {
    for (const match of searchableLine.matchAll(matcher)) {
      const index = match.index! + (match[0].startsWith(' ') ? 1 : 0);
      const length = match[0].length + match.index! - index;
      insertIntoSorted(wordsFoundInLine, { index, length }, 'index');
    }
  }
  const foundFragments: DocumentExceprt[] = [];
  for (let i = 0; i < wordsFoundInLine.length; i++) {
    let startsWithSpace = false;
    const infixedWords: { index: number; length: number }[] = [];
    const word = wordsFoundInLine[i];
    infixedWords.push(word);
    const entirePrefix = rawLine.substring(0, word.index);
    const possiblePrecedingMatcher = /((.|^)([^ ]* ){0,3}| ([^ ]* ){3})$/;
    let includedPrefix = possiblePrecedingMatcher.exec(entirePrefix)![0];
    if (includedPrefix.startsWith(' ')) {
      startsWithSpace = true;
      includedPrefix = includedPrefix.substring(1);
    } else if (includedPrefix.startsWith('.')) {
      includedPrefix = includedPrefix.substring(1);
    }
    let includedSuffix = rawLine.substring(word.index + word.length);
    const possibleSucceedingMatcher = /^[^ .]*( [^ .]*){0,8}\.?/;
    const possiblyIncludedSuffix = possibleSucceedingMatcher.exec(
      includedSuffix
    )![0];
    let endIndex =
      infixedWords[infixedWords.length - 1].length +
      infixedWords[infixedWords.length - 1].index +
      possiblyIncludedSuffix.length;
    while (
      wordsFoundInLine.length > i + 1 &&
      wordsFoundInLine[i + 1].index <= endIndex
    ) {
      const [word] = wordsFoundInLine.splice(i + 1, 1);
      infixedWords.push(word);
      const infix = rawLine.substring(
        infixedWords[0].index,
        word.index + word.length
      );
      const suffix = rawLine.substring(word.index + word.length);
      const spacesSoFar = countSpaces(includedPrefix + infix);
      const spacesRemaining =
        MAXIMUM_FRAGMENT_LENGTH - spacesSoFar >
        MAXIMUM_FRAGMENT_WORDS_AFTER + MAXIMUM_FRAGMENT_WORDS_PRECEDING
          ? MAXIMUM_FRAGMENT_WORDS_AFTER + MAXIMUM_FRAGMENT_WORDS_PRECEDING
          : MAXIMUM_FRAGMENT_LENGTH - spacesSoFar;
      if (spacesRemaining <= 0) {
        break;
      }
      const availableSuffix = new RegExp(
        '^[^ .]*( [^ .]*){0,' + spacesRemaining + '}\\.?',
        'gm'
      );
      const suffixmatch = suffix.match(availableSuffix)![0];
      endIndex =
        infixedWords[infixedWords.length - 1].length +
        infixedWords[infixedWords.length - 1].index +
        suffixmatch.length;
    }
    // now we don't have any more words we can include;
    includedSuffix = rawLine.substring(
      infixedWords[infixedWords.length - 1].index +
        infixedWords[infixedWords.length - 1].length
    );
    const finalSuffixMatcher = /^[^ .]*( [^ .]*){0,5}\.?/;
    includedSuffix = finalSuffixMatcher.exec(includedSuffix)![0];

    const startIndex = infixedWords[0].index - includedPrefix.length;
    endIndex =
      infixedWords[infixedWords.length - 1].index +
      infixedWords[infixedWords.length - 1].length +
      includedSuffix.length;

    foundFragments.push({
      fragments: highlightText(
        rawLine,
        infixedWords,
        startsWithSpace,
        startIndex,
        endIndex
      ),
      ix: fragmentIx++,
      goodness: startIndex - endIndex,
    });
  }
  return foundFragments;
}

function generateExcerpts(
  document: { id: string; weight: number; documentBody?: string },
  fullWords: string[],
  prefixWord: string | undefined
) {
  const documentBody = document.documentBody!;
  const { rawLines, searchableLines } = getRawContents(documentBody);

  const fullWordMatchers = fullWords.map(w => {
    return {
      regex: new RegExp('(?:^| )' + w.replace('\uffff', ' ') + '(?=$| )', 'g'),
      length: w.length,
    };
  });
  if (prefixWord) {
    fullWordMatchers.push({
      regex: new RegExp('(?:^| )' + prefixWord, 'g'),
      length: prefixWord.length,
    });
  }
  fullWordMatchers.sort((a, b) => compareSortables(a.length, b.length));

  // const fragmentix = 0;
  const foundFragments: DocumentExceprt[] = [];
  for (let i = 0; i < searchableLines.length; i++) {
    foundFragments.push(
      ...generateExceptsFromLine(
        rawLines[i],
        searchableLines[i],
        fullWordMatchers.map(f => f.regex)
      )
    );
  }
  foundFragments.sort((a, b) => compareSortables(a.goodness, b.goodness));
  if (foundFragments.length > MAXIMUM_FRAGMENT_PIECES) {
    foundFragments.splice(MAXIMUM_FRAGMENT_PIECES);
  }
  return foundFragments;
}

function highlightFullText(
  rawLine: string,
  fullWords: string[],
  prefixWord: string | undefined
) {
  const fullWordMatchers = fullWords.map(w => {
    return {
      regex: new RegExp('(?:^| )' + w.replace('\uffff', ' ') + '(?=$| )', 'g'),
      length: w.length,
    };
  });
  if (prefixWord) {
    fullWordMatchers.push({
      regex: new RegExp('(?:^| )' + prefixWord, 'g'),
      length: prefixWord.length,
    });
  }
  fullWordMatchers.sort((a, b) => compareSortables(a.length, b.length));

  const searchableLine = searchifyText(rawLine);
  const wordsFoundInLine: { index: number; length: number }[] = [];
  for (const matcher of fullWordMatchers) {
    for (const match of searchableLine.matchAll(matcher.regex)) {
      const index = match.index! + (match[0].startsWith(' ') ? 1 : 0);
      const length = match[0].length + match.index! - index;
      insertIntoSorted(wordsFoundInLine, { index, length }, 'index');
    }
  }
  return highlightText(rawLine, wordsFoundInLine);
}

const enqueueOp: <T>(fn: () => PromiseLike<T> | T) => Promise<T> = (() => {
  let originalOp = Promise.resolve();
  return <T>(fn: () => PromiseLike<T> | T) => {
    const opWas = originalOp;
    const result = opWas.then(() => fn());
    originalOp = new Promise(
      resolve =>
        void result.finally(() => {
          resolve();
        })
    );
    return result;
  };
})();

interface SearchParameters {
  term: string;
  section?: 'ALS' | 'BLS';
  count?: number;
}

class SearchStore implements Module<SearchState, any> {
  namespaced = true;
  state: SearchState = {
    rawSearch: '',
    search: '',
    isSearching: false,
    fullWordResults: {},
    partialWordMatches: null,
    results: null,
    count: 0,
    section: undefined,
  };

  actions = {
    async primeSearch() {
      await query(datastore, { limit: 0, stale: 'update_after' });
    },
    async setSearch(
      context: ActionContext<SearchState, RootState>,
      payload: ActionPayload<string | SearchParameters>
    ) {
      const term =
        typeof payload.payload === 'string'
          ? payload.payload
          : payload.payload.term;
      const count =
        typeof payload.payload === 'string'
          ? MAXIMUM_DOCUMENT_RESULTS
          : payload.payload.count || MAXIMUM_DOCUMENT_RESULTS;
      const section =
        typeof payload.payload === 'string'
          ? undefined
          : payload.payload.section;
      const maxFullMatches = count;
      const maxFullPartials =
        count * 5 < MAX_FULL_PARTIALS ? MAX_FULL_PARTIALS : count * 5;
      let words = term
        .replace(/[^a-zA-Z0-9-]/g, ' ')
        .toLowerCase()
        .split(/\s+/)
        .map(f => (f.length < 2 || commonWordsSet.has(f) ? '' : f));
      words = words.filter((f, i) => i == words.length - 1 || !!f);
      const canonicalSearch = words.join(' ');

      const isNewSearch =
        context.state.rawSearch != term || context.state.section != section;

      context.commit({
        type: 'setSearch',
        payload: {
          raw: term,
          canonical: canonicalSearch,
          section,
          count,
        },
      });

      if (context.state.isSearching) {
        return;
      }
      context.commit({
        type: 'startSearching',
        payload: isNewSearch,
      });
      while (
        !context.state.results ||
        context.state.results.search != context.state.search ||
        context.state.results.count != context.state.count ||
        context.state.results.section != context.state.section
      ) {
        // while we're here, prime the pump to prepare for a search. Building the index
        // can be intensive the first time.
        void context.dispatch({
          type: 'primeSearch',
        });
        let searchstring = context.state.search;
        let section = context.state.section;

        while (true) {
          await new Promise<void>(r => {
            window.setTimeout(() => {
              r();
            }, 500);
          });
          // if the search hasn't changed for 500 ms. some keyboard time...
          if (
            searchstring == context.state.search &&
            section == context.state.section
          ) {
            break;
          }
          searchstring = context.state.search;
          section = context.state.section;
        }

        const endIsPartialWord = !searchstring.endsWith(' ');
        if (!searchstring.trimRight()) {
          context.commit('stopSearching');
          return;
        }
        const searchWords = searchstring.trimRight().split(' ');
        const endWord = endIsPartialWord ? searchWords.pop()! : undefined;
        const endBigraph =
          searchWords.length && endWord
            ? `${searchWords[searchWords.length - 1]}\uFFFF${endWord}`
            : undefined;
        const bigraphs: string[] = [];
        for (let i = 1; i < searchWords.length; i++) {
          bigraphs.push(`${searchWords[i - 1]}\uFFFF${searchWords[i]}`);
        }
        const fullWords: { word: string; results: [WordKey, string][] }[] = [];
        searchWords.push(...bigraphs);
        for (let i = 0; i < searchWords.length; i++) {
          // const stale: 'ok' | 'update_after' =
          //   !endWord && i == searchWords.length - 1 ? 'update_after' : 'ok';
          const word = searchWords[i];
          if (word in context.state.fullWordResults) {
            fullWords.push({
              word,
              results: context.state.fullWordResults[word],
            });
          }
          const results = await query(datastore, {
            startkey: [word],
            endkey: [word, 0],
            limit: maxFullMatches,
            section,
          });
          fullWords.push({
            word,
            results: results.rows.map<[WordKey, string]>(k => [
              k.key as WordKey,
              k.id,
            ]),
          });
        }
        let partialWordResult:
          | { results: [WordKey, string][]; overflow: boolean }
          | undefined;
        if (endWord) {
          const wordMatches =
            context.state.partialWordMatches &&
            endWord.startsWith(context.state.partialWordMatches.word);
          let results: [WordKey, string][];
          let remaining = maxFullPartials;
          let overflow = false;
          if (wordMatches) {
            results = context.state.partialWordMatches!.matches.filter(
              ([[word]]) => word.startsWith(endWord)
            );
            remaining = context.state.partialWordMatches!.overflow
              ? maxFullPartials - results.length
              : 0;
          } else {
            results = [];
          }
          if (remaining) {
            const startkey: [string] =
              results.length > 0
                ? (results[results.length - 1].map(v => v[0]) as [string])
                : [endWord];
            const skip = results.length > 0 ? 1 : 0;
            const singleWordResults = await query(datastore, {
              startkey,
              endkey: [endWord + '\uFFFF'],
              limit: remaining,
              skip,
              section,
            });

            results.push(
              ...singleWordResults.rows.map<[WordKey, string]>(k => [
                k.key as WordKey,
                k.id,
              ])
            );
            results.filter(w => !w[0].includes('\uffff'));
            overflow = results.length == maxFullPartials;
          }

          if (endBigraph) {
            let remaining = maxFullPartials;
            const bigramMatches =
              context.state.partialWordMatches &&
              context.state.partialWordMatches.bigraph &&
              endBigraph.startsWith(context.state.partialWordMatches.bigraph);
            let bigramResults: [WordKey, string][];
            if (bigramMatches) {
              bigramResults = context.state.partialWordMatches!.matches.filter(
                ([[word]]) => word.startsWith(endBigraph)
              );
              remaining = context.state.partialWordMatches!.overflow
                ? maxFullPartials - bigramResults.length
                : 0;
            } else {
              bigramResults = [];
            }
            if (remaining) {
              const startkey: [string] =
                bigramResults.length > 0
                  ? (bigramResults[endBigraph.length - 1].map(v => v[0]) as [
                      string
                    ])
                  : [endBigraph];
              const skip = bigramResults.length > 0 ? 1 : 0;
              const bigramWueryResultsResults = await query(datastore, {
                startkey,
                endkey: [endBigraph + '\uFFFF'],
                limit: remaining,
                skip,
                section,
              });
              bigramResults.push(
                ...bigramWueryResultsResults.rows.map<[WordKey, string]>(k => [
                  k.key as WordKey,
                  k.id,
                ])
              );
            }
            overflow = overflow || bigramResults.length == maxFullPartials;
            results.push(...bigramResults);
          }

          partialWordResult = { results, overflow };
        }

        const documentGoodness = new Map<string, number>();
        for (const word of fullWords) {
          for (const [[, weight], id] of word.results) {
            documentGoodness.set(id, (documentGoodness.get(id) || 0) + weight);
          }
        }
        if (partialWordResult) {
          for (const [[word, weight], id] of partialWordResult.results) {
            // unweight the words. we should just
            const adjustedWeight =
              word.length > 7 ? weight / Math.sqrt(word.length - 7) : weight;
            documentGoodness.set(
              id,
              (documentGoodness.get(id) || 0) + adjustedWeight
            );
          }
        }

        const results: {
          id: string;
          weight: number;
          documentBody?: string;
          section?: 'ALS' | 'BLS';
        }[] = [];

        for (const [id, weight] of documentGoodness.entries()) {
          insertIntoSorted(
            results,
            { id, weight },
            (
              a: { id: string; weight: number },
              b: { id: string; weight: number }
            ) => {
              let r;
              if (((r = compareSortables(a.weight, b.weight)), r != 0)) {
                return r;
              }
              return compareSortables(a.id, b.id);
            }
          );
        }
        if (results.length > count) {
          results.splice(count);
        }
        const documentBodies: {
          [name: string]: {
            documentBody: string;
            section: 'ALS' | 'BLS' | undefined;
          };
        } = {};
        for (const result of results) {
          if (
            context.state.results &&
            result.id in context.state.results.documentBodies
          ) {
            const {
              documentBody,
              section,
            } = context.state.results.documentBodies[result.id];
            result.documentBody = documentBody;
            result.section = section;
          } else {
            const doc = await datastore.get(result.id);
            if (doc.type == 'article') {
              result.documentBody = doc.documentBody || '';
            }
          }
          documentBodies[result.id] = {
            documentBody: result.documentBody!,
            section: result.section!,
          };
        }

        const documents: SearchResult[] = [];
        for (const result of results) {
          const excerpts = generateExcerpts(result, searchWords, endWord);
          documents.push({
            section: result.section!,
            documentBody: result.documentBody!,
            documentName: result.id.substring(8),
            documentNameFragments: highlightFullText(
              result.id.substring(8),
              searchWords,
              endWord
            ),
            excerpts,
            matchScore: result.weight * -1,
          });
        }

        const stepResults: SearchStep = {
          results: {
            documents,
            documentBodies,
            search: searchstring,
            section: section,
            count,
          },
          fullWordResults: {},
          partialWordMatches: null,
        };
        for (const word of fullWords) {
          stepResults.fullWordResults[word.word] = word.results;
        }

        if (partialWordResult) {
          stepResults.partialWordMatches = {
            word: endWord!,
            bigraph: endBigraph,
            matches: partialWordResult.results,
            overflow: partialWordResult.overflow,
          };
        }
        context.commit({
          type: 'searchStep',
          payload: stepResults,
        });
      }
      context.commit('searchCompleted');
    },
  };

  mutations = {
    setSearch(
      state: SearchState,
      arg: ActionPayload<{
        raw: string;
        canonical: string;
        count: number;
        section?: 'ALS' | 'BLS';
      }>
    ) {
      state.rawSearch = arg.payload.raw;
      state.search = arg.payload.canonical;
      state.count = arg.payload.count;
      if (state.section != arg.payload.section) {
        state.partialWordMatches = null;
        state.section = arg.payload.section;
      }
    },
    searchStep(state: SearchState, arg: ActionPayload<SearchStep>) {
      state.results = arg.payload.results;
      state.partialWordMatches = arg.payload.partialWordMatches;
      state.fullWordResults = arg.payload.fullWordResults;
    },
    startSearching(state: SearchState, arg: ActionPayload<boolean>) {
      state.isSearching = true;
      if (arg.payload) {
        state.results = null;
      }
    },
    stopSearching(state: SearchState) {
      state.isSearching = false;
      state.results = null;
      state.partialWordMatches = null;
      state.fullWordResults = {};
    },
    searchCompleted(state: SearchState) {
      state.isSearching = false;
    },
  };
}
const search = new SearchStore();
export default search;
