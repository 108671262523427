import cloneDeep from 'lodash/cloneDeep';
import { appInsights } from '../../init';
import type { ActionContext, Module } from 'vuex';
import type { ActionPayload, RootState } from '..';
import ajax from '../../lib/ajax';
import { base64Encode } from '../../lib/base64';
import type { EditorSelection } from '../../lib/editor-funcs';
import { getSelectionContents } from '../../lib/editor-funcs';
import { compareArrays } from '../../lib/insert-sorted';
import { collectImages } from '../../lib/markdownImageCollector';
import { marked, parseHTML } from '../../lib/marked/marked';
import util from '../../lib/util';
import type { ArticleListState, ArticleSummary } from './article-list';

interface AddLinkState {
  source: 'document' | 'url';
  url: string;
  linkText: string;
}

interface AddImageState {
  source: 'upload' | 'url';
  url: string;
  content: Uint8Array | null;
  contentType: string;
  altText: string;
}

interface EditMetadataState {
  keywords: string;
  hierarchyParent: string[] | null;
  hierarchyWeight: string | null;
  isPublic: boolean;
  documentName: string;
  protocolSection: 'ALS' | 'BLS';
  published: boolean;
}

interface ChangeDocumentName {
  documentNameWas?: string;
}

export function articlesAreSame(
  a: ArticleSummary | null,
  b: ArticleSummary | null
): boolean {
  if (!a && !b) {
    return true;
  }
  if (!a) {
    return false;
  }
  if (!b) {
    return false;
  }
  if ((a.deleted || false) != (b.deleted || false)) {
    return false;
  }
  if (a.documentBody != b.documentBody) {
    return false;
  }
  if (a.documentName != b.documentName) {
    return false;
  }

  if (a.protocolSection != b.protocolSection) {
    return false;
  }
  if (
    a.hierarchyParent != null &&
    b.hierarchyParent != null &&
    compareArrays(a.hierarchyParent, b.hierarchyParent) != 0
  ) {
    return false;
  }
  if (a.hierarchyParent == null && b.hierarchyParent != null) {
    return false;
  }
  if (a.hierarchyParent != null && b.hierarchyParent == null) {
    return false;
  }
  if (a.isPublic != b.isPublic) {
    return false;
  }

  if (a.hierarchyWeight != b.hierarchyWeight) {
    return false;
  }
  if (compareArrays(a.keywords, b.keywords) != 0) {
    return false;
  }
  return true;
}

export interface CurrentArticleState {
  currentEditing: (ArticleSummary & ChangeDocumentName) | null;
  currentEditingSrc: ArticleSummary | null;
  currentRemote: ArticleSummary | null;
  hasChangedOnline: boolean;
  isSaving: boolean;
  exists: boolean;
  addLinkState: AddLinkState;
  addImageState: AddImageState;
  editMetadataState: EditMetadataState;
  currentSelection: EditorSelection | null;
  published: boolean;
}

class ArticleStore implements Module<CurrentArticleState, any> {
  namespaced = true;
  state: CurrentArticleState = {
    hasChangedOnline: false,
    isSaving: false,
    exists: false,
    currentEditing: null,
    currentEditingSrc: null,

    currentRemote: null,
    addImageState: {
      source: 'upload',
      url: '',
      content: null,
      contentType: '',
      altText: '',
    },
    addLinkState: {
      source: 'document',
      url: '',
      linkText: '',
    },
    editMetadataState: {
      keywords: '',
      hierarchyParent: null,
      hierarchyWeight: null,
      isPublic: false,
      documentName: '',
      published: false,
      protocolSection: 'ALS',
    },
    currentSelection: null,
    published: false,
  };

  actions = {
    setModal(
      context: ActionContext<CurrentArticleState, any>,
      arg: ActionPayload<{
        modal: 'link' | 'image' | 'article-info' | null;
        selection?: EditorSelection;
      }>
    ) {
      context.commit(
        {
          type: 'app/openModal',
          payload: arg.payload.modal,
        },
        { root: true }
      );

      context.commit('resetModalState');
      if (typeof arg.payload.selection !== 'undefined') {
        context.commit({
          type: 'setSelection',
          payload: arg.payload.selection,
        });
      }
    },
    async commitMetadata(
      context: ActionContext<CurrentArticleState, RootState>
    ) {
      context.commit({
        type: 'commitMetadata',
        payload: context.rootState.articleList,
      });
      context.dispatch({
        type: 'setModal',
        payload: {
          modal: null,
          selection: null,
        },
      });
    },
    async commitImage(context: ActionContext<CurrentArticleState, any>) {
      let url = context.state.addImageState.url;
      try {
        if (context.state.addImageState.source == 'upload') {
          if (!context.state.addImageState.content) {
            return;
          }
          const data = {
            fileType: context.state.addImageState.contentType,
            contents: base64Encode(context.state.addImageState.content),
          };
          const resp = await ajax.post(
            '/api/services/app/Attachment/Create',
            data
          );
          const id: string = resp.data.result.id;

          void context.dispatch(
            {
              type: 'articleList/startLoadDocuments',
            },
            { root: true }
          );
          url = `attachment:${id}`;
        }
        if (context.state.currentSelection) {
          const { prefix, suffix } = getSelectionContents(
            context.state.currentSelection,
            context.state.currentEditing!.documentBody || ''
          );
          const selection = `![${context.state.addImageState.altText}](${url} "${context.state.addImageState.altText}" =)`;
          const replacement = prefix + selection + suffix;
          context.commit({
            type: 'setContent',
            payload: replacement,
          });
        }
      } catch {}

      await context.dispatch({
        type: 'setModal',
        payload: {
          modal: null,
          selection: null,
        },
      });
    },
    async commitLink(context: ActionContext<CurrentArticleState, any>) {
      const url = context.state.addLinkState.url;
      try {
        if (context.state.currentSelection) {
          const { prefix, suffix } = getSelectionContents(
            context.state.currentSelection,
            context.state.currentEditing!.documentBody || ''
          );
          const selection = `[${context.state.addLinkState.linkText}](${url})`;
          const replacement = prefix + selection + suffix;
          context.commit({
            type: 'setContent',
            payload: replacement,
          });
        }
      } catch {}

      await context.dispatch({
        type: 'setModal',
        payload: {
          modal: null,
          selection: null,
        },
      });
    },
    async save(
      context: ActionContext<CurrentArticleState, any>,
      args?: ActionPayload<undefined | { publish: true }>
    ) {
      if (!context.state.currentEditing) {
        return;
      }
      const published = !!(args && args.payload && args.payload.publish);
      let replacedBy: string | null = null;
      const { documentNameWas } = context.state.currentEditing;
      let replaces =
        context.state.currentEditing.replaces || documentNameWas || null;
      if (!published) {
        // If we renamed the document since the last save, then there are 1
        // or 2 documents that we might ALSO need to update
        replacedBy = context.state.currentEditing.documentName;
        if (replacedBy == replaces) {
          replacedBy = null;
          replaces = null;
        }
      }
      // If we renamed the document, then we have to add / update the existing tombstone
      // for the document we're replacing.
      if (replaces) {
        await context.dispatch(
          {
            type: 'articleList/deleteDocument',
            payload: {
              id: replaces,
              replacedBy,
            },
          },
          { root: true }
        );
      }
      // If there was another intermediated edit, then we have to delete that as well.
      if (documentNameWas && documentNameWas != replaces) {
        await context.dispatch(
          {
            type: 'articleList/deleteDocument',
            payload: {
              id: documentNameWas,
              replacedBy: null,
            },
          },
          { root: true }
        );
      }
      context.state.currentEditing.deleted = false;
      context.commit('startSaving');
      const interpreted = parseHTML(
        marked(context.state.currentEditing.documentBody || '') as string
      );
      const images = collectImages(interpreted);
      context.state.currentEditing.images = images;
      const url = '/api/services/app/Document/Update';
      try {
        await ajax.put(
          url,
          {
            ...context.state.currentEditing,
            published,
            replaces,
            replacedBy: null,
          },
          {
            headers: {
              'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
            },
          }
        );
      } catch {
        context.commit('cancelSaving');
      }
      return context.dispatch(
        {
          type: 'articleList/startLoadDocuments',
        },
        { root: true }
      );
    },
  };

  mutations = {
    setKeywords(state: CurrentArticleState, args: ActionPayload<string>) {
      state.editMetadataState.keywords = args.payload;
    },
    setIsPublic(state: CurrentArticleState, args: ActionPayload<boolean>) {
      state.editMetadataState.isPublic = args.payload;
      if (!args.payload) {
        state.editMetadataState.hierarchyWeight = null;
        state.editMetadataState.hierarchyParent = null;
      }
    },
    setDocumentName(state: CurrentArticleState, args: ActionPayload<string>) {
      state.editMetadataState.documentName = args.payload;
    },
    setProtocolSection(
      state: CurrentArticleState,
      args: ActionPayload<'ALS' | 'BLS'>
    ) {
      state.editMetadataState.protocolSection = args.payload;
    },
    setHierarchyWeight(
      state: CurrentArticleState,
      args: ActionPayload<string>
    ) {
      state.editMetadataState.hierarchyWeight = args.payload;
    },
    setHierarchyParent(
      state: CurrentArticleState,
      args: ActionPayload<string[]>
    ) {
      state.editMetadataState.hierarchyParent = args.payload;
    },
    setImageAltText(state: CurrentArticleState, args: ActionPayload<string>) {
      state.addImageState.altText = args.payload;
    },
    setImageUrl(state: CurrentArticleState, args: ActionPayload<string>) {
      state.addImageState.url = args.payload;
    },
    setImageSource(
      state: CurrentArticleState,
      args: ActionPayload<'upload' | 'url'>
    ) {
      state.addImageState.source = args.payload;
    },
    setImageContent(
      state: CurrentArticleState,
      args: ActionPayload<{ content: Uint8Array | null; contentType: string }>
    ) {
      state.addImageState.content = args.payload.content;
      state.addImageState.contentType = args.payload.contentType;
    },
    setLinkSource(
      state: CurrentArticleState,
      args: ActionPayload<'document' | 'url'>
    ) {
      state.addLinkState.source = args.payload;
    },
    setLinkUrl(state: CurrentArticleState, args: ActionPayload<string>) {
      state.addLinkState.url = args.payload;
    },
    setLinkLinkText(state: CurrentArticleState, args: ActionPayload<string>) {
      state.addLinkState.linkText = args.payload;
    },
    commitMetadata(
      state: CurrentArticleState,
      args: ActionPayload<ArticleListState>
    ) {
      if (!state.currentEditing) {
        return;
      }
      if (
        state.editMetadataState.documentName !=
        state.currentEditing.documentName
      ) {
        const documentBody = state.currentEditing.documentBody;
        const doc =
          args.payload.draftDocumentIndices[
            state.editMetadataState.documentName
          ];
        // First of all, if there's already a document named this bail out.
        // Now treat this as a whole new document
        const docBody: ArticleSummary =
          typeof doc !== 'undefined'
            ? args.payload.draftDocuments[doc]
            : {
                type: 'article',
                documentName: state.editMetadataState.documentName,
                revisionCount: 0,
                modificationTime: '1900-01-01T00:00:00',
                keywords: [],
                documentBody: '',
                hierarchyParent: null,
                hierarchyWeight: null,
                isPublic: false,
                modificationUserName: '',
                images: [],
                published: false,
                protocolSection: 'ALS',
                isCurrent: false,
                replaces: null,
                replacedBy: null,
              };
        state.currentEditing = cloneDeep({
          ...docBody,
          documentBody,
          documentNameWas:
            state.currentEditing.documentNameWas ||
            state.currentEditing.documentName,
        });
        state.currentEditingSrc = cloneDeep({
          ...docBody,
          documentBody,
        });
        state.hasChangedOnline = false;
        if (
          state.currentEditing.documentNameWas ==
          state.currentEditing.documentName
        ) {
          state.currentEditing.documentNameWas = undefined;
        }
        if (!state.currentEditing.deleted) {
          state.currentEditing.replacedBy = null;
        }
        if (!state.currentEditing.published) {
          state.currentEditing.replaces = null;
        }
      }

      state.currentEditing.keywords = state.editMetadataState.keywords
        .split(/\s+/g)
        .filter(v => !!v);
      state.currentEditing.protocolSection =
        state.editMetadataState.protocolSection;
      state.currentEditing.hierarchyParent = state.editMetadataState
        .hierarchyParent
        ? [...state.editMetadataState.hierarchyParent]
        : null;
      state.currentEditing.hierarchyWeight =
        state.editMetadataState.hierarchyWeight;
      state.currentEditing.isPublic = state.editMetadataState.isPublic;
    },
    resetModalState(state: CurrentArticleState) {
      (state.addImageState = {
        source: 'upload',
        url: '',
        content: null,
        contentType: '',
        altText: '',
      }),
        (state.addLinkState = {
          source: 'document',
          url: '',
          linkText: '',
        });

      state.editMetadataState = state.currentEditing
        ? {
            keywords: state.currentEditing.keywords.join(' '),
            hierarchyParent: state.currentEditing.hierarchyParent
              ? [...state.currentEditing.hierarchyParent]
              : null,
            hierarchyWeight: state.currentEditing.hierarchyWeight,
            isPublic: state.currentEditing.isPublic,
            documentName: state.currentEditing.documentName,
            published: false,
            protocolSection: state.currentEditing.protocolSection,
          }
        : {
            keywords: '',
            hierarchyParent: null,
            hierarchyWeight: null,
            isPublic: false,
            documentName: '',
            published: false,
            protocolSection: 'ALS',
          };
    },
    setSelection(
      state: CurrentArticleState,
      args: ActionPayload<EditorSelection | null>
    ) {
      state.currentSelection = args.payload;
      if (args.payload) {
        const { selection } = getSelectionContents(
          args.payload,
          state.currentEditing!.documentBody || ''
        );
        state.addImageState.altText = selection;
        state.addLinkState.linkText = selection;
      }
    },
    startSaving(state: CurrentArticleState) {
      state.isSaving = true;
    },
    cancelSaving(state: CurrentArticleState) {
      state.isSaving = false;
    },
    setContent(state: CurrentArticleState, payload: ActionPayload<string>) {
      if (!state.currentEditing) {
        return;
      }
      state.currentEditing = {
        ...state.currentEditing,
        documentBody: payload.payload,
      };
      state.currentEditing.published = false;
      state.published = false;
    },
    selectDocument(
      state: CurrentArticleState,
      payload: ActionPayload<{
        doc: ArticleSummary;
        exists: boolean;
        select: 'published' | 'draft';
      }>
    ) {
      appInsights.stopTrackEvent(
        `page loading:${payload.payload.doc.documentName}`
      );
      if (
        state.currentEditing &&
        state.currentEditing.documentName == payload.payload.doc.documentName &&
        ((payload.payload.select == 'draft' &&
          state.currentEditing.isCurrent) ||
          (payload.payload.select == 'published' &&
            state.currentEditing.published))
      ) {
        return; // We already have this loaded. This will happen if we rename the document.
      }

      state.currentEditing = cloneDeep(payload.payload.doc);
      state.currentEditing.documentNameWas = undefined;
      if (!state.currentEditing.deleted) {
        state.currentEditing.replacedBy = null;
      }
      state.currentRemote = cloneDeep(payload.payload.doc);
      state.currentEditingSrc = cloneDeep(payload.payload.doc);
      state.exists = payload.payload.exists;
      state.hasChangedOnline = false;
      state.isSaving = false;
      state.published = state.currentEditing.published;
    },
    updateFromRemote(
      state: CurrentArticleState,
      args: ActionPayload<(ArticleSummary & PouchDB.Core.AllDocsMeta)[]>
    ) {
      if (!state.currentEditing) {
        return;
      }
      for (const article of args.payload) {
        if (article.documentName == state.currentEditing.documentName) {
          state.currentRemote = cloneDeep(article);
          state.exists = true;
          if (state.isSaving) {
            state.isSaving = false;
            state.currentEditing.documentNameWas = undefined;
            state.currentEditing = cloneDeep(article);
            state.currentEditingSrc = cloneDeep(article);
            state.hasChangedOnline = false;
            state.published = state.currentEditing.published;
          } else if (
            articlesAreSame(state.currentEditing, state.currentEditingSrc)
          ) {
            state.currentEditing = cloneDeep(article);
            state.currentEditingSrc = cloneDeep(article);
            state.hasChangedOnline = false;
          } else {
            state.hasChangedOnline = true;
          }
          break;
        }
      }
    },
  };
}

const article = new ArticleStore();
export default article;
