import { defaults, Options } from './defaults';
import { cleanUrl, escape } from './helpers';
import { ISlugger } from './Slugger';
import { ITextRenderer } from './TextRenderer';

export interface BlockRenderer<Out = string> extends ITextRenderer<Out> {
  setOptions(options: Options<Out>): void;
  code(code: string, infostring?: string, escaped?: boolean): Out;
  blockquote(quote: Out): Out;
  html(html: string, long: boolean): Out;
  heading(text: Out, level: number, raw: string, slugger: ISlugger): Out;
  list(
    body: Out,
    ordered: boolean,
    start: number | null,
    orderstyle: 'numeric' | 'roman' | 'lowercase' | 'uppercase' | undefined
  ): Out;
  listitem(text: Out, taskitem: boolean, checked?: boolean): Out;
  paragraph(text: Out): Out;
  tablecell(
    text: Out,
    flags: { header: boolean; align: 'left' | 'right' | 'center' | null }
  ): Out;
  tablerow(text: Out): Out;
  table(header: Out, body?: Out): Out;
  hr(): Out;
  checkbox(checked: boolean): Out;
  styleNextElement(
    classes?: string[],
    attributes?: [string, string][],
    id?: string
  ): void;
}

/**
 * Renderer
 */
export default class Renderer
  implements BlockRenderer<string>, ITextRenderer<string> {
  classes: string[] = [];
  attributes: [string, string][] = [];
  id: string | undefined;

  ofText(text: string, encode?: boolean | undefined): string {
    return escape(text, encode);
  }
  start(): string {
    return '';
  }
  cat(a: string, b: string): string {
    return a + b;
  }

  private options: Options<string>;
  constructor(options?: Partial<Options<string>>) {
    this.options = {
      ...defaults,
      ...(options || {}),
    };
  }

  setOptions(options: Options<string>) {
    this.options = options;
  }

  code(code: string, infostring?: string, escaped?: boolean) {
    const lang = (infostring || '').match(/\S*/)![0];
    if (this.options.highlight) {
      const out = this.options.highlight(code, lang);
      if (out != null && out !== code) {
        escaped = true;
        code = out;
      }
    }

    if (lang) {
      this.classes.push(this.options.langPrefix + lang);
    }
    return (
      '<pre><code ' +
      this.renderAttributes() +
      '>' +
      (escaped ? code : escape(code, true)) +
      '</code></pre>'
    );
  }

  blockquote(quote: string) {
    return (
      '<blockquote ' + this.renderAttributes() + '>' + quote + '</blockquote>'
    );
  }

  html(html: string) {
    return html;
  }

  heading(text: string, level: number, raw: string, slugger: ISlugger) {
    if (this.options.headerIds) {
      if (!this.id) {
        this.id = this.options.headerPrefix + slugger.slug(raw);
      }
    }
    // ignore IDs
    return (
      '<h' +
      level +
      ' ' +
      this.renderAttributes() +
      '>' +
      text +
      '</h' +
      level +
      '>'
    );
  }

  hr() {
    return this.options.xhtml
      ? '<hr ' + this.renderAttributes() + '/>'
      : '<hr ' + this.renderAttributes() + '>';
  }

  list(
    body: string,
    ordered: boolean,
    start: number | null,
    orderstyle: 'numeric' | 'roman' | 'lowercase' | 'uppercase' | undefined
  ) {
    const type = ordered ? 'ol' : 'ul',
      startatt = ordered && Number(start) !== 1 ? ' start="' + start + '"' : '';
    return (
      '<' +
      type +
      startatt +
      ' ' +
      this.renderAttributes() +
      (orderstyle ? ' olstyle="' + orderstyle + '" ' : '') +
      '>' +
      body +
      '</' +
      type +
      '>'
    );
  }

  listitem(text: string) {
    return '<li>' + text + '</li>';
  }

  checkbox(checked: boolean) {
    return (
      '<input ' +
      (checked ? 'checked="" ' : '') +
      'disabled="" type="checkbox"' +
      (this.options.xhtml ? ' /' : '') +
      '> '
    );
  }

  paragraph(text: string) {
    return '<p ' + this.renderAttributes() + '>' + text + '</p>';
  }

  table(header: string, body?: string) {
    if (body) body = '<tbody>' + body + '</tbody>';

    return (
      '<table ' +
      this.renderAttributes() +
      '>' +
      '<thead>' +
      header +
      '</thead>' +
      body +
      '</table>'
    );
  }

  tablerow(content: string) {
    return '<tr>' + content + '</tr>';
  }

  tablecell(
    content: string,
    flags: { header: boolean; align: 'left' | 'right' | 'center' | null }
  ) {
    const type = flags.header ? 'th' : 'td';
    const tag = flags.align
      ? '<' + type + ' align="' + flags.align + '">'
      : '<' + type + '>';
    return tag + content + '</' + type + '>';
  }

  // span level renderer
  strong(text: string) {
    return '<strong>' + text + '</strong>';
  }

  em(text: string) {
    return '<em>' + text + '</em>';
  }
  sub(text: string) {
    return '<sub>' + text + '</sub>';
  }
  sup(text: string) {
    return '<sup>' + text + '</sup>';
  }
  marked(text: string) {
    return '<mark>' + text + '</mark>';
  }

  codespan(text: string) {
    return '<code>' + text + '</code>';
  }

  br() {
    return this.options.xhtml ? '<br/>' : '<br>';
  }

  del(text: string) {
    return '<del>' + text + '</del>';
  }

  link(href: string, title: string | null, text: string) {
    const href2 = cleanUrl(
      !!this.options.sanitizer,
      this.options.baseUrl || '',
      href
    );
    if (href2 === null) {
      return text;
    }
    let out = '<a href="' + escape(href2) + '"';
    if (title) {
      out += ' title="' + title + '"';
    }
    out += '>' + text + '</a>';
    return out;
  }

  image(
    href: string,
    title: string | null,
    text: string,
    width: number | undefined,
    height: number | undefined
  ) {
    const href2 = cleanUrl(
      !!this.options.sanitizer,
      this.options.baseUrl || '',
      href
    );
    if (href2 === null) {
      return text;
    }

    let out = '<img src="' + href2 + '" alt="' + text + '"';
    if (title) {
      out += ' title="' + title + '"';
    }
    if (width) {
      out += ` width="${width}px"`;
    }
    if (height) {
      out += ` height="${height}px"`;
    }
    out += this.options.xhtml ? '/>' : '>';
    return out;
  }

  text(text: string) {
    return text;
  }
  styleNextElement(
    classes?: string[],
    attributes?: [string, string][],
    id?: string
  ) {
    this.classes = classes || [];
    this.attributes = attributes || [];
    this.id = id;
  }
  renderAttributes() {
    if (this.classes.length) {
      this.attributes.unshift(['class', this.classes.join(' ')]);
    }
    if (this.id) {
      this.attributes.push(['id', this.id]);
    }
    const foundSoFar = new Set<string>();
    for (let i = this.attributes.length - 1; i >= 0; i--) {
      if (foundSoFar.has(this.attributes[i][0])) {
        this.attributes.splice(i, 1);
      } else {
        foundSoFar.add(this.attributes[i][0]);
      }
    }
    const result = this.attributes
      .map(v => `${v[0]}="${escape(v[1])}"`)
      .join(' ');
    this.attributes = [];
    this.classes = [];
    this.id = undefined;
    return result;
  }
}

export class NullRenderer implements BlockRenderer<null> {
  setOptions(): void {
    //
  }
  code(): null {
    return null;
  }
  blockquote(): null {
    return null;
  }
  html(): null {
    return null;
  }
  heading(): null {
    return null;
  }
  list(): null {
    return null;
  }
  listitem(): null {
    return null;
  }
  paragraph(): null {
    return null;
  }
  tablecell(): null {
    return null;
  }
  tablerow(): null {
    return null;
  }
  table(): null {
    return null;
  }
  hr(): null {
    return null;
  }
  checkbox(): null {
    return null;
  }
  strong(): null {
    return null;
  }
  em(): null {
    return null;
  }
  codespan(): null {
    return null;
  }
  del(): null {
    return null;
  }
  text(): null {
    return null;
  }
  link(): null {
    return null;
  }
  image(): null {
    return null;
  }
  br(): null {
    return null;
  }
  ofText(): null {
    return null;
  }
  start(): null {
    return null;
  }
  cat(): null {
    return null;
  }
  sub() {
    return null;
  }
  sup() {
    return null;
  }
  marked() {
    return null;
  }
  styleNextElement() {}
}
