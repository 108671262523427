var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("header", [
    _c("div", { staticClass: "true-header" }, [
      _c(
        "div",
        { staticClass: "toolbar__tools" },
        [
          _c("div", { staticClass: "category" }, [
            _vm._v(_vm._s(_vm.longCategory)),
          ]),
          _c("v-spacer"),
          _c("div", { staticClass: "category" }, [
            _vm._v(_vm._s(_vm.shortCategory)),
          ]),
        ],
        1
      ),
      _vm.showLogo
        ? _c(
            "div",
            { staticClass: "toolbar__logo" },
            [
              _c("v-img", {
                attrs: {
                  src: "/img/icons/apple-touch-icon-120x120.png",
                  height: "56",
                  width: "56",
                  contain: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "toolbar__content" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ios",
              attrs: { icon: "", "aria-label": "go back" },
              on: { click: _vm.goBack },
            },
            [
              _c("v-icon", { attrs: { "x-large": "" } }, [
                _vm._v("mdi-arrow-left"),
              ]),
            ],
            1
          ),
          _c("v-spacer"),
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(_vm.pageTitle) + " "),
          ]),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: { "offset-y": "", "content-class": "caret-menu-content" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          { attrs: { icon: "", "aria-label": "adjust zoom" } },
                          on
                        ),
                        [_c("v-icon", [_vm._v("mdi-cog")])],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-menu-caret",
                { attrs: { right: "" } },
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-flex",
                                { staticClass: "hover" },
                                [
                                  _c("v-slider", {
                                    attrs: {
                                      value: _vm.zoom,
                                      step: "25",
                                      ticks: "always",
                                      "tick-size": "4",
                                      "hide-details": "",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.zoomChanged($event)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-list-item-action"),
                          _c("v-icon", [_vm._v("mdi-magnify-plus-outline")]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-group",
                        { attrs: { multiple: "" } },
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { active: _vm.showall },
                              on: {
                                change: function ($event) {
                                  return _vm.$emit(
                                    "update:showall",
                                    !_vm.showall
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(_vm.L("ShowAllSections"))),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-checkbox", {
                                    attrs: { "input-value": _vm.showall },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }