import { defaults, Options } from './defaults';
import { rtrim, splitCells } from './helpers';
import { block, BlockGrammar } from './rules';

export interface Fragment {
  role:
    | 'display' // the character is displayed
    | 'escape' // the character is an escape character (\)
    | 'passthrough' // the character is part of raw html
    | 'markup' // the character is markup
    | 'indent'
    | 'info'
    | 'blank'; // the character is a meaningless blank.
  infoRole?: string;
  tokenLocalIndex?: number;
  start: {
    column: number | 'end';
    row: number;
  };
  end: {
    column: number | 'end';
    row: number;
  };
  rawContent?: string;
  contentOffset?: number;
  contentLength?: number;
  inlineStyles?: string[];
  blockStyles?: string[];
}

interface LexerTokenBase<T extends string> {
  type: T;
  fragments: Fragment[];
}
interface ParagraphLexerToken extends LexerTokenBase<'paragraph'> {
  text: string;
}
interface SpaceLexerToken extends LexerTokenBase<'space'> {}
interface CodeLexerToken extends LexerTokenBase<'code'> {
  escaped?: boolean;
  codeBlockStyle?: 'indented';
  lang?: string;
  text: string;
}
interface HeadingLexerToken extends LexerTokenBase<'heading'> {
  depth: number;
  text: string;
}

export interface ContentWithFragments {
  content: string;
  fragmentIndexes: {
    from: number;
    to?: number;
    fragments?: Fragment[];
  };
}

interface TableLexerToken extends LexerTokenBase<'table'> {
  header: ContentWithFragments[];
  align: ('left' | 'right' | 'center' | null)[];
  cells: ContentWithFragments[][];
}
interface ThematicBreakLexerToken extends LexerTokenBase<'hr'> {}
interface BlockquoteStartLexerToken
  extends LexerTokenBase<'blockquote_start'> {}
interface BlockquoteEndLexerToken extends LexerTokenBase<'blockquote_end'> {}
interface ListStartLexerToken extends LexerTokenBase<'list_start'> {
  ordered: boolean;
  start: number | null;
  loose: boolean;
  orderstyle: 'numeric' | 'roman' | 'lowercase' | 'uppercase' | undefined;
}
interface ListItemStartLexerToken extends LexerTokenBase<'list_item_start'> {
  task: boolean;
  checked?: boolean;
  loose: boolean;
}
interface ListItemEndLexerToken extends LexerTokenBase<'list_item_end'> {}
interface ListEndLexerToken extends LexerTokenBase<'list_end'> {}
interface HtmlLexerToken extends LexerTokenBase<'html'> {
  text: string;
  pre: boolean;
}
export interface TextLexerToken extends LexerTokenBase<'text'> {
  text: string;
}
export interface AttributeLexerToken extends LexerTokenBase<'attr_start'> {
  classes: string[];
  id: string;
  attributes: [string, string][];
}
export interface AttributeEndToken extends LexerTokenBase<'attr_end'> {}

export type LexerToken =
  | ParagraphLexerToken
  | SpaceLexerToken
  | CodeLexerToken
  | HeadingLexerToken
  | TableLexerToken
  | ThematicBreakLexerToken
  | BlockquoteStartLexerToken
  | BlockquoteEndLexerToken
  | ListStartLexerToken
  | ListItemStartLexerToken
  | ListItemEndLexerToken
  | ListEndLexerToken
  | HtmlLexerToken
  | TextLexerToken
  | AttributeLexerToken
  | AttributeEndToken;

/**
 * Block Lexer
 */
export default class Lexer {
  options: Options<any>;
  tokens: LexerToken[];
  tokenLinks: {
    [key: string]: {
      href: string;
      title?: string;
    };
  } = {};
  rules: BlockGrammar;

  constructor(options?: Partial<Options<any>>) {
    this.tokens = [];
    this.options = {
      ...defaults,
      ...(options || {}),
    };
    this.rules = block.normal;

    if (this.options.type == 'pedantic') {
      this.rules = block.pedantic;
    } else if (this.options.type == 'gfm' || this.options.type == 'breaks') {
      this.rules = block.gfm;
    }
  }

  /**
   * Expose Block Rules
   */
  static get rules() {
    return block;
  }

  /**
   * Static Lex Method
   */
  static lex(src: string, options: Options<any>) {
    const lexer = new Lexer(options);
    const result = lexer.lex(src);
    return result;
  }

  /**
   * Preprocessing
   */
  lex(src: string) {
    src = src.replace(/\r\n|\r/g, '\n').replace(/\t/g, '    ');

    return this.token(src, true, true);
  }

  /**
   * Lexing
   */
  token(src: string, top: boolean, final?: boolean) {
    let lines = src.split('\n');
    // const blankLines = lines
    //   .map((text, index) => ({ text, index }))
    //   .filter(({ text }) => !text.trim())
    //   .map(({ index }) => index);

    src = src.replace(/^ +$/gm, '');

    lines = src.split('\n');
    const lineOffsets: number[] = [];
    let srcOffset = 0;
    for (const line of lines) {
      lineOffsets.push(srcOffset);
      srcOffset += line.length + 1;
    }

    const srcLength = src.length;
    const getSrcOffset = () => {
      return srcLength - src.length;
    };
    const posOfOffset = (offset: number) => {
      for (let i = 0; i < lineOffsets.length - 1; i++) {
        if (lineOffsets[i] <= offset && offset < lineOffsets[i + 1]) {
          return {
            column: offset - lineOffsets[i],
            row: i,
          };
        }
      }
      return {
        column: offset - lineOffsets[lineOffsets.length - 1],
        row: lineOffsets.length - 1,
      };
    };

    let cap: RegExpExecArray | null,
      bull: string,
      b: string,
      listStart: ListStartLexerToken,
      t: LexerToken,
      // space: number,
      i: number,
      tag: string,
      l: number,
      istask: boolean;

    while (src) {
      // newline
      if ((cap = this.rules.newline.exec(src))) {
        src = src.substring(cap[0].length);
        if (cap[0].length > 1) {
          const srcOffset = getSrcOffset();
          this.tokens.push({
            type: 'space',
            fragments: [
              {
                role: 'blank',
                start: posOfOffset(srcOffset - cap[0].length),
                end: posOfOffset(srcOffset),
              },
            ],
          });
        }
      }

      if ((cap = this.rules.attrs.exec(src))) {
        const contents = cap[3];
        const offsetfromend = cap[4].length;
        const offsetfrombeginning =
          cap[0].length - (offsetfromend + cap[2].length);
        let srcleft = src.substring(0, offsetfrombeginning);
        srcleft = srcleft.trimLeft();
        let finalmarkupstart = srcleft.length;
        const markuplength = cap[0].length - (cap[0].endsWith('\n') ? 1 : 0);

        let srcright = src.substring(
          cap[0].length - (cap[0].endsWith('\n') ? 1 : 0)
        );
        let innercontent = contents;
        const nexttoken = /(\.|#|\[)/;
        let innercap = nexttoken.exec(innercontent);
        const classes: string[] = [];
        let id: string | undefined;
        const attributes: [string, string][] = [];

        while (innercap) {
          innercontent = innercontent.substring(innercap.index);
          let nextcap = /(\.|#|\[| |$)/.exec(innercontent.substring(1))!;
          if (innercontent[0] == '[') {
            const close = innercontent.indexOf(']');
            if (close > 0) {
              const attributeName = innercontent.substring(1, close);
              if (innercontent[close + 1] == '=') {
                let quoteEndFinder =
                  innercontent[close + 2] == '"' ? /"|$/ : / |$/;
                let remainder =
                  innercontent[close + 2] == '"'
                    ? innercontent.substring(close + 3)
                    : innercontent.substring(close + 2);
                let endIndex = quoteEndFinder.exec(remainder)!;
                const value = remainder.substring(0, endIndex.index);
                attributes.push([attributeName, unescape(value)]);
                innercontent = remainder.substring(endIndex.index + 1);
              } else {
                attributes.push([attributeName, '']);
                innercontent = innercontent.substring(close + 1);
              }
            } else {
              innercontent = '';
            }
          } else if (innercontent[0] == '.') {
            const classname = innercontent.substring(1, nextcap.index + 1);
            innercontent = innercontent.substring(nextcap.index + 1);
            classes.push(classname);
          } else if (innercontent[0] == '#') {
            const classname = innercontent.substring(1, nextcap.index + 1);
            innercontent = innercontent.substring(nextcap.index + 1);
            id = classname;
          }
          innercontent = innercontent.trimLeft();
          innercap = nexttoken.exec(innercontent);
        }

        this.tokens.push({
          type: 'attr_start',
          classes,
          id: id as string,
          attributes,
          fragments: [
            {
              role: 'markup',

              start: posOfOffset(getSrcOffset() + finalmarkupstart),
              end: posOfOffset(
                getSrcOffset() + finalmarkupstart + markuplength
              ),
            },
          ],
        });
        src = srcleft + srcright;
        // this is kind of bad. everything will end up in the wrong order...
        continue;
      }

      // code
      if ((cap = this.rules.code.exec(src))) {
        const lastToken = this.tokens[this.tokens.length - 1];
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        // An indented code block cannot interrupt a paragraph.
        if (lastToken && lastToken.type === 'paragraph') {
          lastToken.text += '\n' + cap[0].trimRight();
          lastToken.fragments.push({
            role: 'display',
            start: posOfOffset(srcEnd - cap[0].length - 1),
            end: posOfOffset(srcEnd),
          });
        } else {
          const srcStart = srcEnd - cap[0].length;
          const fragments: Fragment[] = [];

          let rawCap = cap[0];
          let lastIndex = 0;
          let index = rawCap.search(/^ {4}/m);
          let delta = 0;
          let sourceIndex = 0;
          while (index >= 0) {
            if (lastIndex < index) {
              fragments.push({
                role: 'display',
                start: posOfOffset(srcStart + lastIndex + delta),
                end: posOfOffset(srcStart + index + delta),
                contentOffset: sourceIndex,
                contentLength: index - lastIndex,
              });
              sourceIndex += index - lastIndex;
            }
            fragments.push({
              role: 'indent',
              start: posOfOffset(srcStart + index + delta),
              end: posOfOffset(srcStart + index + 4 + delta),
            });
            delta += 4;
            rawCap = rawCap.substring(0, index) + rawCap.substring(index + 4);
            lastIndex = index;
            index = rawCap.search(/^ {4}/m);
          }
          const text =
            this.options.type !== 'pedantic' ? rtrim(rawCap, '\n') : rawCap;
          if (lastIndex < rawCap.length) {
            fragments.push({
              role: 'display',
              start: posOfOffset(srcStart + lastIndex + delta),
              end: posOfOffset(srcStart + rawCap.length + delta),
              contentOffset: sourceIndex,
              contentLength: text.length - sourceIndex,
            });
          }
          this.tokens.push({
            type: 'code',
            codeBlockStyle: 'indented',
            text,
            fragments,
          });
        }
        continue;
      }

      // fences
      if ((cap = this.rules.fences.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        const languageTagStart =
          cap[0].indexOf(cap[1]) +
          cap[1].length +
          (cap[2].length - cap[2].trimLeft().length);
        const textStart = languageTagStart + cap[2].trimLeft().length + 1;
        const endTagStart = textStart + cap[3].length;

        this.tokens.push({
          type: 'code',
          lang: cap[2] ? cap[2].trim() : cap[2],
          text: cap[3] || '',
          fragments: [
            {
              role: 'markup',
              start: posOfOffset(srcStart),
              end: posOfOffset(languageTagStart + srcStart),
            },
            {
              role: 'info',
              infoRole: 'language',
              start: posOfOffset(languageTagStart + srcStart),
              end: posOfOffset(textStart + srcStart),
            },
            {
              role: 'display',
              start: posOfOffset(textStart + srcStart),
              end: posOfOffset(endTagStart + srcStart),
            },
            {
              role: 'markup',
              start: posOfOffset(endTagStart + srcStart),
              end: posOfOffset(srcEnd),
            },
          ],
        });
        continue;
      }

      // heading
      if ((cap = this.rules.heading.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        const softTextStart = cap[0].indexOf(cap[1]) + cap[1].length;
        const suffix = cap[0].substring(softTextStart);
        const textStart =
          softTextStart + suffix.length - suffix.trimLeft().length;
        const textEnd = textStart + cap[2].length;

        this.tokens.push({
          type: 'heading',
          depth: cap[1].length,
          text: cap[2],
          fragments: [
            {
              role: 'markup',
              start: posOfOffset(srcStart),
              end: posOfOffset(textStart + srcStart),
            },
            {
              role: 'display',
              start: posOfOffset(textStart + srcStart),
              end: posOfOffset(textEnd + srcStart),
              contentLength: cap[2].length,
              contentOffset: 0,
            },
            {
              role: 'markup',
              start: posOfOffset(textEnd + srcStart),
              end: posOfOffset(srcEnd),
            },
          ],
        });
        continue;
      }

      // table no leading pipe (gfm)
      if ((cap = this.rules.nptable.exec(src))) {
        const srcStart = getSrcOffset();
        const rawalignment = cap[2].replace(/^ *|\| *$/g, '').split(/ *\| */);
        let headeroffset = /^ */.exec(cap[0])![0].length;
        const initialheaderlength = cap[1].length;
        let rawheaderstring = cap[1].replace(/^ */, '');
        headeroffset += initialheaderlength - rawheaderstring.length;

        const headerParseData = splitCells(cap[1].replace(/^ *| *\| *$/g, ''));
        const align: ('left' | 'right' | 'center' | null)[] = [];
        const rawCells = cap[3] ? cap[3].replace(/\n$/, '').split('\n') : [];

        const fragments: Fragment[] = [];
        const header: ContentWithFragments[] = [];

        let startindex = headeroffset * -1;
        for (const cell of headerParseData.cells) {
          if (typeof cell.index === 'undefined') {
            break;
          }
          let markupend = cell.index;
          if (markupend > startindex) {
            fragments.push({
              role: 'markup',
              tokenLocalIndex: headeroffset + startindex,
              start: posOfOffset(srcStart + headeroffset + startindex),
              end: posOfOffset(srcStart + headeroffset + markupend),
            });
            startindex = markupend;
          }
          const firstIndex = headeroffset + startindex;
          let contentindex = 0;
          for (const escape of cell.escapes) {
            fragments.push({
              role: 'display',
              tokenLocalIndex: headeroffset + startindex,
              start: posOfOffset(srcStart + headeroffset + startindex),
              end: posOfOffset(srcStart + headeroffset + cell.index + escape),
              contentOffset: contentindex,
              contentLength: cell.index + escape - startindex,
            });
            fragments.push({
              role: 'escape',
              tokenLocalIndex:
                headeroffset + headeroffset + cell.index + escape,
              start: posOfOffset(srcStart + headeroffset + cell.index + escape),
              end: posOfOffset(
                srcStart + headeroffset + cell.index + escape + 2
              ),
              contentOffset: contentindex + cell.index + escape - startindex,
              contentLength: 1,
            });
            (contentindex += cell.index + escape - startindex + 1),
              (startindex = cell.index + escape + 2);
          }
          const end = cell.index + cell.raw.length;
          fragments.push({
            role: 'display',
            tokenLocalIndex: headeroffset + startindex,
            start: posOfOffset(srcStart + headeroffset + startindex),
            end: posOfOffset(srcStart + headeroffset + end),
            contentOffset: contentindex,
            contentLength: end - startindex,
          });
          startindex = end;
          header.push({
            content: cell.parsed,
            fragmentIndexes: {
              from: firstIndex,
              to: headeroffset + end,
            },
          });
        }
        if (startindex < cap[1].length) {
          fragments.push({
            role: 'markup',
            tokenLocalIndex: headeroffset + startindex,
            start: posOfOffset(srcStart + headeroffset + startindex),
            end: posOfOffset(srcStart + headeroffset + rawheaderstring.length),
          });
        }

        const alignmentstart = rawheaderstring.length + headeroffset + 1;
        const rawalignmentindex = cap[0].indexOf(cap[2]);

        fragments.push({
          role: 'markup',
          tokenLocalIndex: alignmentstart,
          start: posOfOffset(srcStart + alignmentstart),
          end: posOfOffset(srcStart + rawalignmentindex + cap[2].length),
        });

        if (headerParseData.cells.length === rawalignment.length) {
          src = src.substring(cap[0].length);

          for (i = 0; i < rawalignment.length; i++) {
            if (/^ *-+: *$/.test(rawalignment[i])) {
              align.push('right');
            } else if (/^ *:-+: *$/.test(rawalignment[i])) {
              align.push('center');
            } else if (/^ *:-+ *$/.test(rawalignment[i])) {
              align.push('left');
            } else {
              align.push(null);
            }
          }

          const cells: ContentWithFragments[][] = [];
          let rowindex = rawalignmentindex + cap[2].length + 1;

          for (i = 0; i < rawCells.length; i++) {
            const row: ContentWithFragments[] = [];
            const rowdata = splitCells(
              rawCells[i],
              headerParseData.cells.length
            );
            let startindex = 0;

            for (const cell of rowdata.cells) {
              let contentindex = 0;
              if (typeof cell.index === 'undefined') {
                break;
              }
              let markupend = cell.index;
              if (markupend > startindex) {
                fragments.push({
                  role: 'markup',
                  tokenLocalIndex: rowindex + startindex,
                  start: posOfOffset(srcStart + rowindex + startindex),
                  end: posOfOffset(srcStart + rowindex + markupend),
                });
                startindex = markupend;
              }
              const firstIndex = rowindex + startindex;
              for (const escape of cell.escapes) {
                fragments.push({
                  role: 'display',
                  tokenLocalIndex: rowindex + startindex,
                  start: posOfOffset(srcStart + rowindex + startindex),
                  end: posOfOffset(srcStart + rowindex + cell.index + escape),
                  contentOffset: contentindex,
                  contentLength: cell.index + escape - startindex,
                });
                fragments.push({
                  role: 'escape',
                  tokenLocalIndex: rowindex + cell.index + escape,
                  start: posOfOffset(srcStart + rowindex + cell.index + escape),
                  end: posOfOffset(
                    srcStart + rowindex + cell.index + escape + 2
                  ),
                  contentOffset:
                    contentindex + cell.index + escape - startindex,
                  contentLength: 1,
                });
                contentindex += cell.index + escape - startindex + 1;
                startindex = cell.index + escape + 2;
              }
              const end = cell.index + cell.raw.length;
              fragments.push({
                role: 'display',
                tokenLocalIndex: rowindex + startindex,
                start: posOfOffset(srcStart + rowindex + startindex),
                end: posOfOffset(srcStart + rowindex + end),
                contentOffset: contentindex,
                contentLength: end - startindex,
              });
              startindex = end;
              row.push({
                content: cell.parsed,
                fragmentIndexes: {
                  from: firstIndex,
                  to: rowindex + end,
                },
              });
            }

            if (startindex < rawCells[i].length) {
              fragments.push({
                role: 'markup',
                tokenLocalIndex: rowindex + startindex,
                start: posOfOffset(srcStart + rowindex + startindex),
                end: posOfOffset(srcStart + rowindex + rawCells[i].length),
              });
            }

            cells.push(row);
            rowindex += rawCells[i].length + 1;
          }

          this.tokens.push({
            type: 'table',
            header,
            align,
            cells,
            fragments,
          });

          continue;
        }
      }

      // hr
      if ((cap = this.rules.hr.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;

        this.tokens.push({
          type: 'hr',
          fragments: [
            {
              role: 'markup',
              start: posOfOffset(srcStart),
              end: posOfOffset(srcEnd),
            },
          ],
        });
        continue;
      }

      // blockquote
      if ((cap = this.rules.blockquote.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;

        const start = posOfOffset(srcStart);
        this.tokens.push({
          type: 'blockquote_start',
          fragments: [],
        });

        const replacementstart = this.tokens.length;
        const startLine = start.row;
        const lineprefixes: number[] = [];
        const innerLines = cap[0].split('\n');
        for (let i = 0; i < innerLines.length; i++) {
          const lengthprefix = /^ *> ?/.exec(innerLines[i]);
          if (lengthprefix) {
            lineprefixes.push(lengthprefix[0].length);
          } else {
            lineprefixes.push(0);
          }
          innerLines[i] = innerLines[i].substring(lineprefixes[i]);
        }

        const rawcap = innerLines.join('\n');

        // Pass `top` to keep the current
        // "toplevel" state. This is exactly
        // how markdown.pl works.
        this.token(rawcap, false);

        const replacementend = this.tokens.length;
        for (let i = replacementstart; i < replacementend; i++) {
          const token = this.tokens[i];
          const fragments: Fragment[] = [];
          for (let j = 0; j < token.fragments.length; j++) {
            let fragment = token.fragments[j];
            // let k = fragment.start.row + (fragment.start.column == 0 ? 0 : 1);
            // let fragmentendline =
            //   fragment.end.row + (fragment.end.column == 0 ? 0 : 1);
            while (fragment.start.row < fragment.end.row) {
              const lineoffset = lineprefixes[fragment.start.row];
              if (fragment.start.column == 0 && lineoffset > 0) {
                fragments.push({
                  role: 'indent',
                  start: {
                    row: fragment.start.row + startLine,
                    column: 0,
                  },
                  end: {
                    row: fragment.start.row + startLine,
                    column: lineoffset,
                  },
                });
              }
              fragments.push({
                ...fragment,
                start: {
                  row: fragment.start.row + startLine,
                  column:
                    fragment.start.column == 'end'
                      ? 'end'
                      : lineoffset + fragment.start.column,
                },
                end: {
                  row: fragment.start.row + startLine,
                  column: 'end',
                },
              });

              fragment = {
                ...fragment,
                start: {
                  row: fragment.start.row + 1,
                  column: 0,
                },
              };
            }
            if (
              fragment.start.column == 0 &&
              lineprefixes[fragment.start.row] > 0
            ) {
              fragments.push({
                role: 'indent',
                start: {
                  row: fragment.start.row + startLine,
                  column: 0,
                },
                end: {
                  row: fragment.start.row + startLine,
                  column: lineprefixes[fragment.start.row],
                },
              });
            }
            if (
              (fragment.end.column == 'end' &&
                fragment.start.column != 'end') ||
              fragment.end.column > fragment.start.column
            ) {
              const lineoffset = lineprefixes[fragment.start.row];
              fragments.push({
                ...fragment,
                start: {
                  row: fragment.start.row + startLine,
                  column:
                    fragment.start.column == 'end'
                      ? 'end'
                      : lineoffset + fragment.start.column,
                },
                end: {
                  row: fragment.start.row + startLine,
                  column:
                    fragment.end.column == 'end'
                      ? 'end'
                      : lineoffset + fragment.end.column,
                },
              });
            }
          }
          token.fragments = fragments;
        }

        this.tokens.push({
          type: 'blockquote_end',
          fragments: [],
        });

        continue;
      }

      // list
      if ((cap = this.rules.list.exec(src))) {
        src = src.substring(cap[0].length);
        bull = cap[2];
        const isordered = bull.length > 1;
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        // const startline = posOfOffset(srcStart).row;
        const orderstyle:
          | 'numeric'
          | 'roman'
          | 'uppercase'
          | 'lowercase'
          | undefined = !isordered
          ? undefined
          : !Number.isNaN(Number(bull.substring(0, bull.length - 1)))
          ? 'numeric'
          : /^[xiv]+$/.test(bull.substring(0, bull.length - 1))
          ? 'roman'
          : bull.toLowerCase() == bull
          ? 'lowercase'
          : 'uppercase';
        const bulletMatcher =
          orderstyle === undefined
            ? new RegExp('(?:[' + bull + '])')
            : orderstyle == 'numeric'
            ? /(?:\d{1,9}\.)/
            : orderstyle == 'lowercase'
            ? /(?:[a-z]\.)/
            : orderstyle == 'uppercase'
            ? /(?:[A-Z]\.)/
            : /(?:[lxiv]{1,}\.)/;

        function miniParseRomanNumerals(v: string) {
          let sum = 0;
          let maybeMinus = 0;
          let smallestDigit = 10000;

          v = v.toLowerCase().trim();
          for (let i = 0; i < v.length; i++) {
            let digit = v[i];
            let numeric: number;
            let fives: boolean = false;

            switch (digit) {
              case 'i':
                numeric = 1;
                break;
              case 'v':
                numeric = 5;
                fives = true;
                break;
              case 'x':
                numeric = 10;
                break;
              case 'l':
                numeric = 50;
                fives = true;
                break;
              case 'c':
                numeric = 100;
                break;
              case 'd':
                numeric = 500;
                fives = true;
                break;
              case 'm':
                numeric = 1000;
                break;
              default:
                return Number.NaN;
            }
            if (fives && maybeMinus == numeric / 5) {
              sum += numeric - maybeMinus;
              smallestDigit = maybeMinus - 1; // we can't have
              maybeMinus = 0;
              continue;
            } else if (!fives && maybeMinus == numeric / 10) {
              sum += numeric - maybeMinus;
              smallestDigit = maybeMinus - 1;
              maybeMinus = 0;
              continue;
            } else if (maybeMinus == numeric) {
              sum += numeric + maybeMinus;
              smallestDigit = maybeMinus;
              maybeMinus = 0;
              continue;
            } else if (maybeMinus > 0) {
              sum += maybeMinus;
              smallestDigit = maybeMinus;
            }
            if (numeric > smallestDigit) {
              return Number.NaN;
            }
            if (numeric < smallestDigit && !fives && numeric != 1000) {
              maybeMinus = numeric;
              continue;
            }
            sum += numeric;
            smallestDigit = numeric;
          }
          if (maybeMinus) {
            sum += maybeMinus;
          }
          return sum;
        }

        const start =
          orderstyle == undefined
            ? null
            : orderstyle == 'numeric'
            ? Number(bull.substring(0, bull.length - 1))
            : orderstyle == 'uppercase'
            ? bull.charCodeAt(0) - 'A'.charCodeAt(0) + 1
            : orderstyle == 'lowercase'
            ? bull.charCodeAt(0) - 'a'.charCodeAt(0) + 1
            : miniParseRomanNumerals(bull.substring(0, bull.length - 1));
        listStart = {
          type: 'list_start',
          ordered: isordered,
          start,
          loose: false,
          fragments: [],
          orderstyle,
        };

        this.tokens.push(listStart);

        // Get each top-level item.

        const itemmatcher = new RegExp(this.rules.item.source + '\n*', 'gym');

        let currentmatch = itemmatcher.exec(cap[0]);
        let nextmatch = itemmatcher.exec(cap[0]);

        const listItems: ListItemStartLexerToken[] = [];
        let next = false;

        for (
          ;
          currentmatch != null;
          currentmatch = nextmatch, nextmatch = itemmatcher.exec(cap[0])
        ) {
          let startLine = posOfOffset(currentmatch.index + srcStart).row;

          let item = currentmatch[0].replace(/(\n)*$/, '');

          // Remove the list item's bullet
          // so it is seen as the next token.
          const originalLength = item.length;
          item = item.replace(
            new RegExp('^ *' + this.rules.bullet.source + ' *'),
            ''
          );
          const space = originalLength - item.length;
          const firstlineprefixlength = space;

          const lineprefixes: number[] = [];
          const innerLines = item.split('\n');
          for (let i = 0; i < innerLines.length; i++) {
            const matcher =
              this.options.type !== 'pedantic'
                ? new RegExp('^ {1,' + space + '}')
                : /^ {1,4}/;
            const lengthprefix = matcher.exec(innerLines[i]);
            if (lengthprefix) {
              lineprefixes.push(lengthprefix[0].length);
            } else {
              lineprefixes.push(0);
            }
            innerLines[i] = innerLines[i].substring(lineprefixes[i]);
          }
          lineprefixes[0] += firstlineprefixlength;
          item = innerLines.join('\n');

          // Determine whether the next list item belongs here.
          // Backpedal if it does not belong in this list.
          if (nextmatch) {
            b = this.rules.bullet.exec(nextmatch[0])![0];
            if (!bulletMatcher.test(b)) {
              src = cap[0].slice(nextmatch.index) + src;
              nextmatch = null;
            } else if (/\n\n$/.test(currentmatch[0])) {
              src = cap[0].slice(nextmatch!.index) + src;
              nextmatch = null;
            }
          }

          // Determine whether item is loose or not.
          // Use: /(^|\n)(?! )[^\n]+\n\n(?!\s*$)/
          // for discount behavior.
          let loose = next || /\n\n(?!\s*$)/.test(item);
          if (nextmatch) {
            next = item.charAt(item.length - 1) === '\n';
            if (!loose) loose = next;
          }

          if (loose) {
            listStart.loose = true;
          }

          // Check for task list items
          istask = /^\[[ xX]\] /.test(item);
          let ischecked: boolean | undefined = undefined;

          let originallength = 0;
          if (istask) {
            ischecked = item[1] !== ' ';
            originallength = item.length;
            item = item.replace(/^\[[ xX]\] +/, '');
            lineprefixes[0] += originallength - item.length;
          }

          t = {
            type: 'list_item_start',
            task: istask,
            checked: ischecked,
            loose: loose,
            fragments: istask
              ? [
                  {
                    role: 'info',
                    infoRole: 'checkbox',
                    start: {
                      row: startLine,
                      column: 0,
                    },
                    end: {
                      row: startLine,
                      column: originallength,
                    },
                  },
                ]
              : [],
          };

          listItems.push(t);
          this.tokens.push(t);
          const replacementstart = this.tokens.length;
          // Recurse.
          this.token(item, false);
          const replacementend = this.tokens.length;
          for (let i = replacementstart; i < replacementend; i++) {
            const token = this.tokens[i];
            const fragments: Fragment[] = [];
            for (let j = 0; j < token.fragments.length; j++) {
              let fragment = token.fragments[j];
              while (fragment.start.row < fragment.end.row) {
                const lineoffset = lineprefixes[fragment.start.row];
                if (fragment.start.column == 0 && lineoffset > 0) {
                  fragments.push({
                    role: 'indent',
                    infoRole: fragment.start.row == 0 ? undefined : 'blank',
                    start: {
                      row: fragment.start.row + startLine,
                      column: 0,
                    },
                    end: {
                      row: fragment.start.row + startLine,
                      column: lineoffset,
                    },
                  });
                }
                fragments.push({
                  ...fragment,
                  start: {
                    row: fragment.start.row + startLine,
                    column:
                      fragment.start.column == 'end'
                        ? 'end'
                        : lineoffset + fragment.start.column,
                  },
                  end: {
                    row: fragment.start.row + startLine,
                    column: 'end',
                  },
                });

                fragment = {
                  ...fragment,
                  start: {
                    row: fragment.start.row + 1,
                    column: 0,
                  },
                };
              }
              const lineoffset = lineprefixes[fragment.start.row];
              if (fragment.start.column == 0 && lineoffset > 0) {
                fragments.push({
                  role: 'indent',
                  infoRole: fragment.start.row == 0 ? undefined : 'blank',
                  start: {
                    row: fragment.start.row + startLine,
                    column: 0,
                  },
                  end: {
                    row: fragment.start.row + startLine,
                    column: lineoffset,
                  },
                });
              }
              if (
                (fragment.end.column == 'end' &&
                  fragment.start.column != 'end') ||
                fragment.end.column > fragment.start.column
              ) {
                const lineoffset = lineprefixes[fragment.start.row];
                fragments.push({
                  ...fragment,
                  start: {
                    row: fragment.start.row + startLine,
                    column:
                      fragment.start.column == 'end'
                        ? 'end'
                        : lineoffset + fragment.start.column,
                  },
                  end: {
                    row: fragment.start.row + startLine,
                    column:
                      fragment.end.column == 'end'
                        ? 'end'
                        : lineoffset + fragment.end.column,
                  },
                });
              }
            }
            token.fragments = fragments;
          }

          this.tokens.push({
            type: 'list_item_end',
            fragments: [],
          });
        }

        if (listStart.loose) {
          l = listItems.length;
          i = 0;
          for (; i < l; i++) {
            listItems[i].loose = true;
          }
        }

        this.tokens.push({
          type: 'list_end',
          fragments: [],
        });

        continue;
      }

      // html
      if ((cap = this.rules.html.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        if (this.options.sanitizer) {
          this.tokens.push({
            type: 'paragraph',
            text: this.options.sanitizer(cap[0]),
            fragments: [
              {
                role: 'display',
                start: posOfOffset(srcStart),
                end: posOfOffset(srcEnd),
                contentOffset: 0,
                contentLength: cap[0].length,
              },
            ],
          });
        } else {
          this.tokens.push({
            type: 'html',
            pre: cap[1] === 'pre' || cap[1] === 'script' || cap[1] === 'style',
            text: cap[0],
            fragments: [
              {
                role: 'passthrough',
                start: posOfOffset(srcStart),
                end: posOfOffset(srcEnd),
                contentOffset: 0,
                contentLength: cap[0].length,
              },
            ],
          });
        }
        continue;
      }

      // def
      if (top && (cap = this.rules.def.exec(src))) {
        src = src.substring(cap[0].length);
        if (cap[3]) cap[3] = cap[3].substring(1, cap[3].length - 1);
        tag = cap[1].toLowerCase().replace(/\s+/g, ' ');
        if (!this.tokenLinks[tag]) {
          this.tokenLinks[tag] = {
            href: cap[2],
            title: cap[3],
          };
        }
        continue;
      }

      // table (gfm)
      if ((cap = this.rules.table.exec(src))) {
        const srcStart = getSrcOffset();
        const rawalignment = cap[2].replace(/^ *|\| *$/g, '').split(/ *\| */);
        let headeroffset = /^ *\|/.exec(cap[0])![0].length;
        const initialheaderlength = cap[1].length;
        let rawheaderstring = cap[1].replace(/^ */, '');
        headeroffset += initialheaderlength - rawheaderstring.length;

        const rawHeader = splitCells(cap[1].replace(/^ *| *\| *$/g, ''));
        const align: ('left' | 'right' | 'center' | null)[] = [];
        const rawCells = cap[3] ? cap[3].replace(/\n$/, '').split('\n') : [];

        const fragments: Fragment[] = [];
        const header: ContentWithFragments[] = [];

        let startindex = headeroffset * -1;
        for (const cell of rawHeader.cells) {
          let contentindex = 0;
          if (typeof cell.index === 'undefined') {
            break;
          }
          let markupend = cell.index;
          if (markupend > startindex) {
            fragments.push({
              role: 'markup',
              tokenLocalIndex: headeroffset + startindex,
              start: posOfOffset(srcStart + headeroffset + startindex),
              end: posOfOffset(srcStart + headeroffset + markupend),
            });
            startindex = markupend;
          }
          const headerstartindex = headeroffset + startindex;
          for (const escape of cell.escapes) {
            fragments.push({
              role: 'display',
              tokenLocalIndex: headeroffset + startindex,
              start: posOfOffset(srcStart + headeroffset + startindex),
              end: posOfOffset(srcStart + headeroffset + cell.index + escape),
              contentOffset: contentindex,
              contentLength: cell.index + escape - startindex,
            });
            fragments.push({
              role: 'escape',
              tokenLocalIndex: headeroffset + cell.index + escape,
              start: posOfOffset(srcStart + headeroffset + cell.index + escape),
              end: posOfOffset(
                srcStart + headeroffset + cell.index + escape + 2
              ),
              contentOffset: contentindex + cell.index + escape - startindex,
              contentLength: 1,
            });
            contentindex += cell.index + escape - startindex + 1;
            startindex = cell.index + escape + 2;
          }
          const end = cell.index + cell.raw.length;
          fragments.push({
            role: 'display',
            tokenLocalIndex: headeroffset + startindex,
            start: posOfOffset(srcStart + headeroffset + startindex),
            end: posOfOffset(srcStart + headeroffset + end),
            contentOffset: contentindex,
            contentLength: end - startindex,
          });
          startindex = end;
          header.push({
            content: cell.parsed,
            fragmentIndexes: {
              from: headerstartindex,
              to: headeroffset + end,
            },
          });
        }
        if (startindex < cap[1].length) {
          fragments.push({
            role: 'markup',
            tokenLocalIndex: headeroffset + startindex,
            start: posOfOffset(srcStart + headeroffset + startindex),
            end: posOfOffset(srcStart + headeroffset + rawheaderstring.length),
          });
        }

        const alignmentstart = rawheaderstring.length + headeroffset + 1;
        const rawalignmentindex = cap[0].indexOf(cap[2]);

        fragments.push({
          role: 'markup',
          tokenLocalIndex: alignmentstart,
          start: posOfOffset(srcStart + alignmentstart),
          end: posOfOffset(srcStart + rawalignmentindex + cap[2].length),
        });

        if (rawHeader.cells.length === rawalignment.length) {
          src = src.substring(cap[0].length);

          for (i = 0; i < rawalignment.length; i++) {
            if (/^ *-+: *$/.test(rawalignment[i])) {
              align.push('right');
            } else if (/^ *:-+: *$/.test(rawalignment[i])) {
              align.push('center');
            } else if (/^ *:-+ *$/.test(rawalignment[i])) {
              align.push('left');
            } else {
              align.push(null);
            }
          }

          const cells: ContentWithFragments[][] = [];
          let rowindex = rawalignmentindex + cap[2].length + 1;

          for (i = 0; i < rawCells.length; i++) {
            const row: ContentWithFragments[] = [];
            const rowdata = splitCells(
              rawCells[i].replace(/^ *\| *| *\| *$/g, ''),
              rawHeader.cells.length
            );
            let startindex = 0;
            const firstmeaningfulindex = /^ *\| *|/.exec(rawCells[i])![0]
              .length;
            if (firstmeaningfulindex > startindex) {
              fragments.push({
                role: 'markup',
                tokenLocalIndex: rowindex + startindex,
                start: posOfOffset(srcStart + rowindex + startindex),
                end: posOfOffset(srcStart + rowindex + firstmeaningfulindex),
              });
              rowindex += firstmeaningfulindex - startindex;
            }

            for (const cell of rowdata.cells) {
              let contentindex = 0;
              if (typeof cell.index === 'undefined') {
                break;
              }
              let markupend = cell.index;
              if (markupend > startindex) {
                fragments.push({
                  role: 'markup',
                  tokenLocalIndex: rowindex + startindex,
                  start: posOfOffset(srcStart + rowindex + startindex),
                  end: posOfOffset(srcStart + rowindex + markupend),
                });
                startindex = markupend;
              }
              const cellstart = rowindex + startindex;
              for (const escape of cell.escapes) {
                fragments.push({
                  role: 'display',
                  tokenLocalIndex: rowindex + startindex,
                  start: posOfOffset(srcStart + rowindex + startindex),
                  end: posOfOffset(srcStart + rowindex + cell.index + escape),
                  contentOffset: contentindex,
                  contentLength: cell.index + escape - startindex,
                });
                fragments.push({
                  role: 'escape',
                  tokenLocalIndex: rowindex + cell.index + escape,
                  start: posOfOffset(srcStart + rowindex + cell.index + escape),
                  end: posOfOffset(
                    srcStart + rowindex + cell.index + escape + 2
                  ),
                  contentOffset:
                    contentindex + cell.index + escape - startindex,
                  contentLength: 1,
                });
                contentindex += cell.index + escape - startindex + 1;
                startindex = cell.index + escape + 2;
              }
              const end = cell.index + cell.raw.length;
              fragments.push({
                role: 'display',
                tokenLocalIndex: rowindex + startindex,
                start: posOfOffset(srcStart + rowindex + startindex),
                end: posOfOffset(srcStart + rowindex + end),
                contentOffset: contentindex,
                contentLength: end - startindex,
              });
              startindex = end;
              const to = rowindex + end;
              row.push({
                content: cell.parsed,
                fragmentIndexes: {
                  from: cellstart,
                  to,
                },
              });
            }
            if (startindex < rawCells[i].length + 1 - firstmeaningfulindex) {
              fragments.push({
                role: 'markup',
                tokenLocalIndex: rowindex + startindex,
                start: posOfOffset(srcStart + rowindex + startindex),
                end: posOfOffset(
                  srcStart +
                    rowindex +
                    rawCells[i].length +
                    1 -
                    firstmeaningfulindex
                ),
              });
            }
            rowindex += rawCells[i].length + 1 - firstmeaningfulindex;

            cells.push(row);
          }

          this.tokens.push({
            type: 'table',
            header,
            align,
            cells,
            fragments,
          });

          continue;
        }
      }

      // lheading
      if ((cap = this.rules.lheading.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        const textend = cap[1].length;
        this.tokens.push({
          type: 'heading',
          depth: cap[2].charAt(0) === '=' ? 1 : 2,
          text: cap[1],
          fragments: [
            {
              role: 'display',
              start: posOfOffset(srcStart),
              end: posOfOffset(srcStart + textend),
              contentOffset: 0,
              contentLength: cap[1].length,
            },
            {
              role: 'markup',
              start: posOfOffset(textend + srcStart),
              end: posOfOffset(srcEnd),
            },
          ],
        });
        continue;
      }

      // top-level paragraph
      if (top && (cap = this.rules.paragraph.exec(src))) {
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        this.tokens.push({
          type: 'paragraph',
          text:
            cap[1].charAt(cap[1].length - 1) === '\n'
              ? cap[1].slice(0, -1)
              : cap[1],
          fragments: [
            {
              role: 'display',
              start: posOfOffset(srcStart),
              end: posOfOffset(srcEnd),
              contentOffset: 0,
              contentLength: cap[0].length,
            },
          ],
        });
        continue;
      }

      // text
      if ((cap = this.rules.text.exec(src))) {
        // Top-level should never reach here.
        src = src.substring(cap[0].length);
        const srcEnd = getSrcOffset();
        const srcStart = srcEnd - cap[0].length;
        this.tokens.push({
          type: 'text',
          text: cap[0],
          fragments: [
            {
              role: 'display',
              start: posOfOffset(srcStart),
              end: posOfOffset(srcEnd),
              contentOffset: 0,
              contentLength: cap[0].length,
            },
          ],
        });
        continue;
      }

      if (src) {
        throw new Error('Infinite loop on byte: ' + src.charCodeAt(0));
      }
    }

    if (final) {
      this.finalizeTokens(lines);
    }

    return { tokens: this.tokens, links: this.tokenLinks };
  }

  finalizeTokens(lines: string[]) {
    for (const token of this.tokens) {
      const fragmentswere = token.fragments;
      token.fragments = [];
      for (
        let fragment = fragmentswere.shift();
        !!fragment;
        fragment = fragmentswere.shift()
      ) {
        // let internaloffset = 0;
        while (fragment.end.row > fragment.start.row) {
          token.fragments.push({
            ...fragment,
            start: fragment.start,
            end: {
              row: fragment.start.row,
              column: 'end',
            },
            contentOffset:
              typeof fragment.contentOffset === 'undefined'
                ? undefined
                : fragment.contentOffset,
            contentLength:
              typeof fragment.contentOffset === 'undefined'
                ? undefined
                : lines[fragment.start.row].length,
          });
          token.fragments.push({
            ...fragment,
            start: {
              row: fragment.start.row,
              column: 'end',
            },
            end: {
              row: fragment.start.row + 1,
              column: 0,
            },
            contentOffset:
              typeof fragment.contentOffset === 'undefined'
                ? undefined
                : fragment.contentOffset + lines[fragment.start.row].length,
            contentLength:
              typeof fragment.contentOffset === 'undefined' ? undefined : 1,
          });
          fragment = {
            ...fragment,
            start: {
              row: fragment.start.row + 1,
              column: 0,
            },
            contentOffset:
              typeof fragment.contentOffset === 'undefined'
                ? undefined
                : fragment.contentOffset + lines[fragment.start.row].length + 1,
            contentLength:
              typeof fragment.contentLength === 'undefined'
                ? undefined
                : fragment.contentLength - lines[fragment.start.row].length - 1,
          };
        }
        token.fragments.push(fragment);
      }
      for (const fragment of token.fragments) {
        if (fragment.start.column == 'end') {
          fragment.start.column = lines[fragment.start.row].length;
        }
        if (fragment.end.column == 'end') {
          fragment.end.column = lines[fragment.end.row].length;
        }
        if (fragment.end.row == fragment.start.row) {
          fragment.rawContent = lines[fragment.end.row].substring(
            fragment.start.column,
            fragment.end.column
          );
        } else if (fragment.end.row == fragment.start.row + 1) {
          const firstline = lines[fragment.start.row].substring(
            fragment.start.column
          );
          const lastline = lines[fragment.start.row].substring(
            0,
            fragment.end.column
          );
          fragment.rawContent = firstline + '\n' + lastline;
        } else {
          const fulllines = lines.slice(
            fragment.start.row + 1,
            fragment.end.row
          );
          const firstline = lines[fragment.start.row].substring(
            fragment.start.column
          );
          const lastline = lines[fragment.start.row].substring(
            0,
            fragment.end.column
          );
          fragment.rawContent =
            firstline + '\n' + fulllines.join('\n') + '\n' + lastline;
        }
      }
      token.fragments = token.fragments.filter(
        v => v.start.row != v.end.row || v.start.column != v.end.column
      );
    }
    const lineadjustments = new Array<number>(lines.length).fill(-1);
    for (let j = 0; j < this.tokens.length; j++) {
      const token = this.tokens[j];
      if (token.type == 'attr_start') {
        lineadjustments[token.fragments[0].start.row] = j;
      } else {
        for (let i = 0; i < token.fragments.length; i++) {
          const fragment = token.fragments[i];
          const relevantattr = lineadjustments[fragment.start.row];
          if (fragment.start.row == fragment.end.row && relevantattr >= 0) {
            const attrelement = this.tokens[relevantattr];
            const lineadjustment =
              (attrelement.fragments[0].end.column as number) -
              (attrelement.fragments[0].start.column as number);
            (fragment.end.column as number) -= lineadjustment;
            (fragment.start.column as number) -= lineadjustment;
            if (
              fragment.end.column ==
              lines[fragment.end.row].length - lineadjustment
            ) {
              token.fragments.splice(i + 1, 0, {
                role: 'markup',
                start: fragment.end,
                end: {
                  row: fragment.start.row,
                  column: lines[fragment.end.row].length,
                },
              });
              attrelement.fragments = [];
              break;
            }
          }
        }
      }
    }
  }
}
