var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "modal-host" },
    [
      _vm.modal == "link"
        ? _c("link-modal")
        : _vm.modal == "image"
        ? _c("image-modal")
        : _vm.modal == "article-info"
        ? _c("metadata-modal")
        : _vm.modal == "notice-info"
        ? _c("notice-info-modal")
        : _vm.modal == "self-settings"
        ? _c("self-settings-modal")
        : _vm.modal == "accept-tos"
        ? _c("accept-tos-modal", { attrs: { mobile: _vm.mobile } })
        : _vm._e(),
      _c("zoomable-image"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }