
import AbpBase from '../lib/abpbase';
import { Component } from 'vue-property-decorator';
import { Notification } from '../store/modules/app';
import { VSnackbar, VBtn } from 'vuetify/lib';

@Component({
  components: {
    VSnackbar,
    VBtn,
  },
})
export default class NotificationHost extends AbpBase {
  get notifications(): Notification[] {
    return this.$store.state.app.notifications;
  }
  closeNotification(index: number) {
    this.$store.dispatch({
      type: 'app/removeNotification',
      payload: index,
    });
  }
}
