import { VueConstructor } from 'vue';
import { Vue } from 'vue/types/vue';

export default {
  /*
		adapter for the original iview
	*/
  install(Vue: VueConstructor<Vue>) {
    Vue.prototype.$Modal = {
      error() {
        console.warn('todo: modal');
      },
      confirm() {
        console.warn('todo: modal');
      },
    };

    Vue.prototype.$Message = {
      destroy() {
        console.warn('todo: destroy the message');
      },
      error() {
        console.warn('todo: display the error message');
      },
    };
  },
};
