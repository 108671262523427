var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "article-content" }, [
      _c(
        "p",
        [
          _vm._v(" There is currently no text in this page. "),
          [
            _vm._v(" You can "),
            _c("router-link", { attrs: { to: "/edit/" + _vm.id } }, [
              _vm._v("create"),
            ]),
            _vm._v(" this page. "),
          ],
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }