import type { ActionContext, Module } from 'vuex';
import type { ActionPayload, RootState } from '..';
import ajax from '../../lib/ajax';
import type { EditorSelection } from '../../lib/editor-funcs';
import { getSelectionContents } from '../../lib/editor-funcs';
import util from '../../lib/util';
import type { Notice } from './article-list';

interface EditMetadataState {
  title: string;
  color: string;
  immediate: boolean;
  fromDate: string;
  throughDate: string;
}

export function noticesAreSame(a: Notice | null, b: Notice | null): boolean {
  if (!a && !b) {
    return true;
  }
  if (!a) {
    return false;
  }
  if (!b) {
    return false;
  }
  if (a.isDeleted != b.isDeleted) {
    return false;
  }
  if (a.body != b.body) {
    return false;
  }
  if (a.color != b.color) {
    return false;
  }
  if (a.fromDate != b.fromDate) {
    return false;
  }
  if (a.throughDate != b.throughDate) {
    return false;
  }
  if (a.title != b.title) {
    return false;
  }
  return true;
}

export interface CurrentNoticeState {
  currentEditing: Notice | null;
  currentEditingSrc: Notice | null;
  currentRemote: Notice | null;
  hasChangedOnline: boolean;
  isSaving: boolean;
  exists: boolean;
  editMetadataState: EditMetadataState;
  currentSelection: EditorSelection | null;
}

class CurrentNoticeStore implements Module<CurrentNoticeState, any> {
  namespaced = true;
  state: CurrentNoticeState = {
    currentEditing: null,
    currentEditingSrc: null,
    currentRemote: null,
    hasChangedOnline: false,
    isSaving: false,
    exists: false,
    currentSelection: null,
    editMetadataState: {
      title: '',
      fromDate: '',
      immediate: true,
      throughDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
      color: 'green',
    },
  };

  actions = {
    setModal(
      context: ActionContext<CurrentNoticeState, any>,
      arg: ActionPayload<{
        modal: 'notice-info' | null;
        selection?: EditorSelection;
      }>
    ) {
      context.commit(
        {
          type: 'app/openModal',
          payload: arg.payload.modal,
        },
        { root: true }
      );

      context.commit('resetModalState');
      if (typeof arg.payload.selection !== 'undefined') {
        context.commit({
          type: 'setSelection',
          payload: arg.payload.selection,
        });
      }
    },
    async commitMetadata(
      context: ActionContext<CurrentNoticeState, RootState>
    ) {
      context.commit({
        type: 'commitMetadata',
      });
      context.dispatch({
        type: 'setModal',
        payload: {
          modal: null,
          selection: null,
        },
      });
    },
    async save(context: ActionContext<CurrentNoticeState, any>) {
      if (!context.state.currentEditing) {
        return;
      }
      context.commit('startSaving');
      const url = context.state.exists
        ? '/api/services/app/Notice/Update'
        : '/api/services/app/Notice/Create';
      try {
        if (context.state.exists) {
          await ajax.put(
            url,
            {
              ...context.state.currentEditing,
            },
            {
              headers: {
                'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
              },
            }
          );
        } else {
          await ajax.post(
            url,
            {
              ...context.state.currentEditing,
              id: '00000000-0000-0000-0000-000000000000',
              modificationTime: new Date().toISOString(),
            },
            {
              headers: {
                'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
              },
            }
          );
        }
      } catch {
        context.commit('cancelSaving');
      }
      context.dispatch(
        {
          type: 'articleList/startLoadDocuments',
        },
        { root: true }
      );
    },
  };

  mutations = {
    setTitle(state: CurrentNoticeState, args: ActionPayload<string>) {
      state.editMetadataState.title = args.payload;
    },
    setColor(state: CurrentNoticeState, args: ActionPayload<string>) {
      state.editMetadataState.color = args.payload;
    },
    setImmediate(state: CurrentNoticeState, args: ActionPayload<boolean>) {
      state.editMetadataState.immediate = args.payload;
      if (args.payload) {
        state.editMetadataState.fromDate = '';
      } else {
        state.editMetadataState.fromDate = new Date().toISOString();
      }
    },
    setFromDate(state: CurrentNoticeState, args: ActionPayload<string>) {
      state.editMetadataState.fromDate = args.payload;
      if (args.payload) {
        state.editMetadataState.immediate = false;
      }
    },
    setThroughDate(state: CurrentNoticeState, args: ActionPayload<string>) {
      state.editMetadataState.throughDate = args.payload;
    },
    commitMetadata(state: CurrentNoticeState) {
      if (!state.currentEditing) {
        return;
      }
      state.currentEditing.color = state.editMetadataState.color;
      state.currentEditing.title = state.editMetadataState.title;
      state.currentEditing.fromDate = state.editMetadataState.immediate
        ? null
        : state.editMetadataState.fromDate;
      state.currentEditing.throughDate = state.editMetadataState.throughDate;
    },
    resetModalState(state: CurrentNoticeState) {
      if (!state.currentEditing) {
        return;
      }
      state.editMetadataState = {
        ...state.currentEditing,
        immediate: state.currentEditing.fromDate == null,
        fromDate: state.currentEditing.fromDate || '',
        throughDate: state.currentEditing.throughDate || '',
      };
    },
    setSelection(
      state: CurrentNoticeState,
      args: ActionPayload<EditorSelection | null>
    ) {
      state.currentSelection = args.payload;
      if (args.payload) {
        getSelectionContents(args.payload, state.currentEditing!.body || '');
      }
    },
    startSaving(state: CurrentNoticeState) {
      state.isSaving = true;
    },
    cancelSaving(state: CurrentNoticeState) {
      state.isSaving = false;
    },
    setContent(state: CurrentNoticeState, payload: ActionPayload<string>) {
      if (!state.currentEditing) {
        return;
      }
      state.currentEditing = { ...state.currentEditing, body: payload.payload };
    },
    selectNotice(
      state: CurrentNoticeState,
      payload: ActionPayload<{ doc: Notice; exists: boolean }>
    ) {
      state.currentEditing = state.currentEditingSrc = state.currentRemote =
        payload.payload.doc;
      state.exists = payload.payload.exists;
      state.hasChangedOnline = false;
      state.isSaving = false;
    },
    updateFromRemote(
      state: CurrentNoticeState,
      args: ActionPayload<(Notice & PouchDB.Core.AllDocsMeta)[]>
    ) {
      if (!state.currentEditing) {
        return;
      }
      for (const article of args.payload) {
        if (
          article.id == state.currentEditing.id ||
          (state.currentEditing.id == '' &&
            article.title == state.currentEditing.title)
        ) {
          state.currentRemote = article;
          state.exists = true;
          if (state.isSaving) {
            state.isSaving = false;
            state.currentEditing = state.currentEditingSrc = article;
            state.hasChangedOnline = false;
          } else if (
            noticesAreSame(state.currentEditing, state.currentEditingSrc)
          ) {
            state.currentEditing = state.currentEditingSrc = article;
            state.hasChangedOnline = false;
          } else {
            state.hasChangedOnline = true;
          }
          break;
        }
      }
    },
  };
}

const notice = new CurrentNoticeStore();
export default notice;
