
import AbpBase from '../lib/abpbase';
import { Component, Prop } from 'vue-property-decorator';
import ZoomableImage from './zoomableImage.vue';

export const COLLECTIBLE_MODAL_IMPORTS: {
  [key: string]: () => Promise<any>;
} = {
  'link-modal': () => import('./modals/link-modal.vue'),
  'image-modal': () => import('./modals/image-modal.vue'),
  'metadata-modal': () => import('./modals/metadata-modal.vue'),
  'notice-info-modal': () => import('./modals/noticeInfoModal.vue'),
  'self-settings-modal': () => import('./modals/selfSettings.vue'),
  'accept-tos-modal': () => import('./modals/acceptTosModal.vue'),
};

@Component({
  components: {
    ...COLLECTIBLE_MODAL_IMPORTS,
    'zoomable-image': ZoomableImage,
    // This one is pretty big since it includes the QRCode generation stuff.
  },
})
export default class ModalHost extends AbpBase {
  get modal():
    | 'link'
    | 'image'
    | 'article-info'
    | 'notice-info'
    | 'self-settings'
    | 'self-settings'
    | 'accept-tos'
    | null {
    return this.$store.state.app.modal;
  }
  @Prop(Boolean)
  mobile!: boolean;
}
