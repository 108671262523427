import axios from 'axios';
import type { Store } from 'vuex';
import { APP_CONSTS } from '../init';
import type { RootState } from '../store';
import type { Notification } from '../store/modules/app';
import { abp } from './abp';
const ajax = axios.create({
  baseURL: APP_CONSTS.remoteServiceBaseUrl,
  timeout: 30000,
});
ajax.interceptors.request.use(
  function (config) {
    if (!!abp.auth.getToken()) {
      config.headers.common['Authorization'] = 'Bearer ' + abp.auth.getToken();
    }
    config.headers.common['.AspNetCore.Culture'] = abp.utils.getCookieValue(
      'Abp.Localization.CultureName'
    );
    config.headers.common[
      'Abp.TenantId'
    ] = abp.multiTenancy.getTenantIdCookie();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
ajax.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const store = ((window as unknown) as Record<string, unknown>)[
      '__store__'
    ] as Store<RootState>;
    // This error is modal and it should be presented to the user.
    if (error.config.url.includes('/api/services/app/User/EnableTOTP')) {
      return Promise.reject(error);
    }
    if (error.config.url.includes('/api/services/app/User/ValidateTOTP')) {
      return Promise.reject(error);
    }
    if (error.config.url.includes('/api/services/app/User/RescueUser')) {
      return Promise.reject(error);
    }
    if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message &&
      error.response.data.error.details
    ) {
      const payload: Notification = {
        message: error.response.data.error.message,
        details: error.response.data.error.details,
        severity: 'warning',
        duration: 15_000,
        tag: 'Connectivity',
      };
      store.dispatch(
        {
          type: 'app/notify',
          payload,
        },
        { root: true }
      );
    } else if (
      !!error.response &&
      !!error.response.data.error &&
      !!error.response.data.error.message
    ) {
      const payload: Notification = {
        message: 'CouldNotCompleteLoginOperation',
        details: error.response.data.error.message,
        severity: 'warning',
        duration: 15_000,
        tag: 'Connectivity',
      };
      store.dispatch(
        {
          type: 'app/notify',
          payload,
        },
        { root: true }
      );
    } else if (!error.response) {
      if (error.config && error.config.url) {
        // these happen every 30 seconds...
        if (error.config.url.includes('/api/services/app/Document/GetAll')) {
          return Promise.reject(error);
        }
        if (error.config.url.includes('/api/services/app/Attachment/GetAll')) {
          return Promise.reject(error);
        }
        if (
          error.config.url.includes(
            '/api/services/app/IndexConfigurationService/GetAll'
          )
        ) {
          return Promise.reject(error);
        }
        if (error.config.url.includes('/api/services/app/Notice/GetAll')) {
          return Promise.reject(error);
        }
      }
      const payload: Notification = {
        message: 'ConnectivityError',
        severity: 'warning',
        duration: 15_000,
        tag: 'Connectivity',
      };
      store.dispatch(
        {
          type: 'app/notify',
          payload,
        },
        { root: true }
      );
    }
    return Promise.reject(error);
  }
);
export default ajax;
