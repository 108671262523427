/* eslint-disable @typescript-eslint/consistent-type-imports */
import cloneDeep from 'lodash/cloneDeep';
import { ActionContext, Module } from 'vuex';
import { ActionPayload } from '..';
import ajax from '../../lib/ajax';
import util from '../../lib/util';

export interface NestedTree<T extends NestedTree<T>> {
  name: string;
  children: T[];
}

export interface IndexSection extends NestedTree<IndexSection> {
  fixed?: boolean;
  weight: number;
  name: string;
  icon: string | null;
  children: IndexSection[];
}
export interface IndexConfiguration<T extends NestedTree<T> = IndexSection> {
  type: 'index';
  id: number;
  revisionCount: number;
  sections?: T[];
  alsSections: T[];
  blsSections: T[];
  revisionDate: string;
}

export interface ExpandedIndexSection extends NestedTree<ExpandedIndexSection> {
  fixed?: boolean;
  weight: number;
  parent?: ExpandedIndexSection;
  id?: number;
  icon: string | null;
}

export interface IndexConfigurationState {
  editing: IndexConfiguration<ExpandedIndexSection>;
  largestSectionEver: number;
  hasChanged: boolean;
  isSaving: boolean;
}

function parentSection(section: ExpandedIndexSection, i: number) {
  for (const child of section.children) {
    child.parent = section;
    child.id = i++;
    i = parentSection(child, i);
  }
  return i;
}

function getPath(root: ExpandedIndexSection[], arg: ExpandedIndexSection) {
  const result: number[] = [];
  while (arg.parent) {
    const index = arg.parent.children.findIndex(v => v.id == arg.id);
    result.unshift(index);
    arg = arg.parent;
  }
  const index = root.findIndex(v => v.id == arg.id);
  result.unshift(index);
  return result;
}

function reifyPath(root: ExpandedIndexSection[], arg: ExpandedIndexSection) {
  const path = getPath(root, arg);
  let currentNode!: ExpandedIndexSection;
  for (let i = 0; i < path.length; i++) {
    currentNode = root[path[i]];
    root = currentNode.children;
  }
  return {
    node: currentNode,
    path,
  };
}

function cleanupParents(root: ExpandedIndexSection[]): ExpandedIndexSection[] {
  const result: ExpandedIndexSection[] = [];
  for (const child of root) {
    result.push({
      children: cleanupParents(child.children),
      name: child.name,
      weight: child.weight,
      icon: child.icon,
    });
  }
  return result;
}

class IndexConfigurationStore implements Module<IndexConfigurationState, any> {
  namespaced = true;
  state: IndexConfigurationState = {
    editing: {
      type: 'index',
      id: 0,
      revisionCount: 0,
      revisionDate: '1900-01-01T00:00:00Z',
      alsSections: [],
      blsSections: [],
    },
    largestSectionEver: 0,
    hasChanged: false,
    isSaving: false,
  };

  actions = {
    async save(context: ActionContext<IndexConfigurationState, any>) {
      context.commit('startSaving');
      const url = '/api/services/app/IndexConfigurationService/Update';
      try {
        await ajax.put(
          url,
          {
            ...context.state.editing,
            sections: null,
            alsSections: cleanupParents(context.state.editing.alsSections),
            blsSections: cleanupParents(context.state.editing.blsSections),
          },
          {
            headers: {
              'Abp.TenantId': util.abp.multiTenancy.getTenantIdCookie(),
            },
          }
        );
      } catch {
        context.commit('cancelSaving');
      }
      return context.dispatch(
        {
          type: 'articleList/startLoadDocuments',
        },
        { root: true }
      );
    },
  };

  mutations = {
    changed(state: IndexConfigurationState) {
      state.hasChanged = true;
    },

    startSaving(state: IndexConfigurationState) {
      state.isSaving = true;
    },

    cancelSaving(state: IndexConfigurationState) {
      state.isSaving = false;
    },

    updateFromRemote(
      state: IndexConfigurationState,
      arg: ActionPayload<IndexConfiguration>
    ) {
      if (state.isSaving || !state.hasChanged) {
        state.editing = cloneDeep(arg.payload);
        state.hasChanged = false;
        if (state.editing.sections) {
          state.editing.alsSections = state.editing.sections;
          state.editing.sections = undefined;
        }
        if (!state.editing.blsSections) {
          state.editing.blsSections = [];
        }
        for (const section of state.editing.alsSections) {
          section.parent = undefined;
          section.id = state.largestSectionEver++;
          state.largestSectionEver = parentSection(
            section,
            state.largestSectionEver
          );
        }
        for (const section of state.editing.blsSections) {
          section.parent = undefined;
          section.id = state.largestSectionEver++;
          state.largestSectionEver = parentSection(
            section,
            state.largestSectionEver
          );
        }
      }
    },

    startEditing(
      state: IndexConfigurationState,
      arg: ActionPayload<IndexConfiguration>
    ) {
      state.editing = cloneDeep(arg.payload);
      state.hasChanged = false;
      for (const section of state.editing.alsSections) {
        section.parent = undefined;
        section.id = state.largestSectionEver++;
        state.largestSectionEver = parentSection(
          section,
          state.largestSectionEver
        );
      }
      for (const section of state.editing.blsSections) {
        section.parent = undefined;
        section.id = state.largestSectionEver++;
        state.largestSectionEver = parentSection(
          section,
          state.largestSectionEver
        );
      }
    },

    addSection(
      state: IndexConfigurationState,
      arg: ActionPayload<ExpandedIndexSection | 'ALS' | 'BLS'>
    ) {
      state.hasChanged = true;
      let parent: ExpandedIndexSection | undefined;
      let sections: ExpandedIndexSection[];
      if (arg.payload == 'ALS') {
        sections = state.editing.alsSections;
      } else if (arg.payload == 'BLS') {
        sections = state.editing.blsSections;
      } else {
        let topMost = arg.payload;
        while (topMost.parent) {
          topMost = topMost.parent;
        }
        const treeSections = state.editing.alsSections
          .map(v => v.id)
          .includes(topMost.id)
          ? state.editing.alsSections
          : state.editing.blsSections;
        const { node } = reifyPath(treeSections, arg.payload);
        parent = node;
        sections = node.children;
      }
      const largestWeight = sections.length
        ? sections[sections.length - 1].weight
        : 0;
      sections.push({
        name: 'New Section',
        weight: largestWeight + 1,
        children: [],
        parent,
        id: state.largestSectionEver++,
        icon: null,
      });
    },
    deleteSection(
      state: IndexConfigurationState,
      arg: ActionPayload<ExpandedIndexSection>
    ) {
      state.hasChanged = true;
      let topMost = arg.payload;
      while (topMost.parent) {
        topMost = topMost.parent;
      }
      const treeSections = state.editing.alsSections
        .map(v => v.id)
        .includes(topMost.id)
        ? state.editing.alsSections
        : state.editing.blsSections;
      const { node, path } = reifyPath(treeSections, arg.payload);
      const parentSections = node.parent ? node.parent.children : treeSections;
      const indexInParent = path[path.length - 1];
      parentSections.splice(indexInParent, 1);
    },
    moveUp(
      state: IndexConfigurationState,
      arg: ActionPayload<ExpandedIndexSection>
    ) {
      state.hasChanged = true;
      let topMost = arg.payload;
      while (topMost.parent) {
        topMost = topMost.parent;
      }
      const treeSections = state.editing.alsSections
        .map(v => v.id)
        .includes(topMost.id)
        ? state.editing.alsSections
        : state.editing.blsSections;
      const { node, path } = reifyPath(treeSections, arg.payload);
      const parentSections = node.parent ? node.parent.children : treeSections;
      const indexInParent = path[path.length - 1];
      if (indexInParent == 0) {
        return;
      }
      parentSections.splice(indexInParent, 1);
      parentSections.splice(indexInParent - 1, 0, node);
      const weightWas = parentSections[indexInParent].weight;
      parentSections[indexInParent].weight = node.weight;
      node.weight = weightWas;
    },
    moveDown(
      state: IndexConfigurationState,
      arg: ActionPayload<ExpandedIndexSection>
    ) {
      state.hasChanged = true;
      let topMost = arg.payload;
      while (topMost.parent) {
        topMost = topMost.parent;
      }
      const treeSections = state.editing.alsSections
        .map(v => v.id)
        .includes(topMost.id)
        ? state.editing.alsSections
        : state.editing.blsSections;
      const { node, path } = reifyPath(treeSections, arg.payload);
      const parentSections = node.parent ? node.parent.children : treeSections;
      const indexInParent = path[path.length - 1];
      if (indexInParent >= parentSections.length - 1) {
        return;
      }
      parentSections.splice(indexInParent, 1);
      parentSections.splice(indexInParent + 1, 0, node);
      const weightWas = parentSections[indexInParent].weight;
      parentSections[indexInParent].weight = node.weight;
      node.weight = weightWas;
    },
  };
}

const indexConfiguration = new IndexConfigurationStore();
export default indexConfiguration;
