import Vue from 'vue';
import type { Payload, Store } from 'vuex';
import Vuex from 'vuex';
import type { AppRoute } from '../router/routes';
import type { AccountState } from './modules/account';
import account from './modules/account';
import type { AppState } from './modules/app';
import app from './modules/app';
import type { CurrentArticleState } from './modules/article';
import article from './modules/article';
import type { ArticleListState } from './modules/article-list';
import articleList from './modules/article-list';
import type { ArticleRevisionState } from './modules/article-revision';
import articleRevision from './modules/article-revision';
import type { ImageViewState } from './modules/image-view';
import imageView from './modules/image-view';
import type { IndexConfigurationState } from './modules/index-configuration';
import indexConfiguration from './modules/index-configuration';
import type { CurrentNoticeState } from './modules/notice';
import notice from './modules/notice';
import type { RoleState } from './modules/role';
import role from './modules/role';
import type { SearchState } from './modules/search';
import search from './modules/search';
import type { SessionState } from './modules/session';
import session from './modules/session';
import type { UserState } from './modules/user';
import user from './modules/user';
Vue.use(Vuex);

export interface RootState {
  app: AppState;
  session: SessionState;
  account: AccountState;
  user: UserState;
  role: RoleState;
  articleList: ArticleListState;
  article: CurrentArticleState;
  route: AppRoute;
  indexConfiguration: IndexConfigurationState;
  imageView: ImageViewState;
  search: SearchState;
  notice: CurrentNoticeState;
  articleRevision: ArticleRevisionState;
}

const store = (new Vuex.Store<unknown>({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {},
  modules: {
    app,
    session,
    account,
    user,
    role,
    articleList,
    article,
    indexConfiguration,
    imageView,
    search,
    notice,
    articleRevision,
  },
}) as unknown) as Store<RootState>;

export interface ActionPayload<T> extends Payload {
  payload: T;
}
((window as unknown) as Record<string, unknown>)['__store__'] = store;

export default store;
