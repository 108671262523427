import Vue from 'vue';
import type { RawLocation, RouterOptions } from 'vue-router';
import VueRouter from 'vue-router';
import { appInsights } from '../init';
import { abp } from '../lib/abp';
//import iView from 'iview';
import Util from '../lib/util';
import store from '../store';
import type { Notification } from '../store/modules/app';
import type { AppRouteMeta } from './routes';
import { appRoutes, otherRoutes, routers } from './routes';

Vue.use(VueRouter);

export const defaultLocation: RawLocation = {
  name: 'home',
};

const ROUTER_CONFIG: RouterOptions = {
  mode: 'history',
  routes: routers,
};

export const router = new VueRouter(ROUTER_CONFIG);

router.beforeEach((to, _from, next) => {
  //iView.LoadingBar.start();
  Util.title((to.meta as AppRouteMeta).title!);
  if (
    to.name == 'article' ||
    to.name == 'not-found' ||
    to.name == 'home' ||
    to.name == 'display-notices'
  ) {
    appInsights.startTrackPage(to.fullPath);
    Util.toDefaultPage([...otherRoutes, ...appRoutes], to.name, router, next);
    return;
  }
  if (!Util.abp.session.userId && to.name !== 'login') {
    next({
      name: 'login',
      query: {
        return: to.fullPath,
      },
      replace: true,
    });
    return;
  }
  if (Util.abp.session.userId && to.name === 'login') {
    if (
      to.query &&
      to.query['return'] &&
      typeof to.query['return'] === 'string'
    ) {
      next({
        path: to.query['return'],
        replace: true,
      });
    } else {
      Util.title(to.meta?.title || '');
      next({
        ...defaultLocation,
        replace: true,
      });
    }
    return;
  }
  const curRouterObj = Util.getRouterObjByName(
    [...otherRoutes, ...appRoutes],
    to.name || undefined
  );
  if (curRouterObj && curRouterObj.permission) {
    if (abp.auth.isGranted(curRouterObj.permission)) {
      Util.toDefaultPage(
        [...otherRoutes, ...appRoutes],
        to.name || undefined,
        router,
        next
      );
    } else {
      const payload: Notification = {
        message: 'DefaultError403',
        severity: 'warning',
        duration: 15_000,
        tag: 'DefaultError403',
      };
      void store.dispatch({
        type: 'app/notify',
        payload,
      });
      next({
        ...defaultLocation,
        replace: true,
      });
    }
    return;
  }
  Util.toDefaultPage([...routers], to.name || undefined, router, next);
});
router.afterEach(to => {
  appInsights.stopTrackPage(to.fullPath);
  window.scrollTo(0, 0);
});
