var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { attrs: { id: "app" } },
    [
      _c(_vm.layout, {
        tag: "component",
        on: {
          togglelayout: function ($event) {
            return _vm.toggleLayout()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }