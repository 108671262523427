import { Options } from './defaults';

export interface Ctor<T> {
  new (): T;
}

/**
 * Helpers
 */
const escapeTest = /[&<>"']/;
const escapeReplace = /[&<>"']/g;
const escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/;
const escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g;
const escapeReplacements: { [entity: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
};
const getEscapeReplacement = (ch: string) => escapeReplacements[ch];
export function escape(html: string, encode?: boolean) {
  if (encode) {
    if (escapeTest.test(html)) {
      return html.replace(escapeReplace, getEscapeReplacement);
    }
  } else {
    if (escapeTestNoEncode.test(html)) {
      return html.replace(escapeReplaceNoEncode, getEscapeReplacement);
    }
  }

  return html;
}

const unescapeTest = /&(#(?:\d+)|(?:#x[0-9A-Fa-f]+)|(?:\w+));?/gi;

export function unescape(html: string) {
  // explicitly match decimal, hex, and named HTML entities
  return html.replace(unescapeTest, (_, n) => {
    n = n.toLowerCase();
    if (n === 'colon') return ':';
    if (n.charAt(0) === '#') {
      return n.charAt(1) === 'x'
        ? String.fromCharCode(parseInt(n.substring(2), 16))
        : String.fromCharCode(+n.substring(1));
    }
    return '';
  });
}

const caret = /(^|[^\[])\^/g;
export function edit(regexOuter: RegExp | string, opt?: string) {
  let regex = typeof regexOuter == 'string' ? regexOuter : regexOuter.source;
  opt = opt || '';
  const obj = {
    replace: (name: RegExp | string, valOuter: RegExp | string) => {
      let val = typeof valOuter == 'string' ? valOuter : valOuter.source;
      val = val.replace(caret, '$1');
      regex = regex.replace(name, val);
      return obj;
    },
    getRegex: () => {
      return new RegExp(regex, opt);
    },
  };
  return obj;
}

const nonWordAndColonTest = /[^\w:]/g;
const originIndependentUrl = /^$|^[a-z][a-z0-9+.-]*:|^[?#]/i;
export function cleanUrl(sanitize: boolean, base: string, href: string) {
  if (sanitize) {
    let prot;
    try {
      prot = decodeURIComponent(unescape(href))
        .replace(nonWordAndColonTest, '')
        .toLowerCase();
    } catch (e) {
      return null;
    }
    if (
      prot.indexOf('javascript:') === 0 ||
      prot.indexOf('vbscript:') === 0 ||
      prot.indexOf('data:') === 0
    ) {
      return null;
    }
  }
  if (base && !originIndependentUrl.test(href)) {
    href = resolveUrl(base, href);
  }
  try {
    href = encodeURI(href).replace(/%25/g, '%');
  } catch (e) {
    return null;
  }
  return href;
}

const baseUrls: { [url: string]: string } = {};
const justDomain = /^[^:]+:\/*[^/]*$/;
const protocol = /^([^:]+:)[\s\S]*$/;
const domain = /^([^:]+:\/*[^/]*)[\s\S]*$/;

export function resolveUrl(base: string, href: string) {
  if (!baseUrls[' ' + base]) {
    // we can ignore everything in base after the last slash of its path component,
    // but we might need to add _that_
    // https://tools.ietf.org/html/rfc3986#section-3
    if (justDomain.test(base)) {
      baseUrls[' ' + base] = base + '/';
    } else {
      baseUrls[' ' + base] = rtrim(base, '/', true);
    }
  }
  base = baseUrls[' ' + base];
  const relativeBase = base.indexOf(':') === -1;

  if (href.substring(0, 2) === '//') {
    if (relativeBase) {
      return href;
    }
    return base.replace(protocol, '$1') + href;
  } else if (href.charAt(0) === '/') {
    if (relativeBase) {
      return href;
    }
    return base.replace(domain, '$1') + href;
  } else {
    return base + href;
  }
}

export const noopTest = {
  exec: function noopTest() {
    return null;
  },
};

export interface Merge {
  <T extends {}, U extends {}>(a: T, b: U): T & U;
  <T extends {}, U extends {}, V extends {}>(a: T, b: U, c: V): T & U & V;
}

export function splitCells(
  tableRow: string,
  count?: number
): {
  cells: {
    raw: string;
    parsed: string;
    index: number | undefined;
    escapes: number[];
  }[];
  endindex: number;
} {
  // ensure that every cell-delimiting pipe has a space
  // before it to distinguish it from an escaped pipe

  const row = tableRow.replace(/\|/g, (_match, offset, str) => {
    let escaped = false,
      curr = offset;
    while (--curr >= 0 && str[curr] === '\\') escaped = !escaped;
    if (escaped) {
      // odd number of slashes means | is escaped
      // so we leave it alone
      return '|';
    } else {
      // add space before unescaped |
      return ' |';
    }
  });
  const rawcells = row.split(/ \|/);
  let i = 0;

  if (typeof count !== 'undefined' && rawcells.length > count) {
    rawcells.splice(count);
  }

  let index = 0;
  const cells: {
    raw: string;
    parsed: string;
    index: number | undefined;
    escapes: number[];
  }[] = [];
  for (const raw of rawcells) {
    cells.push({
      raw,
      parsed: '',
      index,
      escapes: [],
    });
    index += raw.length + 1;
  }
  if (typeof count !== 'undefined') {
    while (cells.length < count) {
      cells.push({
        raw: '',
        parsed: '',
        index: undefined,
        escapes: [],
      });
    }
  }

  for (; i < cells.length; i++) {
    // leading or trailing whitespace is ignored per the gfm spec
    const escapesequence = /\\\|/gy;
    const lengthwas = cells[i].raw.length;
    cells[i].raw = cells[i].raw.trimLeft();
    cells[i].index =
      typeof cells[i].index === 'undefined'
        ? undefined
        : cells[i].index! + lengthwas - cells[i].raw.length;
    cells[i].raw = cells[i].raw.trimRight();
    let cap;
    while ((cap = escapesequence.exec(cells[i].raw))) {
      cells[i].escapes.push(cap.index);
    }

    cells[i].parsed = cells[i].raw.replace(/\\\|/g, '|');
  }

  return { cells, endindex: index - 1 };
}

// Remove trailing 'c's. Equivalent to str.replace(/c*$/, '').
// /c*$/ is vulnerable to REDOS.
// invert: Remove suffix of non-c chars instead. Default falsey.
export function rtrim(str: string, c: string, invert?: boolean) {
  const l = str.length;
  if (l === 0) {
    return '';
  }

  // Length of suffix matching the invert condition.
  let suffLen = 0;

  // Step left until we fail to match the invert condition.
  while (suffLen < l) {
    const currChar = str.charAt(l - suffLen - 1);
    if (currChar === c && !invert) {
      suffLen++;
    } else if (currChar !== c && invert) {
      suffLen++;
    } else {
      break;
    }
  }

  return str.substr(0, l - suffLen);
}

export function findClosingBracket(str: string, b: [string, string]) {
  if (str.indexOf(b[1]) === -1) {
    return -1;
  }
  const l = str.length;
  let level = 0,
    i = 0;
  for (; i < l; i++) {
    if (str[i] === '\\') {
      i++;
    } else if (str[i] === b[0]) {
      level++;
    } else if (str[i] === b[1]) {
      level--;
      if (level < 0) {
        return i;
      }
    }
  }
  return -1;
}

export function checkSanitizeDeprecation(opt: Options) {
  if (opt && opt.sanitizer && !opt.silent) {
    console.warn(
      'marked(): sanitize and sanitizer parameters are deprecated since version 0.7.0, should not be used and will be removed in the future. Read more here: https://marked.js.org/#/USING_ADVANCED.md#options'
    );
  }
}
